import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { tokens } from '../../../theme'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FormattedMessage } from 'react-intl';
import { AdminContext } from '../../../context/AdminContext';

function AdminBox({ adminModel, updateAdmin, updateAdminPassword, removeAdmin }) {
    const { admin } = useContext(AdminContext);
    const colors = tokens();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box>
            <Menu
                id="menu"
                aria-labelledby="open-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    updateAdmin(adminModel);
                }}>
                    <FormattedMessage id="edit" />
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose();
                    updateAdminPassword(adminModel);
                }}>
                    <FormattedMessage id="change_password" />
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    removeAdmin(adminModel);
                }}>
                    <FormattedMessage id="delete" />
                </MenuItem>
            </Menu>
            <Box position="relative" bgcolor={colors.grey[900]} p="20px" borderRadius="10px">
                <Box position="absolute" top={15} right={15}>
                    {admin.admin_edit_perm === 1 &&
                        <IconButton
                            id="open-menu-button"
                            aria-controls={open ? 'menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}>
                            <MoreVertIcon sx={{ scale: "1.2" }} />
                        </IconButton>
                    }
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant='h3' fontWeight="600">
                        {adminModel.username ? adminModel.username : "-"}
                    </Typography>
                </Box>
                <Box component="hr" my="20px" border={"1px solid " + colors.grey[500]} />
                <Box mt="10px">
                    <Box display="flex" justifyContent="space-between" mt="10px" gap={"20px"}>
                        <Box>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="dashboard_perm" />:</b> {adminModel.dashboard_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="users_perm" />:</b> {adminModel.users_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="categories_perm" />:</b> {adminModel.categories_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="advertisements_perm" />:</b> {adminModel.advertisements_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="onboardings_perm" />:</b> {adminModel.onboardings_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="reports_perm" />:</b> {adminModel.reports_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="contact_messages_perm" />:</b> {adminModel.contact_messages_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="faqs_perm" />:</b> {adminModel.faqs_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="forbidden_words_perm" />:</b> {adminModel.forbidden_words_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="commercials_perm" />:</b> {adminModel.commercials_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="notifications_perm" />:</b> {adminModel.notifications_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                        </Box>
                        <Box>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="admins_perm" />:</b> {adminModel.admins_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="user_edit_perm" />:</b> {adminModel.user_edit_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="user_suspension_perm" />:</b> {adminModel.user_suspension_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="user_deletion_perm" />:</b> {adminModel.user_deletion_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="category_edit_perm" />:</b> {adminModel.category_edit_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="category_deletion_perm" />:</b> {adminModel.category_deletion_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="advertisement_edit_perm" />:</b> {adminModel.advertisement_edit_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="advertisement_deletion_perm" />:</b> {adminModel.advertisement_deletion_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="comment_edit_perm" />:</b> {adminModel.comment_edit_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="comment_deletion_perm" />:</b> {adminModel.comment_deletion_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                        </Box>
                        <Box>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="direct_notification_perm" />:</b> {adminModel.direct_notification_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="advertisement_notification_perm" />:</b> {adminModel.advertisement_notification_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="comment_notification_perm" />:</b> {adminModel.comment_notification_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="report_notification_perm" />:</b> {adminModel.report_notification_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="contact_message_notification_perm" />:</b> {adminModel.contact_message_notification_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="resend_notification_perm" />:</b> {adminModel.resend_notification_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="notification_drafts_perm" />:</b> {adminModel.notification_drafts_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="report_edit_perm" />:</b> {adminModel.report_edit_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="report_deletion_perm" />:</b> {adminModel.report_deletion_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="faq_edit_perm" />:</b> {adminModel.faq_edit_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                        </Box>
                        <Box>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="faq_deletion_perm" />:</b> {adminModel.faq_deletion_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="commercial_create_perm" />:</b> {adminModel.commercial_create_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="commercial_edit_perm" />:</b> {adminModel.commercial_edit_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="commercial_deletion_perm" />:</b> {adminModel.commercial_deletion_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="gpt_settings_edit_perm" />:</b> {adminModel.gpt_settings_edit_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="service_provider_promotional_text_edit_perm" />:</b> {adminModel.service_provider_promotional_text_edit_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="about_app_edit_perm" />:</b> {adminModel.about_app_edit_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="user_agreement_edit_perm" />:</b> {adminModel.user_agreement_edit_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="privacy_policy_edit_perm" />:</b> {adminModel.privacy_policy_edit_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                            <Typography variant='h6' mt={"5px"} ><b>-<FormattedMessage id="admin_edit_perm" />:</b> {adminModel.admin_edit_perm ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AdminBox
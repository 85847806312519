import React, { createContext, useEffect, useState } from 'react'
import axios from 'axios';
export const AdminContext = createContext();

export function AdminProvider(props) {
    const [token, setToken] = useState(localStorage.getItem('token') ? localStorage.getItem('token') : null);
    const [admin, setAdmin] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleToken = (newToken) => {
        localStorage.setItem('token', newToken);
    }

    const handleAdmin = async (token) => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getAdmin`, { headers: { token: token } })
            .catch((err) => {
                setAdmin(null);
                setIsLoading(false);
                localStorage.removeItem('token');
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setAdmin(response.data.admin);
                }
                setIsLoading(false);
            });

    }

    useEffect(() => {
        handleToken(token);
        if (token) {
            handleAdmin(token);
        } else {
            setAdmin(null);
        }
    }, [token])

    return (
        <AdminContext.Provider value={{ token, setToken, admin, setAdmin, isLoading }}>
            {props.children}
        </AdminContext.Provider>
    )
}
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { tokens } from '../../theme';
import { FormattedMessage } from 'react-intl';
import { AdminContext } from '../../context/AdminContext';

function CommentBox({ comment, onNotificationSendButtonClick, onEditButtonClick, onDeleteButtonClick, onRejectDeletionRequestButtonClick }) {
    const { admin } = useContext(AdminContext);
    const colors = tokens();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box display="flex" flexDirection="column" gap="20px" justifyContent="space-between" sx={{ gridColumn: "span 1", border: "1px solid " + colors.grey[500], borderRadius: "20px", mx: "10px" }}>
            <Menu
                id="menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    onNotificationSendButtonClick([comment.origin_user_id]);
                }}>
                    <FormattedMessage id="to_the_user_who_made_the_comment" />
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose();
                    onNotificationSendButtonClick([comment.target_user_id]);
                }}>
                    <FormattedMessage id="to_the_user_who_received_the_comment" />
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose();
                    onNotificationSendButtonClick([comment.origin_user_id, comment.target_user_id]);
                }}>
                    <FormattedMessage id="both_of_them" />
                </MenuItem>
            </Menu>

            <Box padding="10px">
                <Box display="flex" gap="10px" alignItems="center">
                    <Box component="img" src={comment.user_profile_photo_link} alt={comment.user_name} sx={{ objectFit: "cover", width: "50px", height: "50px", borderRadius: "25px" }} />
                    <Typography variant="h3">{comment.user_name} {comment.user_surname}</Typography>
                </Box>
            </Box>
            <Box component="hr" height="1px" width="100%" sx={{ background: "black", border: "0px", margin: "0px" }} />
            <Box>
                <Box display="flex" sx={{ justifyContent: "space-evenly" }}>
                    <Box display="flex" flexDirection="column" gap="5px" alignItems="center">
                        <Typography variant='h6'>
                            <FormattedMessage id="avarage" />
                        </Typography>
                        <Typography variant='h5'>{comment.avarage_point % 1 === 0 ? comment.avarage_point : comment.avarage_point.toFixed(2)}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" gap="5px" alignItems="center">
                        <Typography variant='h6'>
                            <FormattedMessage id="timing" />
                        </Typography>
                        <Typography variant='h5'>{comment.timing_point}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" gap="5px" alignItems="center">
                        <Typography variant='h6'>
                            <FormattedMessage id="communication" />
                        </Typography>
                        <Typography variant='h5'>{comment.communication_point}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" gap="5px" alignItems="center">
                        <Typography variant='h6'>
                            <FormattedMessage id="quality" />
                        </Typography>
                        <Typography variant='h5'>{comment.service_quality_point}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" gap="5px" alignItems="center">
                        <Typography variant='h6'>
                            <FormattedMessage id="price" />
                        </Typography>
                        <Typography variant='h5'>{comment.price_point}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box component="hr" height="1px" width="100%" sx={{ background: "black", border: "0px", margin: "0px" }} />

            <Box padding="10px">
                <Typography variant='h5'>{comment.comment}</Typography>
            </Box>
            <Box display="flex" gap="10px" padding="10px">
                {(!!onNotificationSendButtonClick && admin.comment_notification_perm === 1) &&
                    <Button variant="contained" color="info" sx={{ width: "100%", borderRadius: "12px" }}
                        onClick={(e) => {
                            handleOpen(e);
                        }}
                    >
                        <FormattedMessage id="send_notification" />
                    </Button>}
                {(!!onEditButtonClick && admin.comment_edit_perm === 1) &&
                    <Button variant="contained" color="success" sx={{ width: "100%", borderRadius: "12px" }} onClick={() => onEditButtonClick()}>
                        <FormattedMessage id="edit" />
                    </Button>
                }
                {(!!onDeleteButtonClick && admin.comment_deletion_perm === 1) &&
                    <Button variant="contained" color="error" sx={{ width: "100%", borderRadius: "12px" }} onClick={() => onDeleteButtonClick(comment.id)} >
                        <FormattedMessage id="delete" />
                    </Button>
                }
                {!!onRejectDeletionRequestButtonClick && admin.report_edit_perm === 1 &&
                    <Button variant="contained" color="info" sx={{ width: "100%", borderRadius: "12px" }} onClick={() => onRejectDeletionRequestButtonClick(comment.id)} >
                        <FormattedMessage id="reject_deletion_request" />
                    </Button>
                }
            </Box>
        </Box>
    )
}

export default CommentBox
import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { tokens } from '../../../theme';
import { FormattedMessage } from 'react-intl';

function OnboardingDialog({ onboarding, dialogOpen, handleClose, handleFormSubmit }) {

    const colors = tokens();

    var initialValues = {
        title: onboarding?.title ? onboarding?.title : "",
        content: null,
        content_link: onboarding?.content_link ? onboarding?.content_link : "",
        translations: onboarding?.translations && onboarding?.translations.length > 0 ? onboarding?.translations : [
            { language: "de", title_translation: "", content_translation: null, content_link_translation: "" },
        ]
    };

    var validationObject = {
        title: yup.string().required(<FormattedMessage id="required" />),
        content: yup.mixed().nullable().when('content_link', {
            is: (content_link) => (!content_link || content_link === ""),
            then: () => yup.string().nonNullable("").required(<FormattedMessage id="required" />)
        }),
        content_link: yup.string(),
        translations: yup.array().of(yup.object().shape(
            {
                language: yup.string().required(<FormattedMessage id="required" />),
                title_translation: yup.string().required(<FormattedMessage id="required" />),
                content_translation: yup.mixed().nullable().when('content_link_translation', {
                    is: (content_link_translation) => (!content_link_translation || content_link_translation === ""),
                    then: () => yup.string().nonNullable("").required(<FormattedMessage id="required" />)
                }),
                content_link_translation: yup.string()
            }
        ).required(<FormattedMessage id="required" />)).required(<FormattedMessage id="required" />)
    }

    const onboardingSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            open={dialogOpen}
            onClose={handleClose}>
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    variant="standard"
                    color="error"
                    sx={{
                        borderRadius: "100%",
                        backgroundColor: "white",
                        maxWidth: "40px",
                        maxHeight: "40px",
                        minWidth: "40px",
                        minHeight: "40px",
                        position: "absolute",
                        top: "10px",
                        right: "10px"
                    }}
                    onClick={handleClose}>
                    <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                </Button>
            </Box>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    {
                        onboarding ?
                            <FormattedMessage id="edit_onboarding" />
                            :
                            <FormattedMessage id="add_new_onboarding" />
                    }
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={onboardingSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>

                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="75px" my="25px">
                                    <Box textAlign="center" sx={{ gridColumn: "span 1" }}>
                                        <Typography variant="h6">
                                            <FormattedMessage id="onboarding_image_with_language_code" values={{ language: "en" }} />
                                        </Typography>
                                        <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[900], height: "150px", width: "150px" }}>
                                            {(values.content || (values.content_link && values.content_link !== "")) && <img alt='onboarding' width="100px" src={values.content ? URL.createObjectURL(values.content) : values.content_link} />}
                                            <input id="content" name="content" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("content", event.target.files[0]); }} hidden />
                                        </Button>
                                    </Box>

                                    {values.translations.map((translation, i) =>
                                        <Box key={`translation-${i}`} textAlign="center" sx={{ gridColumn: "span 1" }}>
                                            <Typography variant="h6">
                                                <FormattedMessage id="onboarding_image_with_language_code" values={{ language: translation.language }} />
                                            </Typography>
                                            <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[900], height: "150px", width: "150px" }}>
                                                {(values.translations[i].content_translation || (values.translations[i].content_link_translation && values.translations[i].content_link_translation !== "")) && <img alt='onboarding' width="100px" src={values.translations[i].content_translation ? URL.createObjectURL(values.translations[i].content_translation) : values.translations[i].content_link_translation} />}
                                                <input id="content_translation" name={"translations[" + i + "].content_translation"} type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("translations[" + i + "].content_translation", event.target.files[0]); }} hidden />
                                            </Button>
                                        </Box>
                                    )}

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={<FormattedMessage id="title_with_language_code" values={{ language: "en" }} />}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"title"}
                                        value={values.title}
                                        error={!!touched.title && !!errors.title}
                                        helperText={touched.title && errors.title}
                                        sx={{ gridColumn: "span 1", mt: "20px" }}
                                    />
                                    {values.translations.map((translation, i) =>

                                        <TextField
                                            key={`title-translation-${i}`}
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label={<FormattedMessage id="title_with_language_code" values={{ language: translation.language }} />}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"translations[" + i + "].title_translation"}
                                            value={values.translations[i].title_translation}
                                            error={!!touched?.translations && !!touched?.translations[i] && !!touched?.translations[i]?.title_translation && !!errors?.translations && !!errors?.translations[i] && !!errors?.translations[i]?.title_translation}
                                            helperText={touched?.translations && touched?.translations[i] && touched?.translations[i]?.title_translation && errors?.translations && errors?.translations[i] && errors?.translations[i]?.title_translation}
                                            sx={{ gridColumn: "span 1", mt: "20px" }}
                                        />
                                    )}
                                </Box>

                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <Typography variant='h5'>
                                            <FormattedMessage id="save" />
                                        </Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default OnboardingDialog
import { Box, Button, Snackbar } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import AlertDialogSlide from '../../components/AlertDialogSlide';
import { Table } from '../../components/Table';
import { FormattedMessage } from 'react-intl';
import OnboardingDialog from './OnboardingDialog';

function OnboardingsPage() {
    const { admin, token } = useContext(AdminContext);
    const [onboardings, setOnboardings] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [maxPosition, setMaxPosition] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [orderModel, setOrderModel] = useState(null);
    const [filterModel, setFilterModel] = useState(null);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        rowsPerPage: admin.onboardings_table_row_count
    });
    const [onboardingEvent, setOnboardingEvent] = useState({ onboarding: null, type: null });
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [snackbarState, setSnackbarState] = useState({ open: false, message: "" });
    const handleSnackbarOpen = (message) => {
        setSnackbarState({ open: true, message: message });
    };

    const handleSnackbarClose = () => {
        setSnackbarState({ open: false, message: "" });
    };

    const columns = [
        {
            id: 'position',
            name: <FormattedMessage id={"table.columns.position"} />,
            type: 'number',
            sortable: true, filterable: true
        }, {
            id: 'content_link',
            name: <FormattedMessage id={"table.columns.content_link"} />,
            sortable: false, filterable: false,
            render: (value) => <img src={value} alt='onboarding' style={{ height: "30px", width: "30px" }} />
        }, {
            id: 'title',
            name: <FormattedMessage id={"table.columns.title"} />,
            type: 'text',
            sortable: true, filterable: true
        }, {
            id: 'actions',
            name: <FormattedMessage id={"table.columns.actions"} />,
            type: 'actions',
            sortable: false, filterable: false,
            getActions: () => [
                {
                    label: <FormattedMessage id={"move_to_up"} />,
                    action: (row) => {
                        changePositions(row, row.position, row.position - 1);
                    }
                },
                {
                    label: <FormattedMessage id={"move_to_down"} />,
                    action: (row) => {
                        changePositions(row, row.position, row.position + 1);
                    }
                },
                {
                    label: <FormattedMessage id={"edit"} />,
                    action: (row) => {
                        fetchOnboardingToUpdate(row);
                    }
                },
                {
                    label: <FormattedMessage id={"delete"} />,
                    action: (row) => {
                        setOnboardingEvent({ onboarding: row, type: "delete" })
                    }
                }
            ]
        }
    ];

    const fetchOnboardings = (paginationModel, orderModel, filterModel, token) => {
        setLoading(true);
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/getOnboardings`,
            {
                startIndex: paginationModel.page * paginationModel.rowsPerPage,
                limit: paginationModel.rowsPerPage,
                orderBy: orderModel ? orderModel.orderBy : null,
                orderType: orderModel ? orderModel.orderType : null,
                filter: filterModel ? filterModel : null
            },
            { headers: { token: token } }
        )
            .catch((err) => {
                setOnboardings([]);
                setLoading(false);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setOnboardings(response.data.onboardings);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                    setMaxPosition(response.data.maxPosition !== undefined ? response.data.maxPosition : 0);
                }
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchOnboardings(paginationModel, orderModel, filterModel, token);
    }, [paginationModel, orderModel, filterModel, token]);

    useEffect(() => {
        if ((onboardingEvent?.onboarding && onboardingEvent?.type === "edit") || (!onboardingEvent?.onboarding && onboardingEvent?.type === "add")) {
            setEditDialogOpen(true);
        }
        else if (onboardingEvent?.onboarding && onboardingEvent?.type === "delete") {
            setRemoveDialogOpen(true);
        }
    }, [onboardingEvent]);

    const fetchOnboardingToUpdate = (onboarding) => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getOnboardingToUpdate?onboardingId=${onboarding.id}`, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setOnboardingEvent({
                        onboarding: {
                            ...response.data.onboarding,
                            position: onboarding.position,
                        }, type: "edit"
                    });
                }
            });
    }

    const addOnboarding = async (onboarding) => {
        if (onboarding.content) {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadOnboardingContent`, {
                onboardingContent: onboarding.content
            }, {
                headers: {
                    token: token,
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        onboarding.content_path = response.data.contentPath;
                    }
                });
        }
        if (onboarding.translations?.length > 0) {
            for (let i = 0; i < onboarding.translations.length; i++) {
                if (onboarding.translations[i].content_translation) {
                    await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadOnboardingContent`, {
                        onboardingContent: onboarding.translations[i].content_translation
                    }, {
                        headers: {
                            token: token,
                            "content-type": "multipart/form-data"
                        }
                    })
                        .catch((err) => {
                            console.log("err: " + err);
                        })
                        .then((response) => {
                            if (response && response.data.success === true && response.data.status === 201) {
                                onboarding.translations[i].content_path_translation = response.data.contentPath;
                            }
                        });
                }
            }
        }
        delete onboarding.content;
        delete onboarding.content_link;
        delete onboarding.position;

        for (let i = 0; i < onboarding.translations.length; i++) {
            delete onboarding.translations[i].content_translation;
            delete onboarding.translations[i].content_link_translation;
        }
        console.log("onboarding: " + JSON.stringify(onboarding));
        await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/createOnboardingWithTranslations`, {
            ...onboarding
        }, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setOnboardingEvent({ onboarding: null, type: null });
                    setEditDialogOpen(false);
                    fetchOnboardings(paginationModel, orderModel, filterModel, token);
                    handleSnackbarOpen(<FormattedMessage id={"onboarding_added_successfully"} />);
                }
            });
    }

    const updateOnboarding = async (onboarding) => {
        var onboardingIx = onboardings.findIndex(o => o.id === onboardingEvent.onboarding.id);
        var contentLink;
        var translationContentLinks = [];
        if (onboarding.content) {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadOnboardingContent`, {
                onboardingContent: onboarding.content
            }, {
                headers: {
                    token: token,
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        onboarding.content_path = response.data.contentPath;
                        contentLink = onboarding.content_link.slice(0, onboarding.content_link.indexOf("/images") + 1) + response.data.contentPath;
                    }
                });
        }



        if (onboarding.translations?.length > 0) {
            for (let i = 0; i < onboarding.translations.length; i++) {
                if (onboarding.translations[i].content_translation) {
                    await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadOnboardingContent`, {
                        onboardingContent: onboarding.translations[i].content_translation
                    }, {
                        headers: {
                            token: token,
                            "content-type": "multipart/form-data"
                        }
                    })
                        .catch((err) => {
                            console.log("err: " + err);
                        })
                        .then((response) => {
                            if (response && response.data.success === true && response.data.status === 201) {
                                onboarding.translations[i].content_path_translation = response.data.contentPath;
                                translationContentLinks.push(onboarding.translations[i].content_link_translation.slice(0, onboarding.translations[i].content_link_translation.indexOf("/images") + 1) + response.data.contentPath);
                            }
                        });
                }
            }
        }

        for (let i = 0; i < onboarding.translations.length; i++) {
            delete onboarding.translations[i].content_translation;
            delete onboarding.translations[i].content_link_translation;
        }

        var onboardingToUpdate = { ...onboarding };
        delete onboardingToUpdate.content;
        delete onboardingToUpdate.content_link;
        delete onboardingToUpdate.position;

        console.log("onboardingToUpdate: " + JSON.stringify(onboardingToUpdate));

        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateOnboardingWithTranslations?onboardingId=${onboardingEvent.onboarding.id}`, {
            onboarding: onboardingToUpdate
        }, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    onboardings[onboardingIx] = onboardingToUpdate;
                    onboardings[onboardingIx].id = onboardingEvent.onboarding.id;
                    onboardings[onboardingIx].position = onboardingEvent.onboarding.position;
                    if (contentLink) {
                        onboardings[onboardingIx].content_link = contentLink;
                    } else {
                        onboardings[onboardingIx].content_link = onboardingEvent.onboarding.content_link;
                    }
                    setOnboardings(Array.from(onboardings));
                    setOnboardingEvent({ onboarding: null, type: null });
                    setEditDialogOpen(false);
                    handleSnackbarOpen(<FormattedMessage id={"onboarding_updated_successfully"} />);
                }
            });
    }

    const changePositions = (onboarding, currentPosition, newPosition) => {
        if (currentPosition === 1 && newPosition === 0) {
            handleSnackbarOpen(<FormattedMessage id={"already_top"} />);
            return;
        }
        if (currentPosition === maxPosition && newPosition === maxPosition + 1) {
            handleSnackbarOpen(<FormattedMessage id={"already_bottom"} />);
            return;
        }
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/changeOnboardingPosition?onboardingId=${onboarding.id}&currentPosition=${currentPosition}&newPosition=${newPosition}`, {}, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleSnackbarOpen(<FormattedMessage id={"position_changed_successfully"} />);
                    fetchOnboardings(paginationModel, orderModel, filterModel, token);
                }
            });
    }

    const handleOnboardingFormSubmit = async (onboarding) => {
        if (onboardingEvent.type === "add") {
            addOnboarding(onboarding);
        } else if (onboardingEvent.type === "edit") {
            updateOnboarding(onboarding);
        }
    }

    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const handleRemoveDialogClose = () => {
        setRemoveDialogOpen(false);
    }
    const clickPositiveAnswer = () => {
        removeOnboarding(onboardingEvent.onboarding);
    }
    const clickNegativeAnswer = () => {
    }

    const removeOnboarding = (onboarding) => {
        //if only 1 onboarding left, do not delete
        if (maxPosition === 1 || maxPosition === 0) {
            handleSnackbarOpen(<FormattedMessage id={"cannot_delete_last_onboarding"} />);
            setRemoveDialogOpen(false);
            setOnboardingEvent({ onboarding: null, type: null })
            return;
        }
        axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteOnboarding?onboardingId=${onboarding.id}`, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
                setOnboardingEvent({ onboarding: null, type: null })
                setRemoveDialogOpen(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = onboardings.findIndex(({ id }) => id === onboarding.id);
                    var newOnboardings = Array.from(onboardings);
                    if (ix >= 0) {
                        newOnboardings.splice(ix, 1);
                        if (onboardings[ix].position !== maxPosition) {
                            for (var i = ix; i < newOnboardings.length; i++) {
                                newOnboardings[i].position -= 1;
                            }
                        }
                        setOnboardings(newOnboardings);
                    }
                    setOnboardingEvent({ onboarding: null, type: null });
                    setRemoveDialogOpen(false);
                    handleSnackbarOpen(<FormattedMessage id={"onboarding_deleted_successfully"} />);
                }
            });
    }

    return (
        <Box sx={{ p: "75px" }}>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarState.open}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                message={snackbarState.message}
            />
            {
                onboardingEvent.onboarding && onboardingEvent.type === "delete" &&
                <AlertDialogSlide
                    open={removeDialogOpen}
                    handleClose={(event, reason) => {
                        if (reason && reason === "backdropClick") {
                            return;
                        }
                        handleRemoveDialogClose();
                        setOnboardingEvent({ onboarding: null, type: null })
                    }}
                    clickPositiveAnswer={clickPositiveAnswer}
                    clickNegativeAnswer={clickNegativeAnswer}
                    positiveAnswer={<FormattedMessage id={"delete"} />}
                    negativeAnswer={<FormattedMessage id={"cancel"} />}
                    title={<FormattedMessage id={"are_you_sure_to_delete_onboarding"} />}
                    description={<FormattedMessage id={"delete_onboarding_description"} />}
                />
            }
            {
                ((onboardingEvent.onboarding && onboardingEvent.type === "edit") || (!onboardingEvent.onboarding && onboardingEvent.type === "add")) &&
                <OnboardingDialog
                    dialogOpen={editDialogOpen}
                    handleClose={(event, reason) => {
                        if (reason && reason === "backdropClick") {
                            return;
                        }
                        setEditDialogOpen(false);
                        setOnboardingEvent({ onboarding: null, type: null });
                    }}
                    onboarding={onboardingEvent.onboarding}
                    handleFormSubmit={handleOnboardingFormSubmit}
                />
            }
            <Box display="flex" alignItems="center" gap="30px">
                <Header
                    title={<FormattedMessage id={"onboarding.header.title"} />}
                    subtitle={<FormattedMessage id={"onboarding.header.description"} />}
                />
                <Button variant="contained" color="success" onClick={() => setOnboardingEvent({ onboarding: null, type: "add" })}>
                    <FormattedMessage id={"add_new_onboarding"} />
                </Button>
            </Box>
            <Table
                table_id={"onboardings_table"}
                isLoading={isLoading}
                columns={columns}
                rows={onboardings}
                rowCount={rowCount}
                onOrderChange={(order) => {
                    setOrderModel(order);
                }}
                onPaginationChange={(pagination) => {
                    setPaginationModel(pagination);
                }}
                onFilterChange={(filter) => {
                    setFilterModel(filter);
                }}
                onRowClick={(row, column) => {

                }}
            />

        </Box>
    )
}

export default OnboardingsPage
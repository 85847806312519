import { Box, Button, Dialog, DialogContent } from '@mui/material';
import React from 'react'

function AdvertisementPhotoDialog({ advertisementPhotoDialogState, setAdvertisementPhotoDialogState }) {
    return (
        <Dialog
            open={advertisementPhotoDialogState.open}
            fullWidth
            maxWidth="lg"
            onClose={(event, reason) => {
                if (reason && reason === "backdropClick") {
                    return;
                }
                setAdvertisementPhotoDialogState({ open: false, photo_link: null });
            }}
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none"
                }
            }}
        >
            <DialogContent>
                <Box>
                    <Button
                        variant="standard"
                        color="error"
                        sx={{
                            borderRadius: "100%",
                            backgroundColor: "white",
                            maxWidth: "40px",
                            maxHeight: "40px",
                            minWidth: "40px",
                            minHeight: "40px",
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            ":hover": {
                                backgroundColor: "white"
                            }
                        }}
                        onClick={() => setAdvertisementPhotoDialogState({ open: false, photo_link: null })}>
                        <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                    </Button>
                    <img src={advertisementPhotoDialogState.photo_link} alt="advertisement-content" width={800} />
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default AdvertisementPhotoDialog
import React from 'react'
import { tokens } from '../theme';
import { Box, Typography } from '@mui/material';

function Header({ title, subtitle }) {
    const colors = tokens();
    return (
        <Box mb="30px">
            <Typography variant="h2" color={colors.grey[100]} fontWeight="bold" marginBottom="5px" sx={{ userSelect: "none", msUserSelect: "none", WebkitUserSelect: "none" }}>
                {title}
            </Typography>
            <Typography variant='h5' color={colors.grey[400]} sx={{ userSelect: "none", msUserSelect: "none", WebkitUserSelect: "none" }}>
                {subtitle}
            </Typography>
        </Box>
    )
}

export default Header
import { Alert, Box, Button, Snackbar, Typography } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import AlertDialogSlide from '../../components/AlertDialogSlide';
import { FormattedMessage, useIntl } from 'react-intl';
import { Table } from '../../components/Table';
import NotificationDialog from '../NotificationsPage/NotificationDialog';
import { useNavigate } from 'react-router-dom';
import ReportDetailsDialog from '../../components/Report/ReportDetailsDialog';
import ReviewDeletionRequestsDialog from '../../components/Comment/ReviewDeletionRequestsDialog';

function ReportsPage() {
    const { admin, token } = useContext(AdminContext);
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const [reports, setReports] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [orderModel, setOrderModel] = useState(null);
    const [filterModel, setFilterModel] = useState(null);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        rowsPerPage: admin.reports_table_row_count
    });

    const [reportDetailsDialogState, setReportDetailsDialogState] = useState({ open: false, report: null });

    const handleReportDetailsDialogOpen = (report) => {
        if (report.offer_id) {
            axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getOffer?offerId=${report.offer_id}`, { headers: { token: token } })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 200) {
                        if (response.data.offer.main_offer_id) {
                            axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getOffer?offerId=${response.data.offer.main_offer_id}`, { headers: { token: token } })
                                .catch((err) => {
                                    console.log("err: " + err);
                                })
                                .then((response) => {
                                    if (response && response.data.success === true && response.data.status === 200) {
                                        setReportDetailsDialogState({ open: true, report: report, offer: response.data.offer });
                                    }
                                });
                        } else {
                            setReportDetailsDialogState({ open: true, report: { ...report, offer: response.data.offer } });
                        }
                    }
                });
        } else {
            setReportDetailsDialogState({ open: true, report: report })
        }
    }

    const [reviewDeletionRequestsDialogOpen, setReviewDeletionRequestsDialogOpen] = useState(false);

    const [alertDialogState, setAlertDialogState] = useState({
        open: false,
        title: "",
        description: "",
        positiveAnswer: "",
        negativeAnswer: "",
        onApprove: () => { }
    });
    const [notificationDialogState, setNotificationDialogState] = useState({
        open: false,
        userIds: [],
        draftType: "report_and_support_answers",
        onClose: () => { }
    });

    const [snackbarState, setSnackbarState] = useState({ open: false, type: "", message: "" });

    const handleSnackbarOpen = (type, message) => {
        setSnackbarState({ open: true, type: type, message: message });
    };

    const handleAlertDialogClose = () => {
        setAlertDialogState({
            open: false,
            title: "",
            description: "",
            positiveAnswer: "",
            negativeAnswer: "",
            onApprove: () => { }
        });
    }

    const handleSnackbarClose = () => {
        setSnackbarState({ open: false, type: "", message: "" });
    }

    const columns = [
        {
            id: 'id',
            name: formatMessage({ id: "table.columns.id" }),
            type: 'number',
            sortable: true, filterable: true,
            render: (value, row) => {
                if (row.status === 0) {
                    return <b>{value}</b>
                } else {
                    return value;
                }
            }

        },
        {
            id: 'status',
            name: formatMessage({ id: "status" }).toUpperCase(),
            type: 'select',
            sortable: true,
            filterable: true,
            options: [
                { value: 0, label: formatMessage({ id: "waiting" }) },
                { value: 1, label: formatMessage({ id: "solved" }) },
                { value: 1, label: formatMessage({ id: "archived" }) },
            ],
            render: (value, row) => {
                if (row.status === 0) {
                    return <b>{formatMessage({ id: value === 0 ? "waiting" : value === 1 ? "solved" : "archived" })}</b>
                } else {
                    return formatMessage({ id: value === 0 ? "waiting" : value === 1 ? "solved" : "archived" });
                }
            }
        },
        {
            id: "created_date",
            name: formatMessage({ id: "table.columns.created_date" }),
            type: 'date',
            sortable: true, filterable: true,
            render: (value, row) => {
                if (row.status === 0) {
                    return <b>{new Date(value).toLocaleDateString()}</b>
                } else {
                    return new Date(value).toLocaleDateString();
                }
            }
        },
        {
            id: "type",
            name: formatMessage({ id: "type" }).toUpperCase(),
            type: 'select',
            options: [
                { value: "advertisement", label: formatMessage({ id: "reports.type.option.advertisement" }) },
                { value: "offer", label: formatMessage({ id: "reports.type.option.offer" }) },
                { value: "user", label: formatMessage({ id: "reports.type.option.user" }) },
            ],
            sortable: true, filterable: true,
            render: (value, row) => {
                if (row.status === 0) {
                    return <b>{formatMessage({ id: `reports.type.option.${value}` })}</b>
                } else {
                    return formatMessage({ id: `reports.type.option.${value}` });
                }
            }
        },
        {
            id: "origin_user",
            name: formatMessage({ id: "reports.table.columns.reporter" }),
            type: 'text',
            sortable: true, filterable: true,
            minWidth: 200,
            render: (value, row) => {
                return (
                    <Box display={"flex"} alignItems={"center"} gap={"5px"}
                        style={admin.users_perm === 1 ? { cursor: "pointer" } : {}}
                        onClick={() => {
                            if (admin.users_perm === 1) {
                                navigate(`/users/${row.origin_user_id}`);
                            }
                        }}>
                        <img
                            alt="profile-user"
                            src={row.origin_user_profile_photo_link}
                            width="35px"
                            height="35px"
                            style={{ borderRadius: "50%" }}
                        />
                        <Typography variant="h5">{row.status !== 0 ? row.origin_user_name + " " + row.origin_user_surname : <b>{row.origin_user_name + " " + row.origin_user_surname}</b>}</Typography>
                    </Box>
                )
            }
        },
        {
            id: "reported_user",
            name: formatMessage({ id: "reports.table.columns.reported" }),
            type: 'text',
            sortable: true, filterable: true,
            minWidth: 200,
            render: (value, row) => {
                return (
                    <Box display={"flex"} alignItems={"center"} gap={"5px"}
                        style={admin.users_perm === 1 ? { cursor: "pointer" } : {}}
                        onClick={() => {
                            if (admin.users_perm === 1) {
                                navigate(`/users/${row.reported_user_id}`);
                            }
                        }}
                    >
                        <img
                            alt="profile-user"
                            src={row.reported_user_profile_photo_link}
                            width="35px"
                            height="35px"
                            style={{ borderRadius: "50%" }}
                        />
                        <Typography variant="h5">{row.status !== 0 ? row.reported_user_name + " " + row.reported_user_surname : <b>{row.reported_user_name + " " + row.reported_user_surname}</b>}</Typography>
                    </Box>
                )
            }
        },
        {
            id: "message",
            name: formatMessage({ id: "reports.table.columns.message" }),
            type: 'text',
            sortable: true, filterable: true,
            render: (value, row) => {
                if (row.status === 0) {
                    return <b>{value}</b>
                } else {
                    return value;
                }
            }
        },
        {
            id: "origin_user_country",
            name: formatMessage({ id: "reports.table.columns.reporter_user_country" }),
            type: 'text',
            sortable: true, filterable: true,
            render: (value, row) => {
                if (row.status === 0) {
                    return <b>{value}</b>
                } else {
                    return value;
                }
            }
        },
        {
            id: "reported_user_country",
            name: formatMessage({ id: "reports.table.columns.reported_user_country" }),
            type: 'text',
            sortable: true, filterable: true,
            render: (value, row) => {
                if (row.status === 0) {
                    return <b>{value}</b>
                } else {
                    return value;
                }
            }
        },
        {
            id: "actions",
            name: formatMessage({ id: "table.columns.actions" }),
            type: "actions",
            sortable: false, filterable: false,
            getActions: () => [
                {
                    label: <FormattedMessage id={"show_details"} />,
                    action: (row) => {
                        handleReportDetailsDialogOpen(row);
                    }
                },
                ...(admin.report_deletion_perm === 1 ? [{
                    label: <FormattedMessage id={"delete"} />,
                    action: (row) => {
                        setAlertDialogState({
                            open: true,
                            title: <FormattedMessage id={"are_you_sure_to_delete_report"} />,
                            description: <FormattedMessage id={"delete_report_description"} />,
                            positiveAnswer: <FormattedMessage id={"delete"} />,
                            negativeAnswer: <FormattedMessage id={"cancel"} />,
                            onApprove: () => {
                                deleteReports([row.id]);
                            }
                        });
                    }
                }] : []),
                ...(admin.report_notification_perm === 1 ? [{
                    label: <FormattedMessage id={"send_notification_to_reporter"} />,
                    action: (row) => {
                        setNotificationDialogState({
                            open: true,
                            userIds: [row.origin_user_id],
                            draftType: "report_and_support_answers",
                            onClose: () => { }
                        });
                    }
                },
                {
                    label: <FormattedMessage id={"send_notification_to_reported"} />,
                    action: (row) => {
                        setNotificationDialogState({
                            open: true,
                            userIds: [row.reported_user_id],
                            draftType: "report_and_support_answers",
                            onClose: () => { }
                        });
                    }
                }] : [])
            ]
        }
    ];

    const fetchReports = async (paginationModel, orderModel, filterModel, token) => {
        setLoading(true);
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/getReports`,
            {
                startIndex: paginationModel.page * paginationModel.rowsPerPage,
                limit: paginationModel.rowsPerPage,
                orderBy: orderModel ? orderModel.orderBy : null,
                orderType: orderModel ? orderModel.orderType : null,
                filter: filterModel ? filterModel : null
            },
            { headers: { token: token } }
        )
            .catch((err) => {
                setReports([]);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setReports(response.data.reports);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                }
                setLoading(false);
            });
    }

    const updateReports = async (reportIds, report) => {
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/updateReportBulk`,
            {
                reportIds: reportIds,
                report: report
            },
            { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleSnackbarOpen("success", formatMessage({ id: "report_updated_successfully" }));
                    setPaginationModel({ ...paginationModel });
                    if (reportDetailsDialogState.open) {
                        setReportDetailsDialogState({ open: false, report: null });
                    }
                }
            });
    }



    const deleteReports = (reportIds) => {
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/deleteReportBulk`, {
            reportIds: reportIds
        }, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setPaginationModel({ ...paginationModel });
                    handleSnackbarOpen("success", formatMessage({ id: "report_deleted_successfully" }));
                    if (reportDetailsDialogState.open) {
                        setReportDetailsDialogState({ open: false, report: null });
                    }
                }
            });
    }



    useEffect(() => {
        fetchReports(paginationModel, orderModel, filterModel, token);
    }, [paginationModel, orderModel, filterModel, token]);

    return (
        <Box sx={{ p: "75px" }}>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarState.open}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarState.type} sx={{ width: '100%' }}>
                    {snackbarState.message}
                </Alert>
            </Snackbar>
            {
                (reportDetailsDialogState?.open && reportDetailsDialogState.report) &&
                <ReportDetailsDialog
                    dialogOpen={reportDetailsDialogState.open}
                    handleClose={() => {
                        setReportDetailsDialogState({ open: false, report: null });
                    }}
                    report={reportDetailsDialogState.report}
                    onChangeStatusButtonClick={(report, newReport) => {
                        setAlertDialogState({
                            open: true,
                            title: <FormattedMessage id={"are_you_sure_to_change_report_status"} />,
                            description: <FormattedMessage id={"change_report_status_description"} />,
                            positiveAnswer: <FormattedMessage id={"yes"} />,
                            negativeAnswer: <FormattedMessage id={"no"} />,
                            onApprove: () => {
                                updateReports([report.id], newReport);
                            }
                        });
                    }}
                    onDeleteButtonClick={(report) => {
                        setAlertDialogState({
                            open: true,
                            title: <FormattedMessage id={"are_you_sure_to_delete_report"} />,
                            description: <FormattedMessage id={"delete_report_description"} />,
                            positiveAnswer: <FormattedMessage id={"delete"} />,
                            negativeAnswer: <FormattedMessage id={"cancel"} />,
                            onApprove: () => {
                                deleteReports([report.id]);
                            }
                        });
                    }}
                    onNotificationSendButtonClick={(userIds) => {
                        setNotificationDialogState({
                            open: true,
                            userIds: userIds,
                            draftType: "report_and_support_answers",
                            onClose: () => {
                            }
                        });
                    }}
                />
            }
            {alertDialogState.open &&
                <AlertDialogSlide
                    open={alertDialogState.open}
                    handleClose={(event, reason) => {
                        if (reason && reason === "backdropClick") {
                            return;
                        }
                        handleAlertDialogClose();
                    }}
                    clickPositiveAnswer={() => {
                        alertDialogState.onApprove();
                        handleAlertDialogClose();
                    }}
                    clickNegativeAnswer={() => { }}
                    positiveAnswer={alertDialogState.positiveAnswer}
                    negativeAnswer={alertDialogState.negativeAnswer}
                    title={alertDialogState.title}
                    description={alertDialogState.description}
                />
            }
            {notificationDialogState.open &&
                <NotificationDialog
                    dialogOpen={notificationDialogState.open}
                    notification={{
                        user_ids: [...(notificationDialogState.userIds)],
                    }}
                    onFormSubmit={() => {
                        setNotificationDialogState({
                            open: false,
                            userIds: [],
                            draftType: "report_and_support_answers",
                            onClose: () => { }
                        });
                        notificationDialogState.onClose();
                    }}
                    initialDraftType={notificationDialogState.draftType}
                    handleClose={(event, reason) => {
                        if (reason && reason === "backdropClick") {
                            return;
                        }
                        setNotificationDialogState({
                            open: false,
                            userIds: [],
                            draftType: "report_and_support_answers",
                            onClose: () => { }
                        });
                        notificationDialogState.onClose();
                    }}
                />
            }
            {reviewDeletionRequestsDialogOpen &&
                <ReviewDeletionRequestsDialog
                    dialogOpen={reviewDeletionRequestsDialogOpen}
                    handleClose={() => {
                        setReviewDeletionRequestsDialogOpen(false);
                    }}
                />
            }
            <Box display="flex" alignItems="center" gap="20px">
                <Header
                    title={<FormattedMessage id={"reports.header.title"} />}
                    subtitle={<FormattedMessage id={"reports.header.description"} />} />
                <Button variant="contained" color="info" onClick={() => {
                    setReviewDeletionRequestsDialogOpen(true);
                }}>
                    <FormattedMessage id={"see_review_deletion_requests"} />
                </Button>
            </Box>
            <Table
                table_id={"reports_table"}
                isLoading={isLoading}
                columns={columns}
                rows={reports}
                rowCount={rowCount}
                onOrderChange={(order) => {
                    setOrderModel(order);
                }}
                onPaginationChange={(pagination) => {
                    setPaginationModel(pagination);
                }}
                onFilterChange={(filter) => {
                    setFilterModel(filter);
                }}
                onRowClick={(row) => {
                }}
                additionalButtons={[
                ]}
                enableRowSelection={admin.report_edit_perm === 1 || admin.report_deletion_perm === 1}
                selectionActionButtons={[
                    ...(admin.report_edit_perm === 1 ? [{
                        label: <FormattedMessage id={"mark_selected_as_waiting"} />,
                        color: "warning",
                        onClick: (selectedRows, removeSelections) => {
                            setAlertDialogState({
                                open: true,
                                title: <FormattedMessage id={"are_you_sure_to_change_report_status"} />,
                                description: <FormattedMessage id={"change_report_status_description"} />,
                                positiveAnswer: <FormattedMessage id={"yes"} />,
                                negativeAnswer: <FormattedMessage id={"no"} />,
                                onApprove: () => {
                                    updateReports(selectedRows.map((row) => row.id), { status: 0 });
                                    removeSelections();
                                }
                            });
                        }
                    },
                    {
                        label: <FormattedMessage id={"mark_selected_as_solved"} />,
                        color: "success",
                        onClick: (selectedRows, removeSelections) => {
                            setAlertDialogState({
                                open: true,
                                title: <FormattedMessage id={"are_you_sure_to_change_report_status"} />,
                                description: <FormattedMessage id={"change_report_status_description"} />,
                                positiveAnswer: <FormattedMessage id={"yes"} />,
                                negativeAnswer: <FormattedMessage id={"no"} />,
                                onApprove: () => {
                                    updateReports(selectedRows.map((row) => row.id), { status: 1 });
                                    removeSelections();
                                }
                            });
                        }
                    },
                    {
                        label: <FormattedMessage id={"mark_selected_as_archived"} />,
                        color: "error",
                        onClick: (selectedRows, removeSelections) => {
                            setAlertDialogState({
                                open: true,
                                title: <FormattedMessage id={"are_you_sure_to_change_report_status"} />,
                                description: <FormattedMessage id={"change_report_status_description"} />,
                                positiveAnswer: <FormattedMessage id={"yes"} />,
                                negativeAnswer: <FormattedMessage id={"no"} />,
                                onApprove: () => {
                                    updateReports(selectedRows.map((row) => row.id), { status: 2 });
                                    removeSelections();
                                }
                            });
                        }
                    }] : []),
                    ...(admin.report_deletion_perm === 1 ? [{
                        label: <FormattedMessage id={"delete_selected"} />,
                        color: "error",
                        onClick: (selectedRows, removeSelections) => {
                            setAlertDialogState({
                                open: true,
                                title: <FormattedMessage id={"are_you_sure_to_delete_report"} />,
                                description: <FormattedMessage id={"delete_report_description"} />,
                                positiveAnswer: <FormattedMessage id={"delete"} />,
                                negativeAnswer: <FormattedMessage id={"cancel"} />,
                                onApprove: () => {
                                    deleteReports(selectedRows.map((row) => row.id));
                                    removeSelections();
                                }
                            });
                        }
                    }] : []),
                ]}
            />

        </Box>
    )
}

export default ReportsPage
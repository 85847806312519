import { Box, Button, Checkbox, Dialog, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from "yup";
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { AdminContext } from '../../../context/AdminContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';

function SuspensionDialog({ open, onClose, handleSubmit }) {

    const { token, admin } = useContext(AdminContext);

    const initialValues = {
        suspend_indefinitely: false,
        suspended_until: null,
        suspended_reason: "",
    };

    const validationObject = {
        suspend_indefinitely: yup.boolean(),
        suspended_until: yup.date().nullable().when("suspend_indefinitely", {
            is: (suspend_indefinitely) => !suspend_indefinitely,
            then: () => yup.date().required("required"),
        }),
        suspended_reason: yup.string().required("required"),
    }


    return (
        <Dialog
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason && reason === "backdropClick") {
                    return;
                }
                onClose();
            }}
        >
            <Box p="20px">
                <Typography variant="h4">
                    <FormattedMessage id={'suspend_user'} />


                    <Button
                        variant="standard"
                        color="error"
                        sx={{
                            borderRadius: "100%",
                            backgroundColor: "white",
                            maxWidth: "40px",
                            maxHeight: "40px",
                            minWidth: "40px",
                            minHeight: "40px",
                            position: "absolute",
                            top: "10px",
                            right: "10px"
                        }}
                        onClick={onClose}>
                        <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                    </Button>
                </Typography>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        if (values.suspend_indefinitely) {
                            handleSubmit({ suspended_until: "1111-01-01", suspended_reason: values.suspended_reason, suspension_appeal: null });
                        } else {
                            handleSubmit({ suspended_until: values.suspended_until, suspended_reason: values.suspended_reason, suspension_appeal: null });
                        }
                    }}
                    validationSchema={yup.object().shape(validationObject)}
                >
                    {({ values, errors, touched, handleChange, handleSubmit, handleBlur, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <Box display="grid" columnGap="15px" rowGap={"15px"} gridTemplateColumns="repeat(2, minmax(0, 1fr))" mt={"25px"} px={"30px"}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.suspend_indefinitely}
                                            size="small"
                                        />}
                                    variant="filled"
                                    label={<FormattedMessage id="suspend_indefinitely" />}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name={"suspend_indefinitely"}
                                    value={values.suspend_indefinitely}
                                    sx={{ gridColumn: "span 1" }} />

                                {
                                    !values.suspend_indefinitely && (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label={<FormattedMessage id="suspend_until" />}
                                                onBlur={handleBlur}
                                                onChange={(date) => setFieldValue("suspended_until", date)}
                                                helperText={touched.suspended_until && errors.suspended_until}
                                                error={!!touched.suspended_until && !!errors.suspended_until}
                                                // error={!!touched.title && !!errors.title}
                                                //         helperText={touched.title && errors.title}
                                                name={"suspended_until"}
                                                value={values.suspended_until}
                                                sx={{ gridColumn: "span 1" }}
                                            />
                                        </LocalizationProvider>
                                    )
                                }

                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label={<FormattedMessage id="suspension_reason" />}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name={"suspended_reason"}
                                    value={values.suspended_reason}
                                    helperText={touched.suspended_reason && errors.suspended_reason}
                                    error={!!touched.suspended_reason && !!errors.suspended_reason}
                                    multiline
                                    rows={4}
                                    sx={{ gridColumn: "span 2" }}
                                />


                                <Box display="flex" justifyContent="end" m="20px 0px 0px 20px" gap={"10px"} sx={{ gridColumn: "span 2" }}>
                                    <Button
                                        type="submit"
                                        color="success"
                                        variant="contained"
                                    >
                                        <FormattedMessage id="save" />
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Dialog>

    )
}

export default SuspensionDialog
import { Alert, Box, Button, Snackbar, Typography } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import AlertDialogSlide from '../../components/AlertDialogSlide';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Table } from '../../components/Table';
import NotificationDialog from '../NotificationsPage/NotificationDialog';

function ContactMessagesPage() {
    const { admin, token } = useContext(AdminContext);
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const [contactMessages, setContactMessages] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [orderModel, setOrderModel] = useState(null);
    const [filterModel, setFilterModel] = useState(null);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        rowsPerPage: admin.contact_messages_table_row_count
    });

    const [alertDialogState, setAlertDialogState] = useState({
        open: false,
        title: "",
        description: "",
        positiveAnswer: "",
        negativeAnswer: "",
        onApprove: () => { }
    });
    const [notificationDialogState, setNotificationDialogState] = useState({
        open: false,
        userIds: [],
        draftType: "report_and_support_answers",
        onClose: () => { }
    });

    const [snackbarState, setSnackbarState] = useState({ open: false, type: "", message: "" });

    const handleSnackbarOpen = (type, message) => {
        setSnackbarState({ open: true, type: type, message: message });
    };

    const handleAlertDialogClose = () => {
        setAlertDialogState({
            open: false,
            title: "",
            description: "",
            positiveAnswer: "",
            negativeAnswer: "",
            onApprove: () => { }
        });
    }

    const handleSnackbarClose = () => {
        setSnackbarState({ open: false, type: "", message: "" });
    }

    const columns = [
        {
            id: 'id',
            name: formatMessage({ id: "table.columns.id" }),
            type: 'number',
            minWidth: 75,
            sortable: true, filterable: true
        },
        {
            id: "user",
            name: formatMessage({ id: "contact_messages.table.columns.user" }),
            type: 'text',
            sortable: true, filterable: true,
            minWidth: 200,
            render: (value, row) => {
                if (!row.user_id) {
                    return <Typography variant="h5">{row.fullname} ({row.email})</Typography>;
                }
                return (
                    <Box display={"flex"} alignItems={"center"} gap={"5px"}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            navigate(`/users/${row.user_id}`);
                        }}>
                        <img
                            alt="profile-user"
                            src={row.user_profile_photo_link}
                            width="35px"
                            height="35px"
                            style={{ borderRadius: "50%" }}
                        />
                        <Typography variant="h5">{row.user_name + " " + row.user_surname}</Typography>
                    </Box>
                )
            }
        },
        {
            id: "title",
            name: formatMessage({ id: "contact_messages.table.columns.title" }),
            type: 'text',
            sortable: true, filterable: true
        },
        {
            id: "message",
            name: formatMessage({ id: "contact_messages.table.columns.message" }),
            type: 'text',
            minWidth: 500,
            sortable: true, filterable: true
        },
        {
            id: "uploaded_file",
            name: formatMessage({ id: "contact_messages.table.columns.uploaded_file" }),
            type: 'text',
            sortable: false, filterable: false,
            render: (value, row) => {
                if (!row.content_link) {
                    return "-";
                }
                return (<Button
                    variant="text"
                    size='small'
                    color="success"
                    onClick={() => {
                        //download file from row.content_link
                        const link = document.createElement('a');
                        link.href = row.content_link;
                        link.setAttribute('download', row.content_link);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }}
                >
                    <FormattedMessage id={"see_file"} />
                </Button>
                )
            }
        },
        {
            id: "created_date",
            name: formatMessage({ id: "table.columns.created_date" }),
            type: 'date',
            sortable: true, filterable: true,
            render: (value, row) => {
                return new Date(value).toLocaleDateString();
            }
        },
        {
            id: "actions",
            name: formatMessage({ id: "table.columns.actions" }),
            type: "actions",
            sortable: false, filterable: false,
            getActions: () => [
                {
                    label: <FormattedMessage id={"delete"} />,
                    action: (row) => {
                        setAlertDialogState({
                            open: true,
                            title: <FormattedMessage id={"are_you_sure_to_delete_contact_message"} />,
                            description: <FormattedMessage id={"delete_contact_message_description"} />,
                            positiveAnswer: <FormattedMessage id={"delete"} />,
                            negativeAnswer: <FormattedMessage id={"cancel"} />,
                            onApprove: () => {
                                removeContactMessages([row.id]);
                            }
                        });
                    }
                },
                ...(admin.contact_message_notification_perm === 1 ? [{
                    label: <FormattedMessage id={"send_notification"} />,
                    action: (row) => {
                        setNotificationDialogState({
                            open: true,
                            userIds: [row.user_id],
                            draftType: "report_and_support_answers",
                            onClose: () => { }
                        });
                    }
                }] : [])
            ]
        }
    ];

    const fetchContactMessages = async (paginationModel, orderModel, filterModel, token) => {
        setLoading(true);
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/getContactMessages`,
            {
                startIndex: paginationModel.page * paginationModel.rowsPerPage,
                limit: paginationModel.rowsPerPage,
                orderBy: orderModel ? orderModel.orderBy : null,
                orderType: orderModel ? orderModel.orderType : null,
                filter: filterModel ? filterModel : null
            },
            { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
                setContactMessages([]);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setContactMessages(response.data.contactMessages);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount)
                }
                setLoading(false);
            });
    }

    const removeContactMessages = (contactMessageIds) => {
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/deleteContactMessageBulk`, {
            contactMessageIds: contactMessageIds
        }, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setPaginationModel({ ...paginationModel });
                    handleSnackbarOpen("success", formatMessage({ id: "contact_message_deleted_successfully" }));
                }
            });
    }

    useEffect(() => {
        fetchContactMessages(paginationModel, orderModel, filterModel, token);
    }, [paginationModel, orderModel, filterModel, token]);

    return (
        <Box sx={{ p: "75px" }}>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarState.open}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarState.type} sx={{ width: '100%' }}>
                    {snackbarState.message}
                </Alert>
            </Snackbar>
            {alertDialogState.open &&
                <AlertDialogSlide
                    open={alertDialogState.open}
                    handleClose={(event, reason) => {
                        if (reason && reason === "backdropClick") {
                            return;
                        }
                        handleAlertDialogClose();
                    }}
                    clickPositiveAnswer={() => {
                        alertDialogState.onApprove();
                        handleAlertDialogClose();
                    }}
                    clickNegativeAnswer={() => { }}
                    positiveAnswer={alertDialogState.positiveAnswer}
                    negativeAnswer={alertDialogState.negativeAnswer}
                    title={alertDialogState.title}
                    description={alertDialogState.description}
                />
            }
            {notificationDialogState.open &&
                <NotificationDialog
                    dialogOpen={notificationDialogState.open}
                    notification={{
                        user_ids: [...(notificationDialogState.userIds)],
                    }}
                    onFormSubmit={() => {
                        setNotificationDialogState({
                            open: false,
                            userIds: [],
                            draftType: "report_and_support_answers",
                            onClose: () => { }
                        });
                        notificationDialogState.onClose();
                    }}
                    initialDraftType={notificationDialogState.draftType}
                    handleClose={(event, reason) => {
                        if (reason && reason === "backdropClick") {
                            return;
                        }
                        setNotificationDialogState({
                            open: false,
                            userIds: [],
                            draftType: "report_and_support_answers",
                            onClose: () => { }
                        });
                        notificationDialogState.onClose();
                    }}
                />
            }
            <Box display="flex" alignItems="center" gap="20px">
                <Header
                    title={<FormattedMessage id={"contact_messages.header.title"} />}
                    subtitle={<FormattedMessage id={"contact_messages.header.description"} />} />
            </Box>
            <Table
                table_id={"contact_messages_table"}
                isLoading={isLoading}
                columns={columns}
                rows={contactMessages}
                rowCount={rowCount}
                onOrderChange={(order) => {
                    setOrderModel(order);
                }}
                onPaginationChange={(pagination) => {
                    setPaginationModel(pagination);
                }}
                onFilterChange={(filter) => {
                    setFilterModel(filter);
                }}
                onRowClick={(row) => {
                }}
                additionalButtons={[
                ]}
                enableRowSelection={true}
                selectionActionButtons={[
                    {
                        label: <FormattedMessage id={"delete_selected"} />,
                        color: "error",
                        onClick: (selectedRows, removeSelections) => {
                            setAlertDialogState({
                                open: true,
                                title: <FormattedMessage id={"are_you_sure_to_delete_contact_message"} />,
                                description: <FormattedMessage id={"delete_contact_message_description"} />,
                                positiveAnswer: <FormattedMessage id={"delete"} />,
                                negativeAnswer: <FormattedMessage id={"cancel"} />,
                                onApprove: () => {
                                    removeContactMessages(selectedRows.map((row) => row.id));
                                    removeSelections();
                                }
                            });
                        }
                    }
                ]}
            />
        </Box>
    )
}

export default ContactMessagesPage
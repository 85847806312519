import React, { useContext, useState } from 'react'
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import AdvertisementPhotoDialog from './AdvertisementPhotoDialog';
import { AdminContext } from '../../context/AdminContext';

function AdvertisementDetailsDialog({ advertisement, dialogOpen, handleClose, onEditButtonClick }) {
    const { admin } = useContext(AdminContext);
    const [advertisementPhotoDialogState, setAdvertisementPhotoDialogState] = useState({ open: false, photo_link: null });
    return (
        advertisement &&
        <Dialog
            sx={{ margin: "0 auto" }}
            maxWidth={"80%"}
            open={dialogOpen}
            onClose={(e, reason) => {
                if (reason && reason === "backdropClick") {
                    return;
                }
                handleClose();
            }}>
            {advertisementPhotoDialogState.open && advertisementPhotoDialogState.photo_link &&
                <AdvertisementPhotoDialog
                    advertisementPhotoDialogState={advertisementPhotoDialogState}
                    setAdvertisementPhotoDialogState={setAdvertisementPhotoDialogState}
                />
            }
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    variant="standard"
                    color="error"
                    sx={{
                        borderRadius: "100%",
                        backgroundColor: "white",
                        maxWidth: "40px",
                        maxHeight: "40px",
                        minWidth: "40px",
                        minHeight: "40px",
                        position: "absolute",
                        top: "10px",
                        right: "10px"
                    }}
                    onClick={handleClose}>
                    <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                </Button>
            </Box>
            <Box m="20px" textAlign="center">
                <Typography variant='h3'>
                    <FormattedMessage id={"advertisement_details"} />({advertisement.id}) - <FormattedMessage id={"advertisement_no"} />: {advertisement.advertisement_no}
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "0", paddingBottom: "45px" }}>
                        <Box width={"800px"}>
                            <Typography variant='h4'>
                                <span style={{ fontWeight: "700" }}><FormattedMessage id={"status"} />: </span> {
                                    !(advertisement.is_active && new Date(advertisement.expiration_date) > new Date()) ?
                                        <FormattedMessage id={"archived"} />
                                        :
                                        advertisement.is_featured && new Date(advertisement.featured_end_date) > new Date() ?
                                            <FormattedMessage id={"featured_advertisement_with_expiration_date"} values={{ expiration_date: new Date(advertisement.featured_end_date).toLocaleDateString() + " " + new Date(advertisement.featured_end_date).toLocaleTimeString() }} />
                                            :
                                            <FormattedMessage id={"active_advertisement_with_expiration_date"} values={{ expiration_date: new Date(advertisement.expiration_date).toLocaleDateString() + " " + new Date(advertisement.expiration_date).toLocaleTimeString() }} />
                                }
                            </Typography>
                        </Box>
                        <Box style={{ display: "grid", gridTemplateColumns: "repeat(3, minmax(0, 1fr))", gap: "16px", width: "800px", marginTop: "12px" }}>
                            {advertisement.advertisement_contents.map((content, index) => {
                                return <Box
                                    key={content.id}
                                    component={content.is_photo ? "img" : "video"}
                                    controls
                                    borderRadius="12px"
                                    gridColumn={"span 1"}
                                    src={content.content_link}
                                    alt={`advertisement-sm-${index}`}
                                    width={255}
                                    height={153}
                                    {...(content.is_photo ? {
                                        onClick: () => {
                                            setAdvertisementPhotoDialogState({ open: true, photo_link: content.content_link });
                                        },
                                        style: { cursor: "pointer", objectFit: "cover" }
                                    } : {
                                        style: { objectFit: "contain" }
                                    })}
                                />
                            })}
                        </Box>
                        <Box style={{ width: "800px" }}>
                            <h1 style={{ fontSize: "24px", fontWeight: 900 }}>{advertisement.title}</h1>
                            <p style={{ fontSize: "16px" }}>{advertisement.description}</p>
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "16px"
                                }}
                            >
                                <Box style={{ display: "flex", gap: "4px" }} >
                                    <Box component="img" borderRadius="12px" src={advertisement.user_profile_photo_link} alt="user-profile" width={24} height={24} />
                                    <p style={{ fontSize: "16px", margin: 0 }}>{`${advertisement.user_name} ${advertisement.user_surname}`}</p>
                                </Box>
                                <Box borderRadius="12px" style={{ background: "#F2F0F5", padding: "4px 12px" }}>
                                    <p style={{ fontSize: "16px", margin: 0 }}>{advertisement.country}</p>
                                </Box>
                                <Box borderRadius="12px" style={{ background: "#F2F0F5", padding: "4px 12px" }}>
                                    <p style={{ fontSize: "16px", margin: 0 }}>{advertisement.city}</p>
                                </Box>
                                <Box borderRadius="12px" style={{ background: "#F2F0F5", padding: "4px 12px" }}>
                                    <p style={{ fontSize: "16px", margin: 0 }}>{advertisement.county}</p>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "16px",
                                    mt: "20px"
                                }}
                            >
                                <Box borderRadius="12px" style={{ background: "#F2F0F5", padding: "4px 12px" }}>
                                    <p style={{ fontSize: "16px", margin: 0 }}>{`Görüntülenme: ${advertisement.view_count}`}</p>
                                </Box>
                                <Box borderRadius="12px" style={{ background: "#F2F0F5", padding: "4px 12px" }}>
                                    <p style={{ fontSize: "16px", margin: 0 }}>{`Paylaşılma: ${advertisement.share_count}`}</p>
                                </Box>
                                <Box borderRadius="12px" style={{ background: "#F2F0F5", padding: "4px 12px" }}>
                                    <p style={{ fontSize: "16px", margin: 0 }}>{`Favori: ${advertisement.fav_count}`}</p>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "16px",
                                    mt: "20px"
                                }}
                            >
                                {advertisement.categories && advertisement.categories.map((category, index) =>

                                (<Box key={category.id} borderRadius="12px" style={{ background: "#F2F0F5", padding: "4px 12px" }}>
                                    <p style={{ fontSize: "16px", margin: 0 }}>{category.name}</p>
                                </Box>)
                                )
                                }


                            </Box>
                        </Box>
                    </Box>
                    {admin.advertisement_edit_perm === 1 &&
                        <Box display="flex" justifyContent="end" m="0px 20px 0px 20px">
                            <Button color="secondary" variant="contained" onClick={() => onEditButtonClick(advertisement)}>
                                <FormattedMessage id="edit" />
                            </Button>
                        </Box>
                    }

                </Box>
            </DialogContent>
        </Dialog>

    )
}

export default AdvertisementDetailsDialog
import { Alert, Box, Snackbar } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { AdminContext } from '../../../context/AdminContext';
import { Table } from '../../../components/Table';
import { FormattedMessage, useIntl } from 'react-intl';
import NotificationDialog from '../../NotificationsPage/NotificationDialog';
import AdvertisementDetailsDialog from '../../../components/Advertisement/AdvertisementDetailsDialog';
import AdvertisementEditDialog from '../../../components/Advertisement/AdvertisementEditDialog';
import AlertDialogSlide from '../../../components/AlertDialogSlide';
import dayjs from 'dayjs';

function AdvertisementsTable({ userId }) {
    const { admin, token } = useContext(AdminContext);
    const { formatMessage } = useIntl();
    const [advertisements, setAdvertisements] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [orderModel, setOrderModel] = useState(null);
    const [filterModel, setFilterModel] = useState(null);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        rowsPerPage: admin.advertisements_table_row_count
    });
    const [advertisementCategories, setAdvertisementCategories] = useState([]);

    const [advertisementDetailsDialogState, setAdvertisementDetailsDialogState] = useState({ open: false, advertisement: null });
    const [editAdvertisementDialogState, setEditAdvertisementDialogState] = useState({ open: false, advertisement: null });
    const [alertDialogState, setAlertDialogState] = useState({
        open: false,
        title: "",
        description: "",
        positiveAnswer: "",
        negativeAnswer: "",
        onApprove: () => { }
    });
    const [notificationDialogState, setNotificationDialogState] = useState({
        open: false,
        userIds: [],
        draftType: "advertisement",
        onClose: () => { }
    });
    const [snackbarState, setSnackbarState] = useState({ open: false, type: "", message: "" });
    const handleSnackbarOpen = (type, message) => {
        setSnackbarState({ open: true, type: type, message: message });
    };

    const handleAlertDialogClose = () => {
        setAlertDialogState({
            open: false,
            title: "",
            description: "",
            positiveAnswer: "",
            negativeAnswer: "",
            onApprove: () => { }
        });
    }

    const handleSnackbarClose = () => {
        setSnackbarState({ open: false, type: "", message: "" });
    }



    const columns = [
        {
            id: 'id',
            name: formatMessage({ id: "table.columns.id" }),
            type: 'number',
            sortable: true, filterable: true
        },
        {
            id: 'advertisement_no',
            name: formatMessage({ id: "advertisements.table.columns.advertisement_no" }),
            type: 'text',
            sortable: true, filterable: true
        },
        {
            id: 'content_link',
            name: formatMessage({ id: "advertisements.table.columns.thumbnail" }),
            sortable: false,
            filterable: false,
            minWidth: 200,
            render: (value, row) => {
                return <Box
                    component={row.is_photo ? "img" : "video"}
                    controls
                    borderRadius="12px"
                    src={value}
                    alt={row.title}
                    width={166}
                    height={100}
                    style={{
                        objectFit: row.is_photo ? "cover" : "contain",
                    }}
                />
            }
        },
        {
            id: 'title',
            name: formatMessage({ id: "table.columns.title" }),
            type: 'text',
            sortable: true, filterable: true
        }, {
            id: 'description',
            name: formatMessage({ id: "table.columns.description" }),
            type: 'text',
            minWidth: 250,
            sortable: true, filterable: true
        }, {
            id: 'country',
            name: formatMessage({ id: "users.table.columns.country" }),
            type: 'text',
            sortable: true, filterable: true
        }, {
            id: 'city',
            name: formatMessage({ id: "users.table.columns.city" }),
            type: 'text',
            sortable: true, filterable: true
        }, {
            id: 'county',
            name: formatMessage({ id: "users.table.columns.county" }),
            type: 'text',
            sortable: true, filterable: true
        }, {
            id: 'category_1',
            name: formatMessage({ id: "advertisements.table.columns.category_1" }),
            type: 'select',
            options: advertisementCategories.map((category) => ({ value: category.id, label: category.name })),
            sortable: false, filterable: false,
            render: (value, row) => {
                if (row.categories && row.categories.length > 0) {
                    return row.categories[0].name;
                } else {
                    return "-";
                }
            }
        }, {
            id: 'category_2',
            name: formatMessage({ id: "advertisements.table.columns.category_2" }),
            type: 'select',
            options: advertisementCategories.map((category) => ({ value: category.id, label: category.name })),
            sortable: false, filterable: false,
            render: (value, row) => {
                if (row.categories && row.categories.length > 1) {
                    return row.categories[1].name;
                } else {
                    return "-";
                }
            }
        }, {
            id: 'view_count',
            name: formatMessage({ id: "advertisements.table.columns.view_count" }),
            type: 'number',
            sortable: true, filterable: true
        },
        {
            id: 'fav_count',
            name: formatMessage({ id: "advertisements.table.columns.fav_count" }),
            type: 'number',
            sortable: true, filterable: true
        },
        {
            id: 'share_count',
            name: formatMessage({ id: "advertisements.table.columns.share_count" }),
            type: 'number',
            sortable: true, filterable: true
        },
        {
            id: "expiration_date",
            name: formatMessage({ id: "advertisements.table.columns.expiration_date" }),
            type: 'date',
            sortable: true, filterable: true,
            render: (value) => {
                return new Date(value).toLocaleDateString()
            }
        },
        {
            id: "created_date",
            name: formatMessage({ id: "table.columns.created_date" }),
            type: 'date',
            sortable: true, filterable: true,
            render: (value) => {
                return new Date(value).toLocaleDateString()
            }
        }, {
            id: "actions",
            name: formatMessage({ id: "table.columns.actions" }),
            type: "actions",
            sortable: false, filterable: false,
            getActions: () => [
                {
                    label: <FormattedMessage id={"show_details"} />,
                    action: (row) => {
                        setAdvertisementDetailsDialogState({ open: true, advertisement: row });
                    }
                },
                ...(admin.advertisement_deletion_perm === 1 ? [{
                    label: <FormattedMessage id={"delete"} />,
                    action: (row) => {
                        setAlertDialogState({
                            open: true,
                            title: <FormattedMessage id={"are_you_sure_to_delete_advertisement"} />,
                            description: <FormattedMessage id={"delete_advertisement_description"} />,
                            positiveAnswer: <FormattedMessage id={"delete"} />,
                            negativeAnswer: <FormattedMessage id={"cancel"} />,
                            onApprove: () => {
                                deleteAdvertisements([row.id]);
                            }
                        });
                    }
                }] : []),
                ...(admin.advertisement_edit_perm === 1 ? [{
                    label: <FormattedMessage id={"activate_deactivate"} />,
                    action: (row) => {
                        if (row.is_truly_active) {
                            setAlertDialogState({
                                open: true,
                                title: <FormattedMessage id={"are_you_sure_to_deactivate_advertisement"} />,
                                description: <FormattedMessage id={"deactivate_advertisement_description"} />,
                                positiveAnswer: <FormattedMessage id={"yes"} />,
                                negativeAnswer: <FormattedMessage id={"no"} />,
                                onApprove: () => {
                                    updateAdvertisement(row.id, { is_active: 0 }, [], row.user_id);
                                }
                            });
                        } else {
                            //use dayjs to MySQL datetime format
                            let expirationDate = dayjs().add(28, "day").format("YYYY-MM-DD HH:mm:ss");
                            setAlertDialogState({
                                open: true,
                                title: <FormattedMessage id={"are_you_sure_to_activate_advertisement"} />,
                                description: <FormattedMessage id={"activate_advertisement_description"} />,
                                positiveAnswer: <FormattedMessage id={"yes"} />,
                                negativeAnswer: <FormattedMessage id={"no"} />,
                                onApprove: () => {
                                    updateAdvertisement(row.id, { is_active: 1, expiration_date: expirationDate }, [], row.user_id);
                                }
                            });
                        }
                    }
                }] : []),
                ...(admin.advertisement_notification_perm === 1 && !(userId) ? [{
                    label: <FormattedMessage id={"send_notification"} />,
                    action: (row) => {
                        setNotificationDialogState({
                            open: true,
                            userIds: [row.user_id],
                            draftType: "advertisement",
                            onClose: () => { }
                        });
                    }
                }] : []),
            ]
        }
    ];

    const fetchAdvertisements = async (paginationModel, orderModel, filterModel, token, language, userId) => {
        setLoading(true);
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/getAdvertisements`,
            {
                startIndex: paginationModel.page * paginationModel.rowsPerPage,
                limit: paginationModel.rowsPerPage,
                orderBy: orderModel ? orderModel.orderBy : null,
                orderType: orderModel ? orderModel.orderType : null,
                filter: filterModel ? (userId ? [
                    ...filterModel, {
                        id: "user_id",
                        value: userId,
                        type: "number",
                        min: true
                    },
                    {
                        id: "user_id",
                        value: userId,
                        type: "number",
                        max: true
                    }
                ] : filterModel) : (userId ? [
                    {
                        id: "user_id",
                        value: userId,
                        type: "number",
                        min: true
                    },
                    {
                        id: "user_id",
                        value: userId,
                        type: "number",
                        max: true
                    }
                ] : null),
                language: language
            },
            { headers: { token: token } }
        )
            .catch((err) => {
                setAdvertisements([]);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setAdvertisements(response.data.advertisements);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                }
                setLoading(false);
            });
    }

    const fetchAdvertisementCategories = async (token, language) => {
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/getCategories`,
            {
                orderBy: "name",
                orderType: "ASC",
                filter: [{
                    id: "for_advertisements",
                    value: 1,
                    type: "select",
                }],
                language: language
            },
            { headers: { token: token } }
        )
            .catch((err) => {
                setAdvertisementCategories([]);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setAdvertisementCategories(response.data.categories);
                }
            });
    }

    const updateAdvertisement = async (advertisementId, advertisement, advertisementContents, userId) => {
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/updateAdvertisement?advertisementId=${advertisementId}`,
            {
                advertisement: advertisement
            },
            { headers: { token: token } }
        )
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    if (advertisementContents?.length > 0) {
                        axios.patch(
                            `${process.env.REACT_APP_API_URL}webAdmin/updateAdvertisementContents?advertisementId=${advertisementId}`,
                            {
                                advertisementContents: advertisementContents
                            },
                            { headers: { token: token } }
                        ).catch((err) => {
                            console.log("err: " + err);
                        }).then((response) => {
                            if (response && response.data.success === true && response.data.status === 201) {
                                handleSnackbarOpen("success", formatMessage({ id: "advertisement_updated_successfully" }));
                                let advertisementIx = advertisements.findIndex(({ id }) => id === advertisementId);
                                if (advertisementIx >= 0) {
                                    setPaginationModel({ ...paginationModel });
                                }
                                if (userId && admin.advertisement_notification_perm === 1) {
                                    setNotificationDialogState({
                                        open: true,
                                        userIds: [userId],
                                        draftType: "after_advertisement_edit",
                                        onClose: () => { setEditAdvertisementDialogState({ open: false, advertisement: null }); },
                                    });
                                } else {
                                    setEditAdvertisementDialogState({ open: false, advertisement: null });
                                }
                            }
                        });
                    } else {
                        handleSnackbarOpen("success", formatMessage({ id: "advertisement_updated_successfully" }));
                        let advertisementIx = advertisements.findIndex(({ id }) => id === advertisementId);
                        if (advertisementIx >= 0) {
                            setPaginationModel({ ...paginationModel });
                        }
                        if (userId && admin.advertisement_notification_perm === 1) {
                            setNotificationDialogState({
                                open: true,
                                userIds: [userId],
                                draftType: "after_advertisement_edit",
                                onClose: () => { setEditAdvertisementDialogState({ open: false, advertisement: null }); },
                            });
                        } else {
                            setEditAdvertisementDialogState({ open: false, advertisement: null });
                        }
                    }
                }
            });
    }

    const updateAdvertisementBulk = async (advertisementIds, advertisement, userIds) => {
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/updateAdvertisementBulk`,
            {
                advertisementIds: advertisementIds,
                advertisement: advertisement
            },
            { headers: { token: token } }
        ).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                handleSnackbarOpen("success", formatMessage({ id: "advertisement_updated_successfully" }));
                setPaginationModel({
                    page: 0,
                    rowsPerPage: admin.advertisements_table_row_count
                });
                if (userIds.length > 0 && admin.advertisement_notification_perm === 1) {
                    setNotificationDialogState({
                        open: true,
                        userIds: userIds,
                        draftType: "after_advertisement_edit",
                        onClose: () => { },
                    });
                }
            }
        });
    }

    const deleteAdvertisements = async (advertisementIds) => {
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/deleteAdvertisementBulk`,
            {
                advertisementIds: advertisementIds
            },
            { headers: { token: token } }
        ).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                handleSnackbarOpen("success", formatMessage({ id: "advertisement_deleted_successfully" }));
                setPaginationModel({
                    page: 0,
                    rowsPerPage: admin.advertisements_table_row_count
                });
            }
        });
    }

    useEffect(() => {
        fetchAdvertisements(paginationModel, orderModel, filterModel, token, admin.language, userId);
        fetchAdvertisementCategories(token, admin.language);
    }, [paginationModel, orderModel, filterModel, token, admin, userId]);

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarState.open}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarState.type} sx={{ width: '100%' }}>
                    {snackbarState.message}
                </Alert>
            </Snackbar>
            {
                (advertisementDetailsDialogState?.open && advertisementDetailsDialogState.advertisement) &&
                <AdvertisementDetailsDialog
                    dialogOpen={advertisementDetailsDialogState.open}
                    handleClose={() => {
                        setAdvertisementDetailsDialogState({ open: false, advertisement: null });
                    }}
                    advertisement={advertisementDetailsDialogState.advertisement}
                    onEditButtonClick={(advertisement) => {
                        setEditAdvertisementDialogState({ open: true, advertisement: advertisement });
                        setAdvertisementDetailsDialogState({ open: false, advertisement: null });
                    }}
                />
            }
            {
                editAdvertisementDialogState?.open && editAdvertisementDialogState.advertisement &&
                <AdvertisementEditDialog
                    advertisement={editAdvertisementDialogState.advertisement}
                    dialogOpen={editAdvertisementDialogState.open}
                    handleClose={() => {
                        setEditAdvertisementDialogState({ open: false, advertisement: null });
                    }}
                    handleFormSubmit={(values) => {
                        let advertisement = { ...values };
                        //parse advertisement.expiratio_date and advertisement.featured_end_date to MySQL datetime format
                        if (advertisement.expiration_date) {
                            advertisement.expiration_date = advertisement.expiration_date.format("YYYY-MM-DD HH:mm:ss");
                        }
                        if (advertisement.featured_end_date) {
                            advertisement.featured_end_date = advertisement.featured_end_date.format("YYYY-MM-DD HH:mm:ss");
                        }
                        advertisement.is_active = advertisement.is_active ? 1 : 0;
                        advertisement.is_featured = advertisement.is_featured ? 1 : 0;
                        advertisement.categoryIds = advertisement.categories.map((category) => category.id);
                        delete advertisement.categories;
                        let advertisementContents = [];
                        advertisement.advertisement_contents.forEach((content, index) => {
                            advertisementContents.push({ content_path: content.content_path, is_photo: content.is_photo, position: index, advertisement_id: editAdvertisementDialogState.advertisement.id });
                        });
                        delete advertisement.advertisement_contents;
                        if (advertisementContents.length === 0) {
                            advertisementContents.push({
                                content_path: "images/owajoo/advertisements/default.png",
                                is_photo: 1,
                                position: 0,
                                advertisement_id: editAdvertisementDialogState.advertisement.id
                            });
                        }

                        setAlertDialogState({
                            open: true,
                            title: <FormattedMessage id={"are_you_sure_to_update_advertisement"} />,
                            description: <FormattedMessage id={"update_advertisement_description"} />,
                            positiveAnswer: <FormattedMessage id={"yes"} />,
                            negativeAnswer: <FormattedMessage id={"no"} />,
                            onApprove: () => {
                                updateAdvertisement(editAdvertisementDialogState.advertisement.id, advertisement, advertisementContents);
                            }
                        });
                    }}
                />
            }
            {alertDialogState.open &&
                <AlertDialogSlide
                    open={alertDialogState.open}
                    handleClose={(event, reason) => {
                        if (reason && reason === "backdropClick") {
                            return;
                        }
                        handleAlertDialogClose();
                    }}
                    clickPositiveAnswer={() => {
                        alertDialogState.onApprove();
                        setAlertDialogState({ open: false, type: "" });
                    }}
                    clickNegativeAnswer={() => { }}
                    positiveAnswer={alertDialogState.positiveAnswer}
                    negativeAnswer={alertDialogState.negativeAnswer}
                    title={alertDialogState.title}
                    description={alertDialogState.description}
                />
            }
            {notificationDialogState.open &&
                <NotificationDialog
                    dialogOpen={notificationDialogState.open}
                    notification={{
                        user_ids: [...(notificationDialogState.userIds)],
                    }}
                    onFormSubmit={() => {
                        setNotificationDialogState({
                            open: false,
                            userIds: [],
                            draftType: "advertisement",
                            onClose: () => { }
                        });
                        notificationDialogState.onClose();
                    }}
                    initialDraftType={notificationDialogState.draftType}
                    handleClose={(event, reason) => {
                        if (reason && reason === "backdropClick") {
                            return;
                        }
                        setNotificationDialogState({
                            open: false,
                            userIds: [],
                            draftType: "advertisement",
                            onClose: () => { }
                        });
                        notificationDialogState.onClose();
                    }}
                />
            }
            <Table
                table_id={"advertisements_table"}
                isLoading={isLoading}
                columns={columns}
                rows={advertisements}
                rowCount={rowCount}
                onOrderChange={(order) => {
                    setOrderModel(order);
                }}
                onPaginationChange={(pagination) => {
                    setPaginationModel(pagination);
                }}
                onFilterChange={(filter) => {
                    setFilterModel(filter);
                }}
                onRowClick={(row) => {
                }}
                additionalButtons={[
                ]}
                additionalFilters={[
                    {
                        id: "category_ids",
                        type: "multiple-select",
                        label: formatMessage({ id: "categories" }).toUpperCase(),
                        value: [],
                        options: advertisementCategories.map((category) => ({ value: category.id, label: category.name }))
                    }
                ]}
                enableRowSelection={(admin.advertisement_edit_perm === 1 || admin.advertisement_deletion_perm === 1 || (admin.advertisement_notification_perm === 1 && !(userId)))}
                selectionActionButtons={[
                    ...(admin.advertisement_edit_perm === 1 ? [{
                        label: <FormattedMessage id={"activate_selected"} />,
                        color: "success",
                        onClick: (selectedRows, removeSelections) => {
                            setAlertDialogState({
                                open: true,
                                title: <FormattedMessage id={"are_you_sure_to_activate_selected_advertisements"} />,
                                description: <FormattedMessage id={"activate_selected_advertisements_description"} />,
                                positiveAnswer: <FormattedMessage id={"yes"} />,
                                negativeAnswer: <FormattedMessage id={"no"} />,
                                onApprove: () => {
                                    let expirationDate = dayjs().add(28, "day").format("YYYY-MM-DD HH:mm:ss");
                                    updateAdvertisementBulk(selectedRows.map((row) => row.id), { is_active: 1, expiration_date: expirationDate }, selectedRows.map((row) => row.user_id));
                                    removeSelections();
                                }
                            });
                        }
                    },
                    {
                        label: <FormattedMessage id={"deactivate_selected"} />,
                        color: "error",
                        onClick: (selectedRows, removeSelections) => {
                            setAlertDialogState({
                                open: true,
                                title: <FormattedMessage id={"are_you_sure_to_deactivate_selected_advertisements"} />,
                                description: <FormattedMessage id={"deactivate_selected_advertisements_description"} />,
                                positiveAnswer: <FormattedMessage id={"yes"} />,
                                negativeAnswer: <FormattedMessage id={"no"} />,
                                onApprove: () => {
                                    updateAdvertisementBulk(selectedRows.map((row) => row.id), { is_active: 0 }, selectedRows.map((row) => row.user_id));
                                    removeSelections();
                                }
                            });
                        }
                    }] : []),
                    ...(admin.advertisement_deletion_perm === 1 ? [{
                        label: <FormattedMessage id={"delete_selected"} />,
                        color: "error",
                        onClick: (selectedRows, removeSelections) => {
                            setAlertDialogState({
                                open: true,
                                title: <FormattedMessage id={"are_you_sure_to_delete_selected_advertisements"} />,
                                description: <FormattedMessage id={"delete_selected_advertisements_description"} />,
                                positiveAnswer: <FormattedMessage id={"delete"} />,
                                negativeAnswer: <FormattedMessage id={"cancel"} />,
                                onApprove: () => {
                                    deleteAdvertisements(selectedRows.map((row) => row.id));
                                    removeSelections();
                                }
                            });
                        }
                    }] : []),
                    ...(admin.advertisement_notification_perm === 1 && !(userId) ? [{
                        label: <FormattedMessage id={"send_notification_to_selected"} />,
                        color: "primary",
                        onClick: (selectedRows, removeSelections) => {
                            setNotificationDialogState({
                                open: true,
                                userIds: selectedRows.map((row) => row.user_id),
                                draftType: "advertisement",
                                onClose: () => { }
                            });
                            removeSelections();
                        }
                    }] : [])
                ]}
            />
        </>
    )
}

export default AdvertisementsTable
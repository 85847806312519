import { Box, Button, Checkbox, FormControl, IconButton, InputLabel, Menu, MenuItem, Select, TableCell, TextField } from '@mui/material';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FaRegArrowAltCircleDown, FaRegArrowAltCircleUp } from "react-icons/fa"
import { AdminContext } from '../context/AdminContext';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

const createColumns = (columns) => {
    return columns.map((column) => ({
        column: column,
        ref: useRef(),
        minWidth: column.minWidth || 100
    }));
}

export function Table({ table_id, rows, columns, rowCount, isLoading, onFilterChange, onOrderChange, onPaginationChange, onRowClick, additionalButtons, additionalFilters, enableRowSelection, selectionActionButtons }) {
    const cols = createColumns(columns);
    const columnsForFilter = [];

    const [selectedRows, setSelectedRows] = useState([]);

    for (let i = 0; i < columns.length; i++) {
        if (columns[i].filterable) {
            if (columns[i].type === "number" || columns[i].type === "date") {
                columnsForFilter.push({
                    id: columns[i].id,
                    value: columns[i].type === "date" ? null : "",
                    type: columns[i].type,
                    min: true,
                    max: false
                });
                columnsForFilter.push({
                    id: columns[i].id,
                    value: columns[i].type === "date" ? null : "",
                    type: columns[i].type,
                    min: false,
                    max: true
                });
            } else if (columns[i].type === "boolean" || columns[i].type === "select") {
                columnsForFilter.push({
                    id: columns[i].id,
                    value: "",
                    type: columns[i].type
                })
            } else {
                columnsForFilter.push({
                    id: columns[i].id,
                    value: "",
                    type: columns[i].type
                })
            }
        }
    }
    for (let i = 0; i < additionalFilters?.length; i++) {
        if (additionalFilters[i].type === "multiple-select") {
            columnsForFilter.push({
                id: additionalFilters[i].id,
                value: [],
                type: additionalFilters[i].type
            })
        }
    }

    const { admin, setAdmin, token } = useContext(AdminContext);

    const [tableHeight, setTableHeight] = useState("auto");
    const [activeIndex, setActiveIndex] = useState(null);
    const tableElement = useRef(null);

    const mouseMove = useCallback((e) => {
        const gridColumns = cols.map((col, i) => {
            if (i === activeIndex) {
                // Calculate the column width
                const width = e.clientX - col.ref.current.getBoundingClientRect().left;

                if (width >= col.minWidth) {
                    return `${width}px`;
                }
            }

            // Otherwise return the previous width (no changes)
            return `${col.ref.current.offsetWidth}px`;
        });

        // Assign the px values to the table
        tableElement.current.style.gridTemplateColumns = enableRowSelection ? `70px ${gridColumns.join(' ')}` : `${gridColumns.join(' ')}`;
    }, [activeIndex, cols]);

    const removeListeners = useCallback(() => {
        window.removeEventListener('mousemove', mouseMove);
        window.removeEventListener('mouseup', removeListeners);
    }, [mouseMove]);

    const mouseUp = useCallback(() => {
        setActiveIndex(null);
        removeListeners();
    }, [setActiveIndex, removeListeners]);


    const [hoveredRow, setHoveredRow] = useState(null);
    const [order, setOrder] = useState({
        orderBy: null,
        orderType: null
    });
    const [filter, setFilter] = useState(columnsForFilter);
    const [pagination, setPagination] = useState({
        page: 0,
        rowsPerPage: admin[table_id + "_row_count"]
    });


    const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState(null);
    const filterMenuOpen = Boolean(filterMenuAnchorEl);
    const handleFilterButtonClick = (event) => {
        setFilterMenuAnchorEl(event.currentTarget);
    };
    const handleFilterMenuClose = (reason) => {
        if (!reason) {
            setFilterMenuAnchorEl(null);
        }
    };

    const rowToApplyActionsRef = useRef(null);
    const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState(null);
    const actionsMenuOpen = Boolean(actionsMenuAnchorEl);
    const handleActionsButtonClick = (event) => {
        setActionsMenuAnchorEl(event.currentTarget);
    };
    const handleActionsMenuClose = () => {
        rowToApplyActionsRef.current = null;
        setActionsMenuAnchorEl(null);
    };

    useEffect(() => {
        setTableHeight(tableElement.current.offsetHeight);
    }, []);

    useEffect(() => {
        if (activeIndex !== null) {
            window.addEventListener('mousemove', mouseMove);
            window.addEventListener('mouseup', mouseUp);
        }

        return () => {
            removeListeners();
        }
    }, [activeIndex, mouseMove, mouseUp, removeListeners]);

    const updateAdmin = (rowCount) => {
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateAdmin?adminId=${admin.id}`, {
            admin: {
                [table_id + "_row_count"]: rowCount
            }
        }, {
            headers: {
                token: token
            }
        }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {

            }
        });
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "start",
                        gap: "16px",
                    }}
                >
                    {enableRowSelection && selectionActionButtons?.length > 0 && selectedRows.length > 0 && selectionActionButtons.map((button, index) => (
                        <Button
                            key={index}
                            variant="text"
                            size='small'
                            color={button.color || "success"}
                            onClick={() => button.onClick(selectedRows, () => setSelectedRows([]))}
                        >
                            {button.label}
                        </Button>
                    ))
                    }
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "16px",
                    }}
                >
                    {additionalButtons && additionalButtons.map((button, index) => (
                        <Button
                            key={index}
                            variant="contained"
                            color={button.color || "success"}
                            onClick={button.onClick}
                        >
                            {button.label}
                        </Button>
                    ))
                    }
                    {
                        columns.filter(column => column.filterable).length > 0 && onFilterChange &&
                        <Button
                            variant="contained"
                            color="success"
                            disabled={columns.filter(column => column.filterable).length === 0 || isLoading}
                            onClick={handleFilterButtonClick}
                        >
                            <FormattedMessage id="table.filter" />
                        </Button>
                    }
                </Box>
            </Box>

            {columns.filter(column => column.type === "actions").length > 0 &&
                <Menu
                    anchorEl={actionsMenuAnchorEl}
                    open={actionsMenuOpen}
                    onClose={handleActionsMenuClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {
                        columns.filter(column => column.type === "actions")[0].getActions().map((action, index) => {
                            return (
                                <MenuItem key={index} onClick={() => {
                                    action.action(rowToApplyActionsRef.current);
                                    handleActionsMenuClose();
                                }}>
                                    {action.label}
                                </MenuItem>
                            )
                        })
                    }
                </Menu>
            }
            {(columns.filter(column => column.filterable).length > 0 || additionalFilters?.length > 0) && onFilterChange &&
                <Menu
                    anchorEl={filterMenuAnchorEl}
                    open={filterMenuOpen}
                    onClose={(reason) => handleFilterMenuClose(reason)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "10px 10px 10px 20px",
                            height: "75px",
                            backgroundColor: "#fff",
                        }}
                    >
                        <h3><FormattedMessage id="table.filter" /></h3>
                        <Box>
                            <Button
                                variant="standard"
                                color="error"
                                sx={{
                                    borderRadius: "100%"
                                }}
                                size='small'
                                onClick={
                                    () => {
                                        onFilterChange(filter);
                                        handleFilterMenuClose();
                                    }
                                }
                            >
                                <h4 style={{ color: "green" }}>✔</h4>
                            </Button>
                            <Button
                                variant="standard"
                                color="error"
                                sx={{
                                    borderRadius: "100%"
                                }}
                                size='small'
                                onClick={
                                    () => {
                                        setFilter(columnsForFilter);
                                        onFilterChange(columnsForFilter);
                                        handleFilterMenuClose();
                                    }
                                }
                            >
                                <h4 style={{ color: "red" }}>✘</h4>
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        //scrolable
                        sx={{
                            maxHeight: "60vh",
                            overflow: "auto",
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            padding: "10px",
                            backgroundColor: "#fff",
                        }}
                    >
                        {columns.map((column, index) => (
                            column.filterable && (
                                <Box key={index}
                                    sx={{
                                        mt: "10px",
                                        px: "10px",
                                    }}
                                >
                                    {
                                        (column.type === 'text' || !column.type) &&
                                        <TextField
                                            label={column.name}
                                            fullWidth
                                            value={filter.find(f => f.id === column.id).value}
                                            type={column.type === 'number' ? 'number' : 'text'}
                                            onChange={(e) => {
                                                setFilter(prev => {
                                                    var newFilter = [...prev];
                                                    var filterIndex = newFilter.findIndex(f => f.id === column.id);
                                                    newFilter[filterIndex] = {
                                                        id: column.id,
                                                        value: e.target.value,
                                                        type: "text"
                                                    }
                                                    return newFilter;
                                                });
                                            }}
                                        />
                                    }
                                    {
                                        column.type === "boolean" &&
                                        <FormControl fullWidth>
                                            <InputLabel id={`${column.id}-label`}>{column.name}</InputLabel>
                                            <Select
                                                label={column.name}
                                                value={filter.find(f => f.id === column.id).value}
                                                fullWidth
                                                onChange={(e) => {
                                                    setFilter(prev => {
                                                        var newFilter = [...prev];
                                                        var filterIndex = newFilter.findIndex(f => f.id === column.id);
                                                        newFilter[filterIndex] = {
                                                            id: column.id,
                                                            value: e.target.value,
                                                            type: "boolean"
                                                        }
                                                        return newFilter;
                                                    });
                                                }}
                                            >
                                                <MenuItem value={""}>
                                                    <FormattedMessage id="all" />
                                                </MenuItem>
                                                <MenuItem value={1}>
                                                    <FormattedMessage id="yes" />
                                                </MenuItem>
                                                <MenuItem value={0}>
                                                    <FormattedMessage id="no" />
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                    {
                                        column.type === "select" &&
                                        <FormControl fullWidth>
                                            <InputLabel id={`${column.id}-label`}>{column.name}</InputLabel>
                                            <Select
                                                labelId={`${column.id}-label`}
                                                label={column.name}
                                                value={filter.find(f => f.id === column.id).value}
                                                fullWidth
                                                onChange={(e) => {
                                                    setFilter(prev => {
                                                        var newFilter = [...prev];
                                                        var filterIndex = newFilter.findIndex(f => f.id === column.id);
                                                        newFilter[filterIndex] = {
                                                            id: column.id,
                                                            value: e.target.value,
                                                            type: "select"
                                                        }
                                                        return newFilter;
                                                    });
                                                }}
                                            >
                                                <MenuItem value={""}>
                                                    <FormattedMessage id="all" />
                                                </MenuItem>
                                                {column.options.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    }
                                    {
                                        column.type === "number" &&
                                        <Box
                                            sx={{
                                                my: "20px",
                                            }}
                                        >
                                            <TextField
                                                label={<>{column.name} {"("}<FormattedMessage id="table.min" />{")"}</>}
                                                fullWidth
                                                value={filter.find(f => f.id === column.id && f.min).value}
                                                type={'number'}
                                                onChange={(e) => {
                                                    setFilter(prev => {
                                                        var newFilter = [...prev];
                                                        var filterIndex = newFilter.findIndex(f => f.id === column.id && f.min);
                                                        newFilter[filterIndex] = {
                                                            id: column.id,
                                                            value: e.target.value,
                                                            min: true,
                                                            type: "number"
                                                        }
                                                        return newFilter;
                                                    });
                                                }}
                                            />

                                            <TextField
                                                label={<>{column.name} {"("}<FormattedMessage id="table.max" />{")"}</>}
                                                fullWidth
                                                value={filter.find(f => f.id === column.id && f.max).value}
                                                type={'number'}
                                                sx={{
                                                    mt: "4px"
                                                }}
                                                onChange={(e) => {
                                                    setFilter(prev => {
                                                        var newFilter = [...prev];
                                                        var filterIndex = newFilter.findIndex(f => f.id === column.id && f.max);
                                                        newFilter[filterIndex] = {
                                                            id: column.id,
                                                            value: e.target.value,
                                                            max: true,
                                                            type: "number"
                                                        }
                                                        return newFilter;
                                                    });
                                                }}
                                            />

                                        </Box>
                                    }
                                    {
                                        column.type === "date" &&
                                        <Box
                                            sx={{
                                                my: "20px",
                                                display: "flex",
                                                gap: "10px",
                                                justifyContent: "space-between"
                                            }}
                                        >

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label={<>{column.name} {"("}<FormattedMessage id="table.min" />{")"}</>}
                                                    fullWidth
                                                    value={filter.find(f => f.id === column.id && f.min).value}
                                                    type="text"
                                                    onChange={(date) => {
                                                        setFilter(prev => {
                                                            var newFilter = [...prev];
                                                            var filterIndex = newFilter.findIndex(f => f.id === column.id && f.min);
                                                            newFilter[filterIndex] = {
                                                                id: column.id,
                                                                value: date,
                                                                min: true,
                                                                type: "date"
                                                            }
                                                            return newFilter;
                                                        });
                                                    }}
                                                />
                                            </LocalizationProvider>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label={<>{column.name} {"("}<FormattedMessage id="table.max" />{")"}</>}
                                                    fullWidth
                                                    value={filter.find(f => f.id === column.id && f.max).value}
                                                    type="text"
                                                    onChange={(date) => {
                                                        setFilter(prev => {
                                                            var newFilter = [...prev];
                                                            var filterIndex = newFilter.findIndex(f => f.id === column.id && f.max);
                                                            newFilter[filterIndex] = {
                                                                id: column.id,
                                                                value: date,
                                                                max: true,
                                                                type: "date"
                                                            }
                                                            return newFilter;
                                                        });
                                                    }}
                                                />
                                            </LocalizationProvider>

                                        </Box>
                                    }
                                </Box>
                            )
                        ))}
                        {additionalFilters?.map((additionalFilter, index) => (
                            <Box key={index}
                                sx={{
                                    mt: "10px",
                                    px: "10px",
                                }}
                            >
                                {
                                    additionalFilter.type === "multiple-select" &&
                                    <FormControl fullWidth>
                                        <InputLabel id={`${additionalFilter.id}-label`}>{additionalFilter.label}</InputLabel>
                                        <Select
                                            labelId={`${additionalFilter.id}-label`}
                                            label={additionalFilter.label}
                                            value={filter.find(f => f.id === additionalFilter.id).value}
                                            fullWidth
                                            onChange={(e) => {
                                                setFilter(prev => {
                                                    var newFilter = [...prev];
                                                    var filterIndex = newFilter.findIndex(f => f.id === additionalFilter.id);
                                                    newFilter[filterIndex] = {
                                                        id: additionalFilter.id,
                                                        value: [e.target.value],
                                                        type: "multiple-select"
                                                    }
                                                    return newFilter;
                                                });
                                            }}
                                        >
                                            <MenuItem value={""}>
                                                <FormattedMessage id="all" />
                                            </MenuItem>
                                            {additionalFilter.options.map((option, index) => (
                                                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                }
                            </Box>
                        ))}
                    </Box>
                </Menu>}
            <table
                bordercolor="#e0e0e0"
                ref={tableElement}
                style={{
                    display: "grid",
                    gridTemplateColumns: enableRowSelection ? "70px " + columns.map(column => "1fr").join(" ") : columns.map(column => "1fr").join(" "),
                    borderCollapse: "collapse",
                    width: "100%",
                    overflowX: "auto",
                    overflowY: "hidden",
                    border: "1px solid #e0e0e0",
                    borderRadius: "4px",
                    marginTop: "8px"
                }}>
                <thead
                    style={{
                        display: "contents"
                    }}
                >
                    <tr style={{
                        display: "contents",
                        border: "1px solid #e0e0e0"
                    }}>
                        {enableRowSelection &&
                            <th
                                style={{
                                    position: "relative",
                                    cursor: "default",
                                    userSelect: "none",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    display: "flex",
                                    gap: "8px",
                                    minWidth: "70px",
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "calc(100% - 7px)",
                                        padding: "16px",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                    }}
                                >

                                </Box>
                            </th>
                        }
                        {cols.map(({ column, ref, minWidth }, index) => (
                            <th
                                ref={ref}
                                key={index}
                                style={{
                                    position: "relative",
                                    cursor: column.sortable ? "pointer" : "default",
                                    userSelect: "none",
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    display: "flex",
                                    gap: "8px",
                                    minWidth: `${minWidth}px`,
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "calc(100% - 7px)",
                                        padding: "16px",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                    }}
                                    onClick={() => {
                                        if (column.sortable) {
                                            if (order.orderBy === column.id) {
                                                if (order.orderType === 'ASC') {
                                                    setOrder({
                                                        orderBy: column.id,
                                                        orderType: 'DESC'
                                                    });
                                                    onOrderChange({
                                                        orderBy: column.id,
                                                        orderType: 'DESC'
                                                    });
                                                } else {
                                                    setOrder({
                                                        orderBy: null,
                                                        orderType: null
                                                    });
                                                    onOrderChange({
                                                        orderBy: null,
                                                        orderType: null
                                                    });
                                                }
                                            } else {
                                                setOrder({
                                                    orderBy: column.id,
                                                    orderType: 'ASC'
                                                });
                                                onOrderChange({
                                                    orderBy: column.id,
                                                    orderType: 'ASC'
                                                });
                                            }
                                            setPagination({
                                                ...pagination,
                                                page: 0
                                            });
                                            onPaginationChange({
                                                ...pagination,
                                                page: 0
                                            });
                                        }
                                    }}
                                >
                                    <span
                                        style={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "block",
                                        }}
                                    >
                                        {column.name}
                                    </span>
                                    {order.orderBy === column.id && order.orderType === 'ASC' &&
                                        <Box height={16} width={16}>
                                            <FaRegArrowAltCircleUp color='grey' />
                                        </Box>
                                    }
                                    {order.orderBy === column.id && order.orderType === 'DESC' &&
                                        <Box height={16} width={16}>
                                            <FaRegArrowAltCircleDown color='grey' />
                                        </Box>
                                    }
                                </Box>
                                <Box
                                    style={{ height: tableHeight }}
                                    onMouseDown={() => setActiveIndex(index)}
                                    sx={{
                                        display: "block",
                                        position: "absolute",
                                        cursor: "col-resize",
                                        width: "7px",
                                        right: 0,
                                        top: 0,
                                        zIndex: 1,
                                        borderRight: "2px solid transparent",
                                        ":hover": {
                                            borderColor: "#ccc"
                                        },
                                        borderColor: activeIndex === index ? "#517ea5" : "transparent"
                                    }}
                                />
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody
                    style={{
                        display: "contents"
                    }}
                >
                    {rows.map((row, index) => (
                        <tr
                            key={index}
                            style={{
                                display: "contents",
                                border: "1px solid #e0e0e0"
                            }}
                            onMouseEnter={() => {
                                setHoveredRow(row);
                            }}
                            onMouseLeave={() => {
                                setHoveredRow(null);
                            }}
                        >
                            {enableRowSelection &&
                                <TableCell
                                    key={index}
                                    sx={{
                                        padding: "8px 16px",
                                        borderTop: "1px solid #e0e0e0",
                                        display: "flex",
                                        alignItems: "center",
                                        backgroundColor: hoveredRow?.id === row.id ? "#f0f0f0" : "transparent",
                                        minWidth: `50px`
                                    }}
                                >
                                    {
                                        //checkbox
                                    }
                                    <Checkbox
                                        checked={selectedRows.map(r => r.id).includes(row.id)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedRows([...selectedRows, row]);
                                            } else {
                                                setSelectedRows(selectedRows.filter(r => r.id !== row.id));
                                            }
                                        }}
                                    />
                                </TableCell>
                            }
                            {columns.map((column, index) => (
                                <TableCell
                                    key={index}
                                    sx={{
                                        padding: "8px 16px",
                                        borderTop: "1px solid #e0e0e0",
                                        display: "flex",
                                        alignItems: "center",
                                        backgroundColor: hoveredRow?.id === row.id ? "#f0f0f0" : "transparent",
                                        minWidth: `${column.minWidth || 100}px`
                                    }}
                                    onClick={() => {
                                        if (column.onClick) {
                                            column.onClick(row, column);
                                        } else if (onRowClick) {
                                            onRowClick(row, column);
                                        }
                                    }}
                                >
                                    <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", display: "block" }}>
                                        {
                                            column.render ?
                                                column.render(row[column.id], row)
                                                :
                                                (
                                                    column.type === "boolean" ?
                                                        (row[column.id] === 1 ? "✔" : "✘")
                                                        :
                                                        column.type === "actions" ?
                                                            <IconButton
                                                                aria-controls={actionsMenuOpen ? 'menu' : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={actionsMenuOpen ? 'true' : undefined}
                                                                onClick={(e) => {
                                                                    rowToApplyActionsRef.current = row;
                                                                    handleActionsButtonClick(e);
                                                                }}>
                                                                <MoreVertIcon sx={{ scale: "1.2" }} />
                                                            </IconButton>
                                                            :
                                                            row[column.id]
                                                )
                                        }
                                    </span>
                                </TableCell>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            {onPaginationChange &&
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        gap: "16px",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingRight: "32px",
                        border: "1px solid #e0e0e0"
                    }}
                >

                    <FormattedMessage id="table.rowsPerPage" />
                    <Select
                        onChange={(e) => {
                            setPagination({
                                page: 0,
                                rowsPerPage: e.target.value
                            });
                            onPaginationChange({
                                page: 0,
                                rowsPerPage: e.target.value
                            });
                            updateAdmin(e.target.value);
                            setAdmin((prev) => {
                                return {
                                    ...prev,
                                    [table_id + "_row_count"]: e.target.value
                                }
                            });

                        }}
                        value={pagination.rowsPerPage}
                        variant='standard'
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={500}>500</MenuItem>


                    </Select>

                    <span
                        style={{
                            marginLeft: "16px",
                            marginRight: "16px"
                        }}
                    >

                        {(pagination.page + 1) * pagination.rowsPerPage - pagination.rowsPerPage + 1}-{pagination.rowsPerPage * (pagination.page + 1) > rowCount ? rowCount : pagination.rowsPerPage * (pagination.page + 1)} of {rowCount}
                    </span>

                    <button
                        onClick={() => {
                            setPagination({
                                ...pagination,
                                page: pagination.page - 1
                            });
                            onPaginationChange({
                                ...pagination,
                                page: pagination.page - 1
                            });
                        }}
                        disabled={pagination.page === 0}
                        style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            padding: "4px",
                            margin: 0,
                            marginRight: "16px",
                            backgroundColor: "transparent",
                            border: "none",
                            cursor: pagination.page === 0 ? "not-allowed" : "pointer"
                        }}
                    >
                        {"<"}
                    </button>
                    <button
                        onClick={() => {
                            setPagination({
                                ...pagination,
                                page: pagination.page + 1
                            });
                            onPaginationChange({
                                ...pagination,
                                page: pagination.page + 1
                            });
                        }}
                        disabled={(pagination.page + 1) * pagination.rowsPerPage >= rowCount}
                        style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            padding: "4px",
                            margin: 0,
                            backgroundColor: "transparent",
                            border: "none",
                            cursor: (pagination.page + 1) * pagination.rowsPerPage >= rowCount ? "not-allowed" : "pointer"
                        }}
                    >
                        {">"}
                    </button>
                </div>
            }
        </>
    )
}
import { Box, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../global/Header';
import { FormattedMessage, useIntl } from 'react-intl';
import { AssignmentInd, GroupAdd, Person, PersonRemove, PriceChange, ReceiptLong, RecordVoiceOver } from '@mui/icons-material';
import DashboardBox from './DashboardBox';
import axios from 'axios';
import { AdminContext } from '../../context/AdminContext';

function DashboardPage() {
    const { admin, token } = useContext(AdminContext);
    const { formatMessage } = useIntl();
    const [serviceProviderCategories, setServiceProviderCategories] = useState([]);
    const [advertisementCategories, setAdvertisementCategories] = useState([]);

    const fetchCategories = async (forAdvertisements, token, language) => {
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/getCategories`,
            {
                orderBy: "name",
                orderType: "ASC",
                filter: [{
                    id: "for_advertisements",
                    value: forAdvertisements ? 1 : 0,
                    type: "select",
                }],
                language: language
            },
            { headers: { token: token } }
        )
            .catch((err) => {
                if (forAdvertisements) {
                    setAdvertisementCategories([]);
                } else {
                    setServiceProviderCategories([]);
                }
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    if (forAdvertisements) {
                        setAdvertisementCategories(response.data.categories);
                    } else {
                        setServiceProviderCategories(response.data.categories);
                    }
                }
            });
    }

    useEffect(() => {
        if (token) {
            fetchCategories(false, token, admin.language);
            fetchCategories(true, token, admin.language);
        }
    }, [token, admin.language]);

    return (
        <Box sx={{ p: "75px" }}>
            <Box display="flex" alignItems="center" gap="20px">
                <Header
                    title={<FormattedMessage id={"dashboard.header.title"} />}
                    subtitle={""} />
            </Box>

            <Box>
                <Box>
                    <Typography variant="h3">
                        <FormattedMessage id={"dashboard.user_statistics"} />
                    </Typography>
                    <Box display="flex" gap="20px" alignItems="center" rowGap={"20px"} mt={"20px"} flexWrap={"wrap"}>
                        <DashboardBox
                            icon={<Person sx={{ fontSize: "40px" }} />}
                            title={<FormattedMessage id={"dashboard.users"} />}
                            endPoint={"getUserCount"}
                            filterable={true}
                            filterColumns={[
                                {
                                    id: "created_date",
                                    name: formatMessage({ id: "table.columns.created_date" }),
                                    type: 'date'
                                },
                                {
                                    id: 'gender',
                                    name: formatMessage({ id: "users.table.columns.gender" }),
                                    type: 'select',
                                    options: [
                                        { value: 0, label: <FormattedMessage id={"users.gender.option.male"} /> },
                                        { value: 1, label: <FormattedMessage id={"users.gender.option.female"} /> },
                                        { value: 2, label: <FormattedMessage id={"users.gender.option.secret"} /> }
                                    ]
                                }, {
                                    id: "birth_date",
                                    name: formatMessage({ id: "users.table.columns.birth_date" }),
                                    type: 'date'
                                }, {
                                    id: 'country',
                                    name: formatMessage({ id: "users.table.columns.country" }),
                                    type: 'text',
                                    filterable: true
                                }, {
                                    id: 'city',
                                    name: formatMessage({ id: "users.table.columns.city" }),
                                    type: 'text'
                                }, {
                                    id: 'county',
                                    name: formatMessage({ id: "users.table.columns.county" }),
                                    type: 'text'
                                }
                            ]}
                        />
                        <DashboardBox
                            icon={<AssignmentInd sx={{ fontSize: "40px" }} />}
                            title={<FormattedMessage id={"dashboard.service_providers"} />}
                            endPoint={"getUserCount"}
                            filterable={true}
                            defaultFilters={[
                                {
                                    id: 'is_premium',
                                    type: 'boolean',
                                    value: true
                                }
                            ]}
                            filterColumns={[
                                {
                                    id: "created_date",
                                    name: formatMessage({ id: "table.columns.created_date" }),
                                    type: 'date'
                                },
                                {
                                    id: 'gender',
                                    name: formatMessage({ id: "users.table.columns.gender" }),
                                    type: 'select',
                                    options: [
                                        { value: 0, label: <FormattedMessage id={"users.gender.option.male"} /> },
                                        { value: 1, label: <FormattedMessage id={"users.gender.option.female"} /> },
                                        { value: 2, label: <FormattedMessage id={"users.gender.option.secret"} /> }
                                    ]
                                }, {
                                    id: "birth_date",
                                    name: formatMessage({ id: "users.table.columns.birth_date" }),
                                    type: 'date'
                                }, {
                                    id: 'country',
                                    name: formatMessage({ id: "users.table.columns.country" }),
                                    type: 'text',
                                    filterable: true
                                }, {
                                    id: 'city',
                                    name: formatMessage({ id: "users.table.columns.city" }),
                                    type: 'text'
                                }, {
                                    id: 'county',
                                    name: formatMessage({ id: "users.table.columns.county" }),
                                    type: 'text'
                                }, {
                                    id: 'categoryId',
                                    name: formatMessage({ id: "categories" }).toUpperCase(),
                                    type: 'select',
                                    options: serviceProviderCategories.map((category) => ({ value: category.id, label: category.name })),
                                }
                            ]}
                        />
                        <DashboardBox
                            icon={<RecordVoiceOver sx={{ fontSize: "40px" }} />}
                            title={<FormattedMessage id={"dashboard.online_users"} />}
                            endPoint={"getUserCount"}
                            filterable={true}
                            defaultFilters={[
                                {
                                    id: 'is_online',
                                    type: 'boolean',
                                    value: true
                                }
                            ]}
                            filterColumns={[
                                {
                                    id: "created_date",
                                    name: formatMessage({ id: "table.columns.created_date" }),
                                    type: 'date'
                                },
                                {
                                    id: 'gender',
                                    name: formatMessage({ id: "users.table.columns.gender" }),
                                    type: 'select',
                                    options: [
                                        { value: 0, label: <FormattedMessage id={"users.gender.option.male"} /> },
                                        { value: 1, label: <FormattedMessage id={"users.gender.option.female"} /> },
                                        { value: 2, label: <FormattedMessage id={"users.gender.option.secret"} /> }
                                    ]
                                }, {
                                    id: "birth_date",
                                    name: formatMessage({ id: "users.table.columns.birth_date" }),
                                    type: 'date'
                                }, {
                                    id: 'country',
                                    name: formatMessage({ id: "users.table.columns.country" }),
                                    type: 'text',
                                    filterable: true
                                }, {
                                    id: 'city',
                                    name: formatMessage({ id: "users.table.columns.city" }),
                                    type: 'text'
                                }, {
                                    id: 'county',
                                    name: formatMessage({ id: "users.table.columns.county" }),
                                    type: 'text'
                                }
                            ]}
                        />
                        <DashboardBox
                            icon={<PersonRemove sx={{ fontSize: "40px" }} />}
                            title={<FormattedMessage id={"dashboard.deleted_users"} />}
                            endPoint={"getUserDeletionCount"}
                            filterable={true}
                            filterColumns={[
                                {
                                    id: "created_date",
                                    name: formatMessage({ id: "deletion_date" }),
                                    type: 'date'
                                }
                            ]}
                        />
                    </Box>

                    <Typography variant="h3" mt="30px">
                        <FormattedMessage id={"dashboard.advertisement_statistics"} />
                    </Typography>
                    <Box display="flex" gap="20px" alignItems="center" rowGap={"20px"} mt={"20px"} flexWrap={"wrap"}>
                        <DashboardBox
                            icon={<ReceiptLong sx={{ fontSize: "40px" }} />}
                            title={<FormattedMessage id={"active_advertisements"} />}
                            endPoint={"getAdvertisementCount"}
                            filterable={true}
                            filterColumns={[
                                {
                                    id: 'country',
                                    name: formatMessage({ id: "users.table.columns.country" }),
                                    type: 'text',
                                    filterable: true
                                }, {
                                    id: 'city',
                                    name: formatMessage({ id: "users.table.columns.city" }),
                                    type: 'text'
                                }, {
                                    id: 'county',
                                    name: formatMessage({ id: "users.table.columns.county" }),
                                    type: 'text'
                                }, {
                                    id: "category_ids",
                                    type: "multiple-select",
                                    name: formatMessage({ id: "categories" }).toUpperCase(),
                                    options: advertisementCategories.map((category) => ({ value: category.id, label: category.name }))
                                }
                            ]}
                            defaultFilters={[
                                {
                                    id: "only_actives",
                                    type: "boolean",
                                    value: true
                                }
                            ]}
                        />
                        <DashboardBox
                            icon={<ReceiptLong sx={{ fontSize: "40px" }} />}
                            title={<FormattedMessage id={"featured_advertisements"} />}
                            endPoint={"getAdvertisementCount"}
                            filterable={true}
                            filterColumns={[
                                {
                                    id: 'country',
                                    name: formatMessage({ id: "users.table.columns.country" }),
                                    type: 'text',
                                    filterable: true
                                }, {
                                    id: 'city',
                                    name: formatMessage({ id: "users.table.columns.city" }),
                                    type: 'text'
                                }, {
                                    id: 'county',
                                    name: formatMessage({ id: "users.table.columns.county" }),
                                    type: 'text'
                                }, {
                                    id: "category_ids",
                                    type: "multiple-select",
                                    name: formatMessage({ id: "categories" }).toUpperCase(),
                                    options: advertisementCategories.map((category) => ({ value: category.id, label: category.name }))
                                }
                            ]}
                            defaultFilters={[
                                {
                                    id: "only_featureds",
                                    type: "boolean",
                                    value: true
                                }
                            ]}
                        />
                    </Box>
                    <Typography variant="h3" mt="30px">
                        <FormattedMessage id={"dashboard.offer_demand_statistics"} />
                    </Typography>
                    <Box display="flex" gap="20px" alignItems="center" rowGap={"20px"} mt={"20px"} flexWrap={"wrap"}>
                        <DashboardBox
                            icon={<PriceChange sx={{ fontSize: "40px" }} />}
                            title={<FormattedMessage id={"dashboard.offers"} />}
                            endPoint={"getOfferCount"}
                            filterable={true}
                            filterColumns={[
                                {
                                    id: "status",
                                    type: "select",
                                    name: formatMessage({ id: "status" }).toUpperCase(),
                                    options: [
                                        { value: 0, label: formatMessage({ id: "waiting" }) },
                                        { value: 1, label: formatMessage({ id: "accepted" }) },
                                        { value: 2, label: formatMessage({ id: "rejected" }) },
                                        { value: 3, label: formatMessage({ id: "cancelled" }) },
                                        { value: 4, label: formatMessage({ id: "rated" }) }
                                    ]
                                }
                            ]}
                            defaultFilters={[
                                {
                                    id: "type",
                                    type: "text",
                                    value: "offer"
                                }
                            ]}
                        />
                        <DashboardBox
                            icon={<PriceChange sx={{ fontSize: "40px" }} />}
                            title={<FormattedMessage id={"dashboard.demands"} />}
                            endPoint={"getOfferCount"}
                            filterable={true}
                            filterColumns={[
                                {
                                    id: "status",
                                    type: "select",
                                    name: formatMessage({ id: "status" }).toUpperCase(),
                                    options: [
                                        { value: 0, label: formatMessage({ id: "waiting" }) },
                                        { value: 2, label: formatMessage({ id: "rejected" }) }
                                    ]
                                }
                            ]}
                            defaultFilters={[
                                {
                                    id: "type",
                                    type: "text",
                                    value: "demand"
                                }
                            ]}
                        />
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}

export default DashboardPage
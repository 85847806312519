import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

function FaqDialog({ faq, dialogOpen, handleClose, handleFormSubmit }) {

    var initialValues = {
        title: faq?.title ? faq?.title : "",
        answer: faq?.answer ? faq?.answer : "",
        translations: faq?.translations && faq?.translations.length > 0 ? faq?.translations : [
            { language: "de", title_translation: "", answer_translation: "" }
        ]
    };

    var validationObject = {
        title: yup.string().required(<FormattedMessage id="required" />),
        answer: yup.string().required(<FormattedMessage id="required" />),
        translations: yup.array().of(yup.object().shape({
            language: yup.string().required(<FormattedMessage id="required" />),
            title_translation: yup.string().required(<FormattedMessage id="required" />),
            answer_translation: yup.string().required(<FormattedMessage id="required" />),
        }).required(<FormattedMessage id="required" />)).required(<FormattedMessage id="required" />)
    }

    const faqSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            fullWidth
            open={dialogOpen}
            onClose={(e, reason) => {
                if (reason === 'backdropClick') {
                    return;
                }
                handleClose();
            }}>
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    variant="standard"
                    color="error"
                    sx={{
                        borderRadius: "100%",
                        backgroundColor: "white",
                        maxWidth: "40px",
                        maxHeight: "40px",
                        minWidth: "40px",
                        minHeight: "40px",
                        position: "absolute",
                        top: "10px",
                        right: "10px"
                    }}
                    onClick={handleClose}>
                    <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                </Button>
            </Box>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    {faq ?
                        <FormattedMessage id="edit_faq" />
                        :
                        <FormattedMessage id="add_new_faq" />
                    }
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={faqSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(1, minmax(0, 1fr))" mx="75px" my="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={<FormattedMessage id="question_title_with_language_code" values={{ language: "en" }} />}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"title"}
                                        value={values.title}
                                        error={!!touched.title && !!errors.title}
                                        helperText={touched.title && errors.title}
                                        sx={{ gridColumn: "span 1" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={<FormattedMessage id="answer_with_language_code" values={{ language: "en" }} />}
                                        multiline
                                        minRows={3}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"answer"}
                                        value={values.answer}
                                        error={!!touched.answer && !!errors.answer}
                                        helperText={touched.answer && errors.answer}
                                        sx={{ gridColumn: "span 1" }}
                                    />
                                    {values.translations.map((translation, i) =>
                                        <Box key={`translation-${i}`} mt={"15px"} >
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label={<FormattedMessage id="question_title_with_language_code" values={{ language: translation.language }} />}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"translations[" + i + "].title_translation"}
                                                value={values.translations[i].title_translation}
                                                error={!!touched?.translations && !!touched?.translations[i] && !!touched?.translations[i]?.title_translation && !!errors?.translations && !!errors?.translations[i] && !!errors?.translations[i]?.title_translation}
                                                helperText={touched?.translations && touched?.translations[i] && touched?.translations[i]?.title_translation && errors?.translations && errors?.translations[i] && errors?.translations[i]?.title_translation}
                                                sx={{ gridColumn: "span 1" }}
                                            />
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label={<FormattedMessage id="answer_with_language_code" values={{ language: translation.language }} />}
                                                multiline
                                                minRows={3}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"translations[" + i + "].answer_translation"}
                                                value={values.translations[i].answer_translation}
                                                error={!!touched?.translations && !!touched?.translations[i] && !!touched?.translations[i]?.answer_translation && !!errors?.translations && !!errors?.translations[i] && !!errors?.translations[i]?.answer_translation}
                                                helperText={touched?.translations && touched?.translations[i] && touched?.translations[i]?.answer_translation && errors?.translations && errors?.translations[i] && errors?.translations[i]?.answer_translation}
                                                sx={{ gridColumn: "span 1", mt: "15px" }}
                                            />
                                        </Box>
                                    )}
                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px" gap={"10px"}>
                                    <Button type="button" color="warning" variant="contained" onClick={() => {
                                        resetForm();
                                    }}>
                                        <FormattedMessage id="reset" />
                                    </Button>
                                    <Button type="button" color="error" variant="contained" onClick={handleClose}>
                                        <FormattedMessage id="cancel" />

                                    </Button>
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <FormattedMessage id="save" />

                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default FaqDialog
import { Box, Button, Checkbox, Dialog, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from "yup";
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { AdminContext } from '../../../context/AdminContext';

function MakePremiumDialog({ makePremiumDialogState, setMakePremiumDialogState, handleSubmit, user }) {

    const { token, admin } = useContext(AdminContext);

    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const initialValues = {
        bio: user.bio ? user.bio : "",
        subcategories:
            user.categories?.length > 0
                ?
                user.categories.map((category) => {
                    return category.subcategories?.length > 0 ? category.subcategories.map((subcategory) => { return { category_id: category.id, id: subcategory.id, name: subcategory.name } }) : []
                }).reduce((accumulator, currentValue) => accumulator.concat(currentValue)) : []

    };

    const validationObject = {
        bio: yup.string().required(<FormattedMessage id="required" />),
        subcategories: yup.array().of(yup.object().shape(
            {
                category_id: yup.number().required(<FormattedMessage id="required" />),
                id: yup.number().required(<FormattedMessage id="required" />)
            }
        ).required(<FormattedMessage id="required" />)).required(<FormattedMessage id="required" />)
    }

    useEffect(() => {
        let tempSelectedCategories = user.categories?.length > 0 ? user.categories.map((category) => category) : [
            { id: "", name: "" },
            { id: "", name: "" },
            { id: "", name: "" }
        ];
        for (let i = 3; i > 0; i--) {
            if (!tempSelectedCategories[i - 1]) {
                tempSelectedCategories[i - 1] = { id: "", name: "" };
            }
        }
        setSelectedCategories(tempSelectedCategories);
    }, [user]);

    useEffect(() => {
        if (makePremiumDialogState.open) {
            fetchCategories(admin, token);
            fetchSubcategories(admin, token);
        }
    }, [makePremiumDialogState.open, token, admin]);

    const fetchCategories = async (admin, token) => {
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/getCategories`,
            {
                orderBy: "position",
                orderType: "ASC",
                filter: [
                    {
                        id: "for_advertisements",
                        value: 0,
                        type: "boolean"
                    }
                ],
                language: admin.language
            },
            { headers: { token: token } }).catch((err) => {
                console.log("err: " + err);
            }).then((categoriesResponse) => {
                if (categoriesResponse && categoriesResponse.data.success === true && categoriesResponse.data.status === 200) {
                    setCategories(categoriesResponse.data.categories);
                }
            });
    }

    const fetchSubcategories = async (admin, token) => {
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/getSubcategories`,
            {
                orderBy: "position",
                orderType: "ASC",
                language: admin.language
            },
            { headers: { token: token } }).catch((err) => {
                console.log("err: " + err);
            }).then((subcategoriesResponse) => {
                if (subcategoriesResponse && subcategoriesResponse.data.success === true && subcategoriesResponse.data.status === 200) {
                    setSubcategories(subcategoriesResponse.data.subcategories);
                }
            });
    }
    return (
        <Dialog
            fullWidth
            open={makePremiumDialogState.open}
            onClose={(event, reason) => {
                if (reason && reason === "backdropClick") {
                    return;
                }
                setMakePremiumDialogState({ open: false, duration: "" });
            }}
        >
            <Box p="20px">
                <Typography variant="h4">
                    <FormattedMessage id={`make_user_service_provider_${makePremiumDialogState.duration}`} />


                    <Button
                        variant="standard"
                        color="error"
                        sx={{
                            borderRadius: "100%",
                            backgroundColor: "white",
                            maxWidth: "40px",
                            maxHeight: "40px",
                            minWidth: "40px",
                            minHeight: "40px",
                            position: "absolute",
                            top: "10px",
                            right: "10px"
                        }}
                        onClick={() => setMakePremiumDialogState({ open: false, duration: "" })}>
                        <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                    </Button>
                </Typography>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        handleSubmit({ bio: values.bio, subcategoryIds: values.subcategories.map((subcategory) => subcategory.id) });
                    }}
                    validationSchema={yup.object().shape(validationObject)}
                >
                    {({ values, handleChange, handleSubmit, handleBlur, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <Box display="flex" flexDirection="column" gap="10px" mt={"20px"}>
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label={<FormattedMessage id="bio" />}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name={"bio"}
                                    value={values.bio}
                                    multiline
                                    rows={4}
                                />
                                {categories.length > 0 && subcategories?.length > 0 && selectedCategories.map((selectedCategory, i) => (
                                    <Box key={i + "" + selectedCategory.id} display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0,1fr))">
                                        <FormControl
                                            fullWidth
                                            variant="filled"
                                            sx={{ gridColumn: "span 1" }}>
                                            <InputLabel id="filter-label1">
                                                <FormattedMessage id="category" />
                                            </InputLabel>
                                            <Select
                                                labelId="filter-label1"
                                                label={<FormattedMessage id="type" />}
                                                onChange={(e) => {
                                                    if (selectedCategories.findIndex((category) => category.id === e.target.value) > -1) {
                                                        return;
                                                    }
                                                    setSelectedCategories(selectedCategories.map((category, index) => {
                                                        if (index === i) {
                                                            const newSubcategories = values.subcategories.filter((subcategory) => subcategory.category_id !== selectedCategory.id);
                                                            setFieldValue("subcategories", newSubcategories);
                                                            return categories.filter((category) => category.id === e.target.value)[0];
                                                        }
                                                        return category;
                                                    }));
                                                }}
                                                value={selectedCategory.id}
                                            >
                                                {categories.map((category, i) => (
                                                    <MenuItem value={category.id} key={i}>
                                                        {category.name}
                                                    </MenuItem>
                                                ))
                                                }
                                            </Select>
                                        </FormControl>
                                        {selectedCategory.id !== -1 ?
                                            //multiple select
                                            <FormControl
                                                fullWidth
                                                variant="filled"
                                                sx={{ gridColumn: "span 1" }}>
                                                <InputLabel id="filter-label1">
                                                    <FormattedMessage id="subcategory" />
                                                </InputLabel>
                                                <Select
                                                    labelId="filter-label1"
                                                    label={<FormattedMessage id="type" />}
                                                    onChange={(e) => {
                                                        if (values.subcategories.findIndex((subcategory) => subcategory.id === e.target.value[e.target.value.length - 1].id) > -1) {
                                                            let newSubcategories = values.subcategories.filter((subcategory) => subcategory.id !== e.target.value[e.target.value.length - 1].id);
                                                            setFieldValue("subcategories", newSubcategories);
                                                        } else {
                                                            let newSubcategories = [
                                                                ...(values.subcategories),
                                                                {
                                                                    category_id: selectedCategory.id,
                                                                    name: e.target.value[e.target.value.length - 1].name,
                                                                    id: e.target.value[e.target.value.length - 1].id
                                                                }];
                                                            setFieldValue("subcategories", newSubcategories);
                                                        }
                                                    }}
                                                    value={values.subcategories.filter((subcategory) => subcategory.category_id === selectedCategory.id)}
                                                    multiple
                                                    renderValue={(selected) => {
                                                        return selected.map((subcategory) => subcategory.name).join(", ")
                                                    }}
                                                >
                                                    {subcategories.filter((subcategory) => subcategory.category_id === selectedCategory.id).map((subcategory, i) => (
                                                        //with checkbox
                                                        <MenuItem value={subcategory} key={i}>
                                                            <Checkbox
                                                                checked={values.subcategories.findIndex((subct) => subct.id === subcategory.id) > -1} />
                                                            {subcategory.name}
                                                        </MenuItem>
                                                    ))
                                                    }
                                                </Select>
                                            </FormControl>
                                            :
                                            ""
                                        }
                                    </Box>
                                ))
                                }
                                <Box display="flex" justifyContent="end" m="10px 0px 0px 20px" gap={"10px"}>
                                    <Button
                                        type="submit"
                                        color="success"
                                        variant="contained">
                                        <FormattedMessage id="save" />
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Dialog>

    )
}

export default MakePremiumDialog
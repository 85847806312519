import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

function ChangeSidebarPositionsDialog({ sidebarItemPositions, dialogOpen, handleClose, handleFormSubmit }) {

    const { formatMessage } = useIntl();

    var initialValues = {
        dashboard_order: sidebarItemPositions?.dashboard_order || 1,
        users_order: sidebarItemPositions?.users_order || 2,
        categories_order: sidebarItemPositions?.categories_order || 3,
        advertisements_order: sidebarItemPositions?.advertisements_order || 4,
        onboardings_order: sidebarItemPositions?.onboardings_order || 5,
        notifications_order: sidebarItemPositions?.notifications_order || 6,
        reports_order: sidebarItemPositions?.reports_order || 7,
        contact_messages_order: sidebarItemPositions?.contact_messages_order || 8,
        forbidden_words_order: sidebarItemPositions?.forbidden_words_order || 9,
        commercials_order: sidebarItemPositions?.commercials_order || 10,
        faqs_order: sidebarItemPositions?.faqs_order || 11,
        admins_order: sidebarItemPositions?.admins_order || 12
    };

    var validationObject = {
        dashboard_order: yup.number().required(formatMessage({ id: "required" })).min(1, formatMessage({ id: "min_value" }, { value: 1 })),
        users_order: yup.number().required(formatMessage({ id: "required" })).min(1, formatMessage({ id: "min_value" }, { value: 1 })),
        categories_order: yup.number().required(formatMessage({ id: "required" })).min(1, formatMessage({ id: "min_value" }, { value: 1 })),
        advertisements_order: yup.number().required(formatMessage({ id: "required" })).min(1, formatMessage({ id: "min_value" }, { value: 1 })),
        onboardings_order: yup.number().required(formatMessage({ id: "required" })).min(1, formatMessage({ id: "min_value" }, { value: 1 })),
        notifications_order: yup.number().required(formatMessage({ id: "required" })).min(1, formatMessage({ id: "min_value" }, { value: 1 })),
        reports_order: yup.number().required(formatMessage({ id: "required" })).min(1, formatMessage({ id: "min_value" }, { value: 1 })),
        contact_messages_order: yup.number().required(formatMessage({ id: "required" })).min(1, formatMessage({ id: "min_value" }, { value: 1 })),
        forbidden_words_order: yup.number().required(formatMessage({ id: "required" })).min(1, formatMessage({ id: "min_value" }, { value: 1 })),
        commercials_order: yup.number().required(formatMessage({ id: "required" })).min(1, formatMessage({ id: "min_value" }, { value: 1 })),
        faqs_order: yup.number().required(formatMessage({ id: "required" })).min(1, formatMessage({ id: "min_value" }, { value: 1 })),
        admins_order: yup.number().required(formatMessage({ id: "required" })).min(1, formatMessage({ id: "min_value" }, { value: 1 }))
    }

    const settingsSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            open={dialogOpen}
            fullWidth
            maxWidth="sm"
            onClose={(e, reason) => {
                if (reason && reason === "backdropClick") {
                    return;
                }
                handleClose();
            }}>
            <Box m="20px" textAlign="center">
                <Typography variant='h3'>
                    <FormattedMessage id={"edit_sidebar_item_order"} />
                </Typography>

                <Button
                    variant="standard"
                    color="error"
                    sx={{
                        borderRadius: "100%",
                        backgroundColor: "white",
                        maxWidth: "40px",
                        maxHeight: "40px",
                        minWidth: "40px",
                        minHeight: "40px",
                        position: "absolute",
                        top: "10px",
                        right: "10px"
                    }}
                    onClick={handleClose}>
                    <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                </Button>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={settingsSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="20px" gridTemplateColumns="repeat(1, minmax(0, 1fr))" mx="75px" my="25px">

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "dashboard.header.title" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="dashboard_order"
                                        value={values.dashboard_order}
                                        error={!!touched.dashboard_order && !!errors.dashboard_order}
                                        helperText={touched.dashboard_order && errors.dashboard_order}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "users.header.title" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="users_order"
                                        value={values.users_order}
                                        error={!!touched.users_order && !!errors.users_order}
                                        helperText={touched.users_order && errors.users_order}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "categories.header.title" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="categories_order"
                                        value={values.categories_order}
                                        error={!!touched.categories_order && !!errors.categories_order}
                                        helperText={touched.categories_order && errors.categories_order}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "advertisements.header.title" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="advertisements_order"
                                        value={values.advertisements_order}
                                        error={!!touched.advertisements_order && !!errors.advertisements_order}
                                        helperText={touched.advertisements_order && errors.advertisements_order}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "onboarding.header.title" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="onboardings_order"
                                        value={values.onboardings_order}
                                        error={!!touched.onboardings_order && !!errors.onboardings_order}
                                        helperText={touched.onboardings_order && errors.onboardings_order}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "notifications.header.title" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="notifications_order"
                                        value={values.notifications_order}
                                        error={!!touched.notifications_order && !!errors.notifications_order}
                                        helperText={touched.notifications_order && errors.notifications_order}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "reports.header.title" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="reports_order"
                                        value={values.reports_order}
                                        error={!!touched.reports_order && !!errors.reports_order}
                                        helperText={touched.reports_order && errors.reports_order}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "contact_messages.header.title" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="contact_messages_order"
                                        value={values.contact_messages_order}
                                        error={!!touched.contact_messages_order && !!errors.contact_messages_order}
                                        helperText={touched.contact_messages_order && errors.contact_messages_order}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "forbidden_words.header.title" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="forbidden_words_order"
                                        value={values.forbidden_words_order}
                                        error={!!touched.forbidden_words_order && !!errors.forbidden_words_order}
                                        helperText={touched.forbidden_words_order && errors.forbidden_words_order}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "commercials.header.title" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="commercials_order"
                                        value={values.commercials_order}
                                        error={!!touched.commercials_order && !!errors.commercials_order}
                                        helperText={touched.commercials_order && errors.commercials_order}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "faqs.header.title" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="faqs_order"
                                        value={values.faqs_order}
                                        error={!!touched.faqs_order && !!errors.faqs_order}
                                        helperText={touched.faqs_order && errors.faqs_order}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "admins.header.title" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="admins_order"
                                        value={values.admins_order}
                                        error={!!touched.admins_order && !!errors.admins_order}
                                        helperText={touched.admins_order && errors.admins_order}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px" gap={"10px"}>
                                    <Button type="button" color="warning" variant="contained" onClick={() => {
                                        resetForm();
                                    }}>
                                        <FormattedMessage id="reset" />
                                    </Button>
                                    <Button type="button" color="error" variant="contained" onClick={handleClose}>
                                        <FormattedMessage id="cancel" />

                                    </Button>
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <FormattedMessage id="save" />
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default ChangeSidebarPositionsDialog
import { Box, Button, IconButton, Snackbar } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../global/Header';
import { AdminContext } from '../../../context/AdminContext';
import AlertDialogSlide from '../../../components/AlertDialogSlide';
import SubcategoryDialog from './SubcategoryDialog';
import { Table } from '../../../components/Table';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

function SubcategoriesPage() {
    const { categoryId } = useParams();
    const { admin, token } = useContext(AdminContext);
    const [subcategories, setSubcategories] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [maxPosition, setMaxPosition] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [orderModel, setOrderModel] = useState(null);
    const [filterModel, setFilterModel] = useState(null);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        rowsPerPage: admin.subcategories_table_row_count
    });
    const [subcategoryEvent, setSubcategoryEvent] = useState({ subcategory: null, type: null });
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [snackbarState, setSnackbarState] = useState({ open: false, message: "" });
    const handleSnackbarOpen = (message) => {
        setSnackbarState({ open: true, message: message });
    };

    const handleSnackbarClose = () => {
        setSnackbarState({ open: false, message: "" });
    };

    const columns = [
        {
            id: 'position',
            name: <FormattedMessage id={"table.columns.position"} />,
            type: 'number',
            sortable: true, filterable: true
        }, {
            id: 'name',
            name: <FormattedMessage id={"table.columns.name"} />,
            type: 'text',
            sortable: true, filterable: true
        }, {
            id: "service_provider_count",
            name: <FormattedMessage id={"categories.table.columns.service_provider_count"} />,
            type: 'number',
            sortable: true, filterable: true
        }, ...(admin.category_edit_perm === 1 || admin.category_deletion_perm === 1 ? [{
            id: 'actions',
            name: <FormattedMessage id={"table.columns.actions"} />,
            type: 'actions',
            sortable: false, filterable: false,
            getActions: () => [
                ...(admin.category_edit_perm === 1 ? [{
                    label: <FormattedMessage id={"move_to_up"} />,
                    action: (row) => {
                        changePositions(row, row.position, row.position - 1);
                    }
                },
                {
                    label: <FormattedMessage id={"move_to_down"} />,
                    action: (row) => {
                        changePositions(row, row.position, row.position + 1);
                    }
                },
                {
                    label: <FormattedMessage id={"edit"} />,
                    action: (row) => {
                        fetchSubcategoryToUpdate(row);
                    }
                }] : []),
                ...(admin.category_deletion_perm === 1 ? [{
                    label: <FormattedMessage id={"delete"} />,
                    action: (row) => {
                        setSubcategoryEvent({ subcategory: row, type: "delete" })
                    }
                }] : [])
            ]
        }] : [])
    ];

    const fetchSubcategories = (paginationModel, orderModel, filterModel, token, categoryId) => {
        setLoading(true);
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/getSubcategories?categoryId=${categoryId}`,
            {
                startIndex: paginationModel.page * paginationModel.rowsPerPage,
                limit: paginationModel.rowsPerPage,
                orderBy: orderModel ? orderModel.orderBy : null,
                orderType: orderModel ? orderModel.orderType : null,
                filter: filterModel ? filterModel : null
            },
            { headers: { token: token } }
        )
            .catch((err) => {
                setSubcategories([]);
                setLoading(false);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setSubcategories(response.data.subcategories);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                    setMaxPosition(response.data.maxPosition !== undefined ? response.data.maxPosition : 0);
                }
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchSubcategories(paginationModel, orderModel, filterModel, token, categoryId);
    }, [paginationModel, orderModel, filterModel, token, categoryId]);

    useEffect(() => {
        if ((subcategoryEvent?.subcategory && subcategoryEvent?.type === "edit") || (!subcategoryEvent?.subcategory && subcategoryEvent?.type === "add")) {
            setEditDialogOpen(true);
        }
        else if (subcategoryEvent?.subcategory && subcategoryEvent?.type === "delete") {
            setRemoveDialogOpen(true);
        }
    }, [subcategoryEvent]);

    const fetchSubcategoryToUpdate = (subcategory) => {
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/getCategories`,
            {
                orderBy: "position",
                orderType: "ASC",
                filter: [
                    {
                        id: "for_advertisements",
                        value: 0,
                        type: "boolean"
                    }
                ]
            },
            { headers: { token: token } }).catch((err) => {
                console.log("err: " + err);
            }).then((categoriesResponse) => {
                if (categoriesResponse && categoriesResponse.data.success === true && categoriesResponse.data.status === 200) {
                    axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getSubcategoryToUpdate?subcategoryId=${subcategory.id}`, { headers: { token: token } })
                        .catch((err) => {
                            console.log("err: " + err);
                        })
                        .then((response) => {
                            if (response && response.data.success === true && response.data.status === 200) {
                                setSubcategoryEvent({
                                    subcategory: response.data.subcategory,
                                    categories: categoriesResponse.data.categories,
                                    type: "edit"
                                });
                            }
                        });
                }
            });
    }

    const addSubcategory = async (subcategory) => {

        await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/createSubcategoryWithTranslations`, {
            ...subcategory,
            category_id: categoryId
        }, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setSubcategoryEvent({ subcategory: null, type: null });
                    setEditDialogOpen(false);
                    fetchSubcategories(paginationModel, orderModel, filterModel, token, categoryId);
                    handleSnackbarOpen(<FormattedMessage id={"subcategory_added_successfully"} />);
                }
            });
    }

    const updateSubcategory = async (subcategory) => {
        var subcategoryIx = subcategories.findIndex(sc => sc.id === subcategoryEvent.subcategory.id);


        var subcategoryToUpdate = { ...subcategory, position: subcategories[subcategoryIx].position };
        delete subcategoryToUpdate.service_provider_count;

        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateSubcategoryWithTranslations?subcategoryId=${subcategoryEvent.subcategory.id}&categoryIdChanged=${subcategories[subcategoryIx].category_id !== subcategory.category_id}&oldCategoryId=${subcategories[subcategoryIx].category_id}`, {
            subcategory: subcategoryToUpdate
        }, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchSubcategories(paginationModel, orderModel, filterModel, token, categoryId);
                    setSubcategoryEvent({ subcategory: null, type: null });
                    setEditDialogOpen(false);
                    handleSnackbarOpen(<FormattedMessage id={"subcategory_updated_successfully"} />);
                }
            });
    }

    const changePositions = (subcategory, currentPosition, newPosition) => {
        if (currentPosition === 1 && newPosition === 0) {
            handleSnackbarOpen(<FormattedMessage id={"already_top"} />);
            return;
        }
        if (currentPosition === maxPosition && newPosition === maxPosition + 1) {
            handleSnackbarOpen(<FormattedMessage id={"already_bottom"} />);
            return;
        }
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/changeSubcategoryPosition?subcategoryId=${subcategory.id}&currentPosition=${currentPosition}&newPosition=${newPosition}`, {}, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleSnackbarOpen(<FormattedMessage id={"position_changed_successfully"} />);
                    fetchSubcategories(paginationModel, orderModel, filterModel, token, categoryId);
                }
            });
    }

    const handleSubcategoryFormSubmit = async (subcategory) => {
        if (subcategoryEvent.type === "add") {
            addSubcategory(subcategory);
        } else if (subcategoryEvent.type === "edit") {
            updateSubcategory(subcategory);
        }
    }

    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const handleRemoveDialogClose = () => {
        setRemoveDialogOpen(false);
    }
    const clickPositiveAnswer = () => {
        removeSubcategory(subcategoryEvent.subcategory);
    }
    const clickNegativeAnswer = () => {
    }

    const removeSubcategory = (subcategory) => {
        axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteSubcategory?subcategoryId=${subcategory.id}`, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
                setSubcategoryEvent({ subcategory: null, type: null })
                setRemoveDialogOpen(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = subcategories.findIndex(({ id }) => id === subcategory.id);
                    var newSubcategories = Array.from(subcategories);
                    if (ix >= 0) {
                        newSubcategories.splice(ix, 1);
                        if (subcategories[ix].position !== maxPosition) {
                            for (var i = ix; i < newSubcategories.length; i++) {
                                newSubcategories[i].position -= 1;
                            }
                        }
                        setSubcategories(newSubcategories);
                    }
                    setSubcategoryEvent({ subcategory: null, type: null });
                    setRemoveDialogOpen(false);
                    handleSnackbarOpen(<FormattedMessage id={"subcategory_deleted_successfully"} />);
                }
            });
    }

    return (
        <Box sx={{ p: "75px" }}>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarState.open}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                message={snackbarState.message}
            />
            {
                subcategoryEvent.subcategory && subcategoryEvent.type === "delete" &&
                <AlertDialogSlide
                    open={removeDialogOpen}
                    handleClose={(event, reason) => {
                        if (reason && reason === "backdropClick") {
                            return;
                        }
                        handleRemoveDialogClose();
                        setSubcategoryEvent({ subcategory: null, type: null })
                    }}
                    clickPositiveAnswer={clickPositiveAnswer}
                    clickNegativeAnswer={clickNegativeAnswer}
                    positiveAnswer={<FormattedMessage id={"delete"} />}
                    negativeAnswer={<FormattedMessage id={"cancel"} />}
                    title={<FormattedMessage id={"are_you_sure_to_delete_subcategory"} />}
                    description={<FormattedMessage id={"delete_subcategory_description"} />}
                />
            }
            {
                ((subcategoryEvent.subcategory && subcategoryEvent.type === "edit") || (!subcategoryEvent.subcategory && subcategoryEvent.type === "add")) &&
                <SubcategoryDialog
                    dialogOpen={editDialogOpen}
                    handleClose={(event, reason) => {
                        if (reason && reason === "backdropClick") {
                            return;
                        }
                        setEditDialogOpen(false);
                        setSubcategoryEvent({ subcategory: null, type: null });
                    }}
                    subcategory={subcategoryEvent.subcategory}
                    categories={subcategoryEvent.categories}
                    handleFormSubmit={handleSubcategoryFormSubmit}
                />
            }
            <Box display="flex" alignItems="center" gap="30px">
                <Box display="flex" gap="10px">
                    <IconButton style={{ width: "40px", height: "40px", marginRight: "5px" }}
                        onClick={() => {
                            window.history.back();
                        }}>
                        <ArrowBackIosNewOutlinedIcon />
                    </IconButton>
                    <Header
                        title={<FormattedMessage id="subcategories.header.title" />}
                        subtitle={<FormattedMessage id="subcategories.header.description" />}
                    />
                </Box>
                {admin.category_edit_perm === 1 &&
                    <Button variant="contained" color="success" onClick={() => setSubcategoryEvent({ subcategory: null, type: "add" })}>
                        <FormattedMessage id="add_new_subcategory" />
                    </Button>
                }
            </Box>
            <Table
                table_id={"subcategories_table"}
                isLoading={isLoading}
                columns={columns}
                rows={subcategories}
                rowCount={rowCount}
                onOrderChange={(order) => {
                    setOrderModel(order);
                }}
                onPaginationChange={(pagination) => {
                    setPaginationModel(pagination);
                }}
                onFilterChange={(filter) => {
                    setFilterModel(filter);
                }}
            />

        </Box>
    )
}

export default SubcategoriesPage
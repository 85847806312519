import { Alert, Box, Button, Snackbar, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { tokens } from '../../theme'
import { Formik } from 'formik';
import * as yup from "yup";
import axios from 'axios';
import { useContext } from 'react';
import { AdminContext } from '../../context/AdminContext';
import { FormattedMessage } from 'react-intl';
import ForgetPasswordDialog from '../../components/ForgetPasswordDialog';

function LoginPage() {
    const colors = tokens();
    const { setToken } = useContext(AdminContext);
    const [showError, setShowError] = useState(false);
    const [forgetPasswordDialogOpen, setForgetPasswordDialogOpen] = useState(false);

    const [snackbarState, setSnackbarState] = useState({ open: false, type: "", message: "" });
    const handleSnackbarOpen = (type, message) => {
        setSnackbarState({ open: true, type: type, message: message });
    };
    const handleSnackbarClose = () => {
        setSnackbarState({ open: false, type: "", message: "" });
    }

    var initialValues = {
        username: "",
        password: ""
    }

    var validationObject = {
        username: yup.string().required(<FormattedMessage id="required" />),
        password: yup.string().required(<FormattedMessage id="required" />)
    }

    const adminSchema = yup.object().shape(validationObject);

    const handleFormSubmit = async (values) => {
        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/login`, {
            ...values
        }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                setToken(response.data.token);
            } else {
                setShowError(true);
            }
        });
    }

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarState.open}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarState.type} sx={{ width: '100%' }}>
                    {snackbarState.message}
                </Alert>
            </Snackbar>
            {forgetPasswordDialogOpen &&
                <ForgetPasswordDialog
                    dialogOpen={forgetPasswordDialogOpen}
                    handleClose={() => {
                        setForgetPasswordDialogOpen(false);
                    }}
                    onSuccess={() => {
                        handleSnackbarOpen("success", <FormattedMessage id="password_reset_success" />);
                        setForgetPasswordDialogOpen(false);
                    }}
                />
            }
            <Box bgcolor={colors.grey[900]} p="20px" borderRadius="10px">
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={adminSchema}
                >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="25px" mb="25px">
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label={<FormattedMessage id="username" defaultMessage="Username" />}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name={"username"}
                                    value={values.username}
                                    error={!!touched.username && !!errors.username}
                                    helperText={touched.username && errors.username}
                                    sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                />
                                <Box sx={{ gridColumn: "span 2", marginTop: "20px", textAlign: "end" }}>
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="password"
                                        label={<FormattedMessage id="password" defaultMessage="Password" />}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"password"}
                                        value={values.password}
                                        error={!!touched.password && !!errors.password}
                                        helperText={touched.password && errors.password}
                                    />
                                    <Typography
                                        variant="caption"
                                        color={colors.grey[400]}
                                        sx={{ marginTop: "5px", cursor: "pointer", userSelect: "none" }}
                                        onClick={() => {
                                            setForgetPasswordDialogOpen(true);
                                        }}
                                    >
                                        <FormattedMessage id="forget_password" defaultMessage="Forget Password?" />
                                    </Typography>
                                </Box>
                            </Box>

                            {showError &&
                                <Typography color="error" sx={{ gridColumn: "span 2", textAlign: "center" }}>
                                    <FormattedMessage
                                        id="login.error"
                                        defaultMessage="Username or password is incorrect"
                                    />
                                </Typography>}

                            <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                <Button type="submit" color="success" variant="contained">
                                    <Typography variant='h5'>
                                        <FormattedMessage id="login" />
                                    </Typography>
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Box>
    )
}

export default LoginPage
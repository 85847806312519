import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

function ChangeDayIntervalForForbiddenWordCheckDialog({ settings, dialogOpen, handleClose, handleFormSubmit }) {

    const { formatMessage } = useIntl();

    var initialValues = {
        day_interval_for_forbidden_keyword_check: settings?.day_interval_for_forbidden_keyword_check || 3,
    };

    var validationObject = {
        day_interval_for_forbidden_keyword_check: yup.number().required(formatMessage({ id: "required" })).min(0, formatMessage({ id: "min_value" }, { value: 0 }))
    }

    const settingsSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            open={dialogOpen}
            fullWidth
            maxWidth="sm"
            onClose={(e, reason) => {
                if (reason && reason === "backdropClick") {
                    return;
                }
                handleClose();
            }}>
            <Box m="20px" textAlign="center">
                <Typography variant='h3'>
                    <FormattedMessage id={"change_day_interval"} />
                </Typography>

                <Button
                    variant="standard"
                    color="error"
                    sx={{
                        borderRadius: "100%",
                        backgroundColor: "white",
                        maxWidth: "40px",
                        maxHeight: "40px",
                        minWidth: "40px",
                        minHeight: "40px",
                        position: "absolute",
                        top: "10px",
                        right: "10px"
                    }}
                    onClick={handleClose}>
                    <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                </Button>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={settingsSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="20px" gridTemplateColumns="repeat(1, minmax(0, 1fr))" mx="75px" my="25px">

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "day_interval" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="day_interval_for_forbidden_keyword_check"
                                        value={values.day_interval_for_forbidden_keyword_check}
                                        error={!!touched.day_interval_for_forbidden_keyword_check && !!errors.day_interval_for_forbidden_keyword_check}
                                        helperText={touched.day_interval_for_forbidden_keyword_check && errors.day_interval_for_forbidden_keyword_check}
                                        sx={{ gridColumn: "span 1", }}
                                    />


                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px" gap={"10px"}>
                                    <Button type="button" color="warning" variant="contained" onClick={() => {
                                        resetForm();
                                    }}>
                                        <FormattedMessage id="reset" />
                                    </Button>
                                    <Button type="button" color="error" variant="contained" onClick={handleClose}>
                                        <FormattedMessage id="cancel" />

                                    </Button>
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <FormattedMessage id="save" />
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default ChangeDayIntervalForForbiddenWordCheckDialog
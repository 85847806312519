import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { tokens } from '../../../theme';
import { FormattedMessage, useIntl } from 'react-intl';

function UserDialog({ targetUser, dialogOpen, handleClose, handleFormSubmit }) {

    const colors = tokens();

    const { formatMessage } = useIntl();

    var initialValues = {
        email: targetUser?.email ? targetUser?.email : "",
        phone_number: targetUser?.phone_number ? targetUser?.phone_number : "",
        name: targetUser?.name ? targetUser?.name : "",
        ...(targetUser?.account_type !== 1 ? { surname: targetUser?.surname ? targetUser?.surname : "" } : {}),
        photo: null,
        profile_photo_link: targetUser?.profile_photo_link ? targetUser?.profile_photo_link : "",
        profile_photo_path: targetUser?.profile_photo_path ? targetUser?.profile_photo_path : "",
        ...(targetUser?.is_premium ? { bio: targetUser?.bio ? targetUser?.bio : "" } : {})
    };

    var validationObject = {
        email: yup.string().email(<FormattedMessage id="invalid_email" />).when('phone_number', {
            is: (phone_number) => (!phone_number || phone_number === ""),
            then: () => yup.string().required(<FormattedMessage id="required" />)
        }),
        phone_number: yup.string().matches(/^\+\d{10,15}$/, <FormattedMessage id="invalid_phone_number" />),
        name: yup.string().required(<FormattedMessage id="required" />),
        ...(targetUser?.account_type !== 1 ? { surname: yup.string().required(<FormattedMessage id="required" />) } : {}),
        photo: yup.mixed().nullable().when('profile_photo_link', {
            is: (profile_photo_link) => (!profile_photo_link || profile_photo_link === ""),
            then: () => yup.string().nonNullable("").required(<FormattedMessage id="required" />)
        }),
        profile_photo_path: yup.string().nullable(),
        profile_photo_link: yup.string(),
        ...(targetUser?.is_premium ? { bio: yup.string().required(<FormattedMessage id="required" />) } : {})
    }

    const userSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">
                <Typography variant='h3'>
                    <FormattedMessage id="edit_user" />
                </Typography>
                <Button
                    variant="standard"
                    color="error"
                    sx={{
                        borderRadius: "100%",
                        backgroundColor: "white",
                        maxWidth: "40px",
                        maxHeight: "40px",
                        minWidth: "40px",
                        minHeight: "40px",
                        position: "absolute",
                        top: "10px",
                        right: "10px"
                    }}
                    onClick={handleClose}>
                    <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                </Button>
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={userSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm }) => (
                            <form onSubmit={handleSubmit}>
                                <Box textAlign="center">
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography variant="h6">
                                            {formatMessage({ id: "users.table.columns.profile_photo" })[0].toUpperCase() + formatMessage({ id: "users.table.columns.profile_photo" }).slice(1).toLocaleLowerCase()}
                                        </Typography>
                                        <Button
                                            variant="standard"
                                            color="error"
                                            sx={{
                                                borderRadius: "100%",
                                                backgroundColor: "white",
                                                maxWidth: "30px",
                                                maxHeight: "30px",
                                                minWidth: "30px",
                                                minHeight: "30px",
                                                ":hover": {
                                                    backgroundColor: "#f0f0f0"
                                                }
                                            }}
                                            onClick={() => {
                                                setFieldValue("photo", null);
                                                setFieldValue("profile_photo_link", `${process.env.REACT_APP_WEB_URL}/images/owajoo/profile-photos/default${targetUser.gender}.png`);
                                                setFieldValue("profile_photo_path", `images/owajoo/profile-photos/default${targetUser.gender}.png`);
                                            }}
                                        >
                                            <p style={{ color: "red", fontSize: "20px" }}>↺</p>
                                        </Button>
                                    </Box>
                                    <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[900], height: "150px", width: "150px" }}>
                                        {(values.photo || (values.profile_photo_link && values.profile_photo_link !== "")) && <img alt='user-profile' width="100px" src={values.photo ? URL.createObjectURL(values.photo) : values.profile_photo_link} />}
                                        <input id="photo" name="photo" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("photo", event.target.files[0]); }} hidden />
                                    </Button>
                                </Box>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="75px" my="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="email"
                                        label={formatMessage({ id: "users.table.columns.email" })[0].toUpperCase() + formatMessage({ id: "users.table.columns.email" }).slice(1).toLocaleLowerCase()}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"email"}
                                        value={values.email}
                                        error={!!touched.email && !!errors.email}
                                        helperText={touched.email && errors.email}
                                        sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "users.table.columns.phone_number" })[0].toUpperCase() + formatMessage({ id: "users.table.columns.phone_number" }).slice(1).toLocaleLowerCase()}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"phone_number"}
                                        value={values.phone_number}
                                        error={!!touched.phone_number && !!errors.phone_number}
                                        helperText={touched.phone_number && errors.phone_number}
                                        sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "table.columns.name" })[0].toUpperCase() + formatMessage({ id: "table.columns.name" }).slice(1).toLocaleLowerCase()}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"name"}
                                        value={values.name}
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                        sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                    />
                                    {targetUser?.account_type !== 1 &&
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label={formatMessage({ id: "users.table.columns.surname" })[0].toUpperCase() + formatMessage({ id: "users.table.columns.surname" }).slice(1).toLocaleLowerCase()}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"surname"}
                                            value={values.surname}
                                            error={!!touched.surname && !!errors.surname}
                                            helperText={touched.surname && errors.surname}
                                            sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                        />
                                    }

                                    {!!targetUser?.is_premium &&
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label={formatMessage({ id: "bio" })[0].toUpperCase() + formatMessage({ id: "bio" }).slice(1).toLocaleLowerCase()}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"bio"}
                                            value={values.bio}
                                            multiline
                                            rows={4}
                                            error={!!touched.bio && !!errors.bio}
                                            helperText={touched.bio && errors.bio}
                                            sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                        />
                                    }

                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px" gap={"10px"}>
                                    <Button type="button" color="warning" variant="contained" onClick={() => {
                                        resetForm();
                                    }}>
                                        <FormattedMessage id="reset" />
                                    </Button>
                                    <Button type="button" color="error" variant="contained" onClick={handleClose}>
                                        <FormattedMessage id="cancel" />

                                    </Button>
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <FormattedMessage id="save" />

                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default UserDialog
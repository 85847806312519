import React, { useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import axios from 'axios';

function ForgetPasswordDialog({ dialogOpen, handleClose, onSuccess }) {

    const [showError, setShowError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { formatMessage } = useIntl();

    var initialValues = {
        email: "",
        secret_question: "",
        secret_answer: ""
    };

    var validationObject = {
        email: yup.string().email(formatMessage({ id: "invalid_email" })).required(formatMessage({ id: "required" })),
        secret_question: yup.string().required(formatMessage({ id: "required" })),
        secret_answer: yup.string().required(formatMessage({ id: "required" }))
    }

    const forgetPasswordSchema = yup.object().shape(validationObject);

    const handleFormSubmit = async (values) => {
        setIsLoading(true);
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/resetPasswordWithSecrets`, {
            ...values
        }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            setIsLoading(false);
            if (response && response.data.success === true && response.data.status === 201) {
                onSuccess();
            } else {
                setShowError(true);
            }
        })
    };

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            open={dialogOpen}
            fullWidth
            maxWidth="sm"
            onClose={(e, reason) => {
                if (reason && reason === "backdropClick") {
                    return;
                }
                handleClose();
            }}>
            <Box m="20px" textAlign="center">
                <Typography variant='h3'>
                    <FormattedMessage id={"reset_password"} />
                </Typography>

                <Button
                    variant="standard"
                    color="error"
                    sx={{
                        borderRadius: "100%",
                        backgroundColor: "white",
                        maxWidth: "40px",
                        maxHeight: "40px",
                        minWidth: "40px",
                        minHeight: "40px",
                        position: "absolute",
                        top: "10px",
                        right: "10px"
                    }}
                    onClick={handleClose}>
                    <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                </Button>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={forgetPasswordSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="20px" gridTemplateColumns="repeat(1, minmax(0, 1fr))" mx="75px" my="25px">

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "email" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="email"
                                        value={values.email}
                                        error={!!touched.email && !!errors.email}
                                        helperText={touched.email && errors.email}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "secret_question" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="secret_question"
                                        value={values.secret_question}
                                        error={!!touched.secret_question && !!errors.secret_question}
                                        helperText={touched.secret_question && errors.secret_question}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "secret_answer" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="secret_answer"
                                        value={values.secret_answer}
                                        error={!!touched.secret_answer && !!errors.secret_answer}
                                        helperText={touched.secret_answer && errors.secret_answer}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                </Box>
                                {showError &&
                                    <Typography color="error" sx={{ gridColumn: "span 2", textAlign: "center" }}>
                                        <FormattedMessage
                                            id="reset_password_error"
                                            defaultMessage="Given informations are not correct."
                                        />
                                    </Typography>}
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px" gap={"10px"}>
                                    <Button type="button" color="error" variant="contained" onClick={handleClose}>
                                        <FormattedMessage id="cancel" />

                                    </Button>
                                    <Button type="submit" color="secondary" variant="contained" disabled={isLoading} onClick={() => console.log(errors)}>
                                        <FormattedMessage id="send" />
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default ForgetPasswordDialog
import { Box, Button, IconButton, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { tokens } from '../../../theme';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { AdminContext } from '../../../context/AdminContext';

function urlify(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex)
        .map(part => {
            if (part.match(urlRegex)) {
                return <Box key={part} component="a" href={part} style={{ wordWrap: "break-word", display: "inline-block" }}>{part}</Box>;
            }
            return part;
        });
}

function FaqBox({ faq, onEditButtonClick, onDeleteButtonClick, onChangePositionButtonClick, maxPosition }) {
    const { admin } = useContext(AdminContext);
    const colors = tokens();

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ gridColumn: "span 1", border: "1px solid " + colors.grey[500], borderRadius: "20px" }}>
            <Box padding="10px" display="flex" justifyContent="space-between" alignItems="center" >
                <Typography variant="h3">{faq.title}</Typography>
            </Box>
            <Box component="hr" height="1px" width="100%" sx={{ background: "black", border: "0px", margin: "0px" }} />
            <Box display={"flex"} alignItems="top" justifyContent="space-between" padding="10px" sx={{ wordWrap: "break-word" }}>
                <Typography variant='h5' mt="10px">{!!faq.answer &&
                    [faq.answer].map(answer => {
                        var urlRegex = /(https?:\/\/[^\s]+)/g;
                        if (urlRegex.test(answer)) {
                            return urlify(answer);
                        } else {
                            return answer;
                        }
                    })
                }</Typography>
                {
                    admin.faq_edit_perm === 1 &&
                    <Box display="flex" flexDirection="column">
                        {faq.position !== 1 &&
                            <IconButton onClick={() => onChangePositionButtonClick(faq.id, faq.position, faq.position - 1)}>
                                <ArrowUpward />
                            </IconButton>
                        }
                        {faq.position !== maxPosition &&
                            <IconButton onClick={() => onChangePositionButtonClick(faq.id, faq.position, faq.position + 1)}>
                                <ArrowDownward />
                            </IconButton>
                        }
                    </Box>
                }
            </Box>
            <Box display="flex" gap="10px" padding="10px" justifyContent="end">
                {admin.faq_edit_perm === 1 &&
                    <Button variant="contained" color="info" sx={{ width: "35%", borderRadius: "12px" }} onClick={() => onEditButtonClick()}>
                        <FormattedMessage id="edit" />
                    </Button>
                }
                {admin.faq_deletion_perm === 1 &&
                    <Button variant="contained" color="error" sx={{ width: "35%", borderRadius: "12px" }} onClick={() => onDeleteButtonClick()}>
                        <FormattedMessage id="delete" />
                    </Button>
                }
            </Box>
        </Box>
    )
}

export default FaqBox
import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

function CommentEditDialog({ comment, dialogOpen, handleClose, handleFormSubmit }) {
    const { formatMessage } = useIntl();

    var initialValues = {
        comment: comment?.comment ? comment?.comment : "",
        service_quality_point: comment?.service_quality_point !== undefined && comment?.service_quality_point !== null ? comment?.service_quality_point : 5,
        timing_point: comment?.timing_point !== undefined && comment?.timing_point !== null ? comment?.timing_point : 5,
        communication_point: comment?.communication_point !== undefined && comment?.communication_point !== null ? comment?.communication_point : 5,
        price_point: comment?.price_point !== undefined && comment?.price_point !== null ? comment?.price_point : 5,
    };

    var validationObject = {
        comment: yup.string().required(formatMessage({ id: "required" })),
        service_quality_point: yup.number().min(0, formatMessage({ id: "min_0_point_max_5_point" })).max(5, formatMessage({ id: "min_0_point_max_5_point" })).required(formatMessage({ id: "required" })),
        timing_point: yup.number().min(0, formatMessage({ id: "min_0_point_max_5_point" })).max(5, formatMessage({ id: "min_0_point_max_5_point" })).required(formatMessage({ id: "required" })),
        communication_point: yup.number().min(0, formatMessage({ id: "min_0_point_max_5_point" })).max(5, formatMessage({ id: "min_0_point_max_5_point" })).required(formatMessage({ id: "required" })),
        price_point: yup.number().min(0, formatMessage({ id: "min_0_point_max_5_point" })).max(5, formatMessage({ id: "min_0_point_max_5_point" })).required(formatMessage({ id: "required" })),
    }

    const commentSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            open={dialogOpen}
            fullWidth
            maxWidth="sm"
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    <FormattedMessage id="edit_comment" />
                </Typography>


                <Button
                    variant="standard"
                    color="error"
                    sx={{
                        borderRadius: "100%",
                        backgroundColor: "white",
                        maxWidth: "40px",
                        maxHeight: "40px",
                        minWidth: "40px",
                        minHeight: "40px",
                        position: "absolute",
                        top: "10px",
                        right: "10px"
                    }}
                    onClick={handleClose}>
                    <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                </Button>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={commentSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" mx="50px" mb="25px">

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "timing" }) + " " + formatMessage({ id: "point" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"timing_point"}
                                        value={values.timing_point}
                                        error={!!touched.timing_point && !!errors.timing_point}
                                        helperText={touched.timing_point && errors.timing_point}
                                        sx={{ gridColumn: "span 1", }}
                                    />


                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "communication" }) + " " + formatMessage({ id: "point" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"communication_point"}
                                        value={values.communication_point}
                                        error={!!touched.communication_point && !!errors.communication_point}
                                        helperText={touched.communication_point && errors.communication_point}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "quality" }) + " " + formatMessage({ id: "point" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"service_quality_point"}
                                        value={values.service_quality_point}
                                        error={!!touched.service_quality_point && !!errors.service_quality_point}
                                        helperText={touched.service_quality_point && errors.service_quality_point}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "price" }) + " " + formatMessage({ id: "point" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"price_point"}
                                        value={values.price_point}
                                        error={!!touched.price_point && !!errors.price_point}
                                        helperText={touched.price_point && errors.price_point}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Yorum"
                                        multiline
                                        minRows={3}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"comment"}
                                        value={values.comment}
                                        error={!!touched.comment && !!errors.comment}
                                        helperText={touched.comment && errors.comment}
                                        sx={{ gridColumn: "span 4" }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" >
                                        <Typography variant='h5'>
                                            <FormattedMessage id="save" />
                                        </Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default CommentEditDialog
import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Checkbox, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import axios from 'axios';
import { AdminContext } from '../../../context/AdminContext';

function NotificationDialog({ notification, initialDraftType = "general", dialogOpen, handleClose, onFormSubmit }) {
    const { admin, token } = useContext(AdminContext);
    const { formatMessage } = useIntl();
    const [chooseFromDrafts, setChooseFromDrafts] = useState(false);
    const [draftType, setDraftType] = useState(initialDraftType);
    const [drafts, setDrafts] = useState([]);
    const [selectedDraft, setSelectedDraft] = useState(null);

    useEffect(() => {
        fetchDrafts(token, draftType);
    }, [token, draftType]);

    var initialValues = {
        type: notification?.type || "system",
        content_en: notification?.content_en || "",
        content_de: notification?.content_de || "",
        user_ids: notification?.user_ids ? typeof notification.user_ids !== "string" ? JSON.stringify(notification.user_ids) : notification.user_ids : "",
        user_filter: notification?.user_filter ? typeof notification.user_filter !== "string" ? JSON.stringify(notification.user_filter) : notification.user_filter : "",
        delivery_type: notification?.delivery_type || "",
    };

    var validationObject = {
        type: yup.string(),
        content_en: yup.string().required(formatMessage({ id: "required" })),
        content_de: yup.string().required(formatMessage({ id: "required" })),
        user_ids: yup.string(),
        user_filter: yup.string(),
        delivery_type: yup.string().required(formatMessage({ id: "required" }))
    }

    const notificationSchema = yup.object().shape(validationObject);

    const handleFormSubmit = (values) => {
        axios.post(`${process.env.REACT_APP_API_URL}webAdmin/notifyUsers`,
            {
                notification: {
                    type: values.type,
                    content_en: values.content_en,
                    content_de: values.content_de
                },
                userIds: values.user_ids,
                userFilter: values.user_filter,
                deliveryType: values.delivery_type
            },
            { headers: { token: token } }
        ).catch((error) => {
            console.log(error);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                onFormSubmit();
            }
        });
    }

    const fetchDrafts = (token, draftType) => {
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/getNotificationDrafts`,
            {
                filter: [{
                    id: "type",
                    value: draftType,
                    type: "select"
                }]
            },
            { headers: { token: token } }
        )
            .catch((error) => {
                console.log(error);
                setDrafts([]);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setDrafts(response.data.drafts);
                }
            });
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            fullWidth
            open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    <FormattedMessage id="send_notification" />
                </Typography>

                <Button
                    variant="standard"
                    color="error"
                    sx={{
                        borderRadius: "100%",
                        backgroundColor: "white",
                        maxWidth: "40px",
                        maxHeight: "40px",
                        minWidth: "40px",
                        minHeight: "40px",
                        position: "absolute",
                        top: "10px",
                        right: "10px"
                    }}
                    onClick={handleClose}>
                    <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                </Button>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        onReset={() => {
                            setChooseFromDrafts(false);
                            setSelectedDraft(null);
                            setDraftType(initialDraftType);
                        }}
                        initialValues={initialValues}
                        validationSchema={notificationSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, setFieldValue, handleSubmit, resetForm }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="75px" mb="25px" mt="0px">

                                    {admin.notification_drafts_perm === 1 &&
                                        <Box sx={{ gridColumn: "span 2" }}>
                                            <Checkbox
                                                checked={chooseFromDrafts}
                                                onChange={(e) => {
                                                    setChooseFromDrafts(e.target.checked)
                                                    if (!e.target.checked) {
                                                        setSelectedDraft(null);
                                                        setDraftType(initialDraftType);
                                                    }
                                                }}
                                            />
                                            <FormattedMessage id="choose_from_drafts" />
                                        </Box>
                                    }
                                    {chooseFromDrafts &&
                                        <>
                                            <FormControl
                                                fullWidth
                                                variant="filled"
                                                disabled={!chooseFromDrafts}
                                                sx={{ gridColumn: "span 1" }}>
                                                <InputLabel id="filter-label1">
                                                    {formatMessage({ id: "type" })[0].toUpperCase() + formatMessage({ id: "type" }).slice(1).toLocaleLowerCase()}
                                                </InputLabel>
                                                <Select
                                                    labelId="filter-label1"
                                                    label={formatMessage({ id: "type" })[0].toUpperCase() + formatMessage({ id: "type" }).slice(1).toLocaleLowerCase()}
                                                    onChange={(e) => {
                                                        setDraftType(e.target.value);
                                                        setSelectedDraft(null);
                                                    }}
                                                    name={"draft_type"}
                                                    value={draftType}
                                                >
                                                    <MenuItem value={"general"}>
                                                        <FormattedMessage id={"notification_drafts.type.option.general"} />
                                                    </MenuItem>
                                                    <MenuItem value={"after_user_edit"}>
                                                        <FormattedMessage id={"notification_drafts.type.option.after_user_edit"} />
                                                    </MenuItem>
                                                    <MenuItem value={"advertisement"}>
                                                        <FormattedMessage id={"notification_drafts.type.option.advertisement"} />
                                                    </MenuItem>
                                                    <MenuItem value={"after_advertisement_edit"}>
                                                        <FormattedMessage id={"notification_drafts.type.option.after_advertisement_edit"} />
                                                    </MenuItem>
                                                    <MenuItem value={"report_and_support_answers"}>
                                                        <FormattedMessage id={"notification_drafts.type.option.report_and_support_answers"} />
                                                    </MenuItem>
                                                    <MenuItem value={"comment"}>
                                                        <FormattedMessage id={"notification_drafts.type.option.comment"} />
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl
                                                fullWidth
                                                variant="filled"
                                                disabled={!chooseFromDrafts}
                                                sx={{ gridColumn: "span 2" }}>
                                                <InputLabel id="filter-label2">
                                                    {formatMessage({ id: "notification_drafts.header.title" })}
                                                </InputLabel>
                                                <Select
                                                    labelId="filter-label2"
                                                    label={formatMessage({ id: "notification_drafts.header.title" })}
                                                    onChange={(e) => {
                                                        setSelectedDraft(drafts.find(draft => draft.id === e.target.value));
                                                        setFieldValue("content_en", drafts.find(draft => draft.id === e.target.value)?.content_en || "");
                                                        setFieldValue("content_de", drafts.find(draft => draft.id === e.target.value)?.content_de || "");
                                                    }}
                                                    name={"selected_draft"}
                                                    value={selectedDraft?.id || ""}
                                                >
                                                    {drafts?.length > 0 &&
                                                        drafts.map((draft) => (
                                                            <MenuItem key={draft.id} value={draft.id}>
                                                                {admin.language === "en" ? draft.content_en : draft.content_de}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </>
                                    }

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "notifications.table.columns.content_with_language_code" }, { language: "en" })[0].toUpperCase() + formatMessage({ id: "notifications.table.columns.content_with_language_code" }, { language: "en" }).slice(1).toLocaleLowerCase()}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"content_en"}
                                        value={values.content_en}
                                        multiline
                                        rows={4}
                                        error={!!touched.content_en && !!errors.content_en}
                                        helperText={touched.content_en && errors.content_en}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "notifications.table.columns.content_with_language_code" }, { language: "de" })[0].toUpperCase() + formatMessage({ id: "notifications.table.columns.content_with_language_code" }, { language: "de" }).slice(1).toLocaleLowerCase()}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"content_de"}
                                        value={values.content_de}
                                        multiline
                                        rows={4}
                                        error={!!touched.content_de && !!errors.content_de}
                                        helperText={touched.content_de && errors.content_de}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                    <FormControl
                                        fullWidth
                                        variant="filled"
                                        sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label1">
                                            {formatMessage({ id: "notifications.table.columns.delivery_type" })[0].toUpperCase() + formatMessage({ id: "notifications.table.columns.delivery_type" }).slice(1).toLocaleLowerCase()}
                                        </InputLabel>
                                        <Select
                                            labelId="filter-label1"
                                            label={formatMessage({ id: "notifications.table.columns.delivery_type" })[0].toUpperCase() + formatMessage({ id: "notifications.table.columns.delivery_type" }).slice(1).toLocaleLowerCase()}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"delivery_type"}
                                            value={values.delivery_type}
                                            error={!!touched.delivery_type && !!errors.delivery_type}
                                        >
                                            <MenuItem value={"notification"}>
                                                <FormattedMessage id={"notifications.delivery_type.option.notification"} />
                                            </MenuItem>
                                            <MenuItem value={"email"}>
                                                <FormattedMessage id={"notifications.delivery_type.option.email"} />
                                            </MenuItem>
                                            <MenuItem value={"notification_and_email"}>
                                                <FormattedMessage id={"notifications.delivery_type.option.notification_and_email"} />
                                            </MenuItem>
                                            <MenuItem value={"notification_if_allowed_or_email"}>
                                                <FormattedMessage id={"notifications.delivery_type.option.notification_if_allowed_or_email"} />
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px" gap={"10px"}>
                                    <Button type="button" color="warning" variant="contained" onClick={() => {
                                        resetForm();
                                    }}>
                                        <FormattedMessage id="reset" />
                                    </Button>
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <FormattedMessage id="send" />
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default NotificationDialog
import React, { useContext, useState } from 'react'
import { Box, Button, Dialog, DialogContent, Menu, MenuItem, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import AdvertisementDetailsDialog from '../Advertisement/AdvertisementDetailsDialog';
import AdvertisementEditDialog from '../Advertisement/AdvertisementEditDialog';
import AlertDialogSlide from '../AlertDialogSlide';
import axios from 'axios';
import { AdminContext } from '../../context/AdminContext';

function ReportDetailsDialog({ report, dialogOpen, handleClose, onNotificationSendButtonClick, onDeleteButtonClick, onChangeStatusButtonClick }) {
    const { token, admin } = useContext(AdminContext);
    const [advertisementDetailsDialogState, setAdvertisementDetailsDialogState] = useState({ open: false, advertisement: null });
    const [editAdvertisementDialogState, setEditAdvertisementDialogState] = useState({ open: false, advertisement: null });
    const [alertDialogState, setAlertDialogState] = useState({
        open: false,
        title: "",
        description: "",
        positiveAnswer: "",
        negativeAnswer: "",
        onApprove: () => { }
    });
    const handleAlertDialogClose = () => {
        setAlertDialogState({
            open: false,
            title: "",
            description: "",
            positiveAnswer: "",
            negativeAnswer: "",
            onApprove: () => { }
        });
    }
    const { formatMessage } = useIntl();
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const updateAdvertisement = async (advertisementId, advertisement, advertisementContents) => {
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/updateAdvertisement?advertisementId=${advertisementId}`,
            {
                advertisement: advertisement
            },
            { headers: { token: token } }
        )
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    if (advertisementContents?.length > 0) {
                        axios.patch(
                            `${process.env.REACT_APP_API_URL}webAdmin/updateAdvertisementContents?advertisementId=${advertisementId}`,
                            {
                                advertisementContents: advertisementContents
                            },
                            { headers: { token: token } }
                        ).catch((err) => {
                            console.log("err: " + err);
                        }).then((response) => {
                            if (response && response.data.success === true && response.data.status === 201) {
                                setEditAdvertisementDialogState({ open: false, advertisement: null });
                            }
                        });
                    } else {
                        setEditAdvertisementDialogState({ open: false, advertisement: null });
                    }
                }
            });
    }

    const fetchAdvertisement = async (report, token, language) => {
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/getAdvertisements`,
            {
                startIndex: 0,
                limit: 1,
                orderBy: null,
                orderType: null,
                filter: [
                    {
                        id: "id",
                        value: report.advertisement_id,
                        type: "number",
                        min: true
                    },
                    {
                        id: "id",
                        value: report.advertisement_id,
                        type: "number",
                        max: true
                    }
                ],
                language: language
            },
            { headers: { token: token } }
        )
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setAdvertisementDetailsDialogState({ open: true, advertisement: response.data.advertisements[0] });
                }
            });
    }

    return (
        report &&
        <>
            <Menu
                id="menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    handleMenuClose();
                    onNotificationSendButtonClick([report.origin_user_id]);
                }}>
                    <FormattedMessage id="to_the_reporter" />
                </MenuItem>
                <MenuItem onClick={() => {
                    handleMenuClose();
                    onNotificationSendButtonClick([report.reported_user_id]);
                }}>
                    <FormattedMessage id="to_the_reported" />
                </MenuItem>
                <MenuItem onClick={() => {
                    handleMenuClose();
                    onNotificationSendButtonClick([report.origin_user_id, report.reported_user_id]);
                }}>
                    <FormattedMessage id="both_of_them" />
                </MenuItem>
            </Menu>
            {alertDialogState.open &&
                <AlertDialogSlide
                    open={alertDialogState.open}
                    handleClose={(event, reason) => {
                        if (reason && reason === "backdropClick") {
                            return;
                        }
                        handleAlertDialogClose();
                    }}
                    clickPositiveAnswer={() => {
                        alertDialogState.onApprove();
                        setAlertDialogState({ open: false, type: "" });
                    }}
                    clickNegativeAnswer={() => { }}
                    positiveAnswer={alertDialogState.positiveAnswer}
                    negativeAnswer={alertDialogState.negativeAnswer}
                    title={alertDialogState.title}
                    description={alertDialogState.description}
                />
            }
            {advertisementDetailsDialogState.open && advertisementDetailsDialogState.advertisement &&
                <AdvertisementDetailsDialog
                    advertisement={advertisementDetailsDialogState.advertisement}
                    dialogOpen={advertisementDetailsDialogState.open}
                    handleClose={() => {
                        setAdvertisementDetailsDialogState({ open: false, advertisement: null });
                    }}
                    onEditButtonClick={(advertisement) => {
                        setEditAdvertisementDialogState({ open: true, advertisement: advertisement });
                        setAdvertisementDetailsDialogState({ open: false, advertisement: null });
                    }}
                />
            }
            {editAdvertisementDialogState?.open && editAdvertisementDialogState.advertisement &&
                <AdvertisementEditDialog
                    advertisement={editAdvertisementDialogState.advertisement}
                    dialogOpen={editAdvertisementDialogState.open}
                    handleClose={() => {
                        setEditAdvertisementDialogState({ open: false, advertisement: null });
                    }}
                    handleFormSubmit={(values) => {
                        let advertisement = { ...values };
                        //parse advertisement.expiration_date and advertisement.featured_end_date to MySQL datetime format
                        if (advertisement.expiration_date) {
                            advertisement.expiration_date = advertisement.expiration_date.format("YYYY-MM-DD HH:mm:ss");
                        }
                        if (advertisement.featured_end_date) {
                            advertisement.featured_end_date = advertisement.featured_end_date.format("YYYY-MM-DD HH:mm:ss");
                        }
                        advertisement.is_active = advertisement.is_active ? 1 : 0;
                        advertisement.is_featured = advertisement.is_featured ? 1 : 0;
                        advertisement.categoryIds = advertisement.categories.map((category) => category.id);
                        delete advertisement.categories;
                        let advertisementContents = [];
                        advertisement.advertisement_contents.forEach((content, index) => {
                            advertisementContents.push({ content_path: content.content_path, is_photo: content.is_photo, position: index, advertisement_id: editAdvertisementDialogState.advertisement.id });
                        });
                        delete advertisement.advertisement_contents;
                        if (advertisementContents.length === 0) {
                            advertisementContents.push({
                                content_path: "images/owajoo/advertisements/default.png",
                                is_photo: 1,
                                position: 0,
                                advertisement_id: editAdvertisementDialogState.advertisement.id
                            });
                        }

                        setAlertDialogState({
                            open: true,
                            title: <FormattedMessage id={"are_you_sure_to_update_advertisement"} />,
                            description: <FormattedMessage id={"update_advertisement_description"} />,
                            positiveAnswer: <FormattedMessage id={"yes"} />,
                            negativeAnswer: <FormattedMessage id={"no"} />,
                            onApprove: () => {
                                updateAdvertisement(editAdvertisementDialogState.advertisement.id, advertisement, advertisementContents);
                            }
                        });
                    }}
                />
            }
            <Dialog
                sx={{ margin: "0 auto" }}
                maxWidth={"80%"}
                open={dialogOpen}
                onClose={(e, reason) => {
                    if (reason && reason === "backdropClick") {
                        return;
                    }
                    handleClose();
                }}>
                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        variant="standard"
                        color="error"
                        sx={{
                            borderRadius: "100%",
                            backgroundColor: "white",
                            maxWidth: "40px",
                            maxHeight: "40px",
                            minWidth: "40px",
                            minHeight: "40px",
                            position: "absolute",
                            top: "10px",
                            right: "10px"
                        }}
                        onClick={handleClose}>
                        <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                    </Button>
                </Box>
                <Box m="20px" textAlign="center">
                    <Typography variant='h3'>
                        <FormattedMessage id={"report_details"} />({report.id}) - <FormattedMessage id={"status"} />: <FormattedMessage id={report.status === 0 ? "waiting" : report.status === 1 ? "solved" : "archived"} />
                    </Typography>
                </Box>
                <DialogContent>
                    <Box>
                        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "0", gap: "20px" }}>
                            <Box display={"flex"} alignItems={"center"} gap={"50px"}>
                                <Box display="flex" gap="10px" alignItems="center">
                                    <Typography variant="h4">
                                        {formatMessage({ id: "reports.table.columns.reporter" }).slice(0, 1).toUpperCase() + formatMessage({ id: "reports.table.columns.reporter" }).slice(1).toLowerCase()}:
                                    </Typography>
                                    <Box display="flex" gap="10px" alignItems="center">
                                        <Box component="img" src={report.origin_user_profile_photo_link} alt={report.origin_user_name} sx={{ objectFit: "cover", width: "30px", height: "30px", borderRadius: "25px" }} />
                                        <Box>
                                            <Typography
                                                variant="h5"
                                                style={admin.users_perm === 1 ? { cursor: "pointer" } : {}}
                                                onClick={() => {
                                                    if (admin.users_perm === 1) {
                                                        window.open(`/users/${report.origin_user_id}`, "_blank");
                                                    }
                                                }}
                                            >
                                                {report.origin_user_name} {report.origin_user_surname}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" gap="10px" alignItems="center">
                                    <Typography variant="h4">
                                        {formatMessage({ id: "reports.table.columns.reported" }).slice(0, 1).toUpperCase() + formatMessage({ id: "reports.table.columns.reported" }).slice(1).toLowerCase()}:
                                    </Typography>
                                    <Box display="flex" gap="10px" alignItems="center">
                                        <Box component="img" src={report.reported_user_profile_photo_link} alt={report.reported_user_name} sx={{ objectFit: "cover", width: "30px", height: "30px", borderRadius: "25px" }} />
                                        <Box>
                                            <Typography
                                                variant="h5"
                                                style={admin.users_perm === 1 ? { cursor: "pointer" } : {}}
                                                onClick={() => {
                                                    if (admin.users_perm === 1) {
                                                        window.open(`/users/${report.reported_user_id}`, "_blank");
                                                    }
                                                }}
                                            >
                                                {report.reported_user_name} {report.reported_user_surname}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>

                            </Box>
                            {report.type === "advertisement" &&
                                <>
                                    <hr style={{ width: "100%" }} />
                                    <Box display="flex" flexDirection="column" alignItems="center" gap={"10px"}>
                                        <Typography variant="h4">
                                            {formatMessage({ id: "reports.type.option.advertisement" })}
                                        </Typography>
                                        <Box display={"flex"} gap={"10px"} alignItems={"center"}>
                                            <Box>
                                                <Box component={report.advertisement_content_is_photo ? "img" : "video"} controls src={report.advertisement_content_link} alt={report.advertisement_title} sx={{ objectFit: "cover", width: "255px", height: "153px", borderRadius: "10px" }} />
                                            </Box>
                                            <Box padding="10px" maxWidth={400}>
                                                <Typography variant='h3'>{report.advertisement_title}</Typography>
                                                <Typography mt={"10px"}>{report.advertisement_description}</Typography>
                                            </Box>
                                        </Box>
                                        <Button
                                            color="info"
                                            variant="contained"
                                            onClick={() => {
                                                fetchAdvertisement(report, token, admin.language);
                                            }}>
                                            <FormattedMessage id="show_advertisement_details" />
                                        </Button>
                                    </Box>
                                </>
                            }
                            {report.type === "offer" &&
                                <>
                                    <hr style={{ width: "100%" }} />
                                    <Box display="flex" flexDirection="column" alignItems="center" gap={"10px"}>
                                        <Typography variant="h4">
                                            {formatMessage({ id: report.offer.type })}
                                        </Typography>
                                        <Box display={"flex"} gap={"10px"} alignItems={"center"}>
                                            <Box>
                                                <Box component={report.offer.is_photo ? "img" : "video"} controls src={report.offer.content_link} alt={report.offer.title} sx={{ objectFit: "cover", width: "255px", height: "153px", borderRadius: "10px" }} />
                                            </Box>
                                            <Box padding="10px" maxWidth={400}>
                                                <Typography variant='h3'>{report.offer.title}</Typography>
                                                <Typography mt={"10px"}>{report.offer.details}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </>
                            }
                            <hr style={{ width: "100%" }} />
                            <Box>
                                <Box display="flex" gap="10px" alignItems="center" justifyContent={"center"}>
                                    <Typography variant="h4">
                                        {formatMessage({ id: "type" })}:
                                    </Typography>
                                    <Typography variant="h6">
                                        {formatMessage({ id: `reports.type.option.${report.type}` })}
                                    </Typography>
                                </Box>
                                {!!report.message && <p style={{ fontSize: "16px", textAlign: "center", margin: "10px 0px 0px 0px" }}>{report.message}</p>}
                            </Box>
                            {(admin.report_notification_perm === 1 || admin.report_deletion_perm === 1 || admin.report_edit_perm === 1) &&
                                <hr style={{ width: "100%" }} />
                            }
                        </Box>
                        <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                            {admin.report_notification_perm === 1 &&
                                <Button color="info" variant="contained"
                                    onClick={(e) => {
                                        handleMenuOpen(e);
                                    }}
                                    sx={{ marginRight: "10px" }}
                                >
                                    <FormattedMessage id="send_notification" />
                                </Button>
                            }
                            {admin.report_deletion_perm === 1 &&
                                <Button color="error" variant="contained" onClick={() => onDeleteButtonClick(report)} sx={{ marginRight: "10px" }}>
                                    <FormattedMessage id="delete" />
                                </Button>
                            }

                            {(report.status !== 0 && admin.report_edit_perm === 1) &&
                                <Button color="info" variant="contained" onClick={() => onChangeStatusButtonClick(report, { status: 0 })} sx={{ marginRight: "10px" }}>
                                    <FormattedMessage id="mark_as_waiting" />
                                </Button>
                            }
                            {(report.status !== 1 && admin.report_edit_perm === 1) &&
                                <Button color="success" variant="contained" onClick={() => onChangeStatusButtonClick(report, { status: 1 })} sx={{ marginRight: "10px" }}>
                                    <FormattedMessage id="mark_as_solved" />
                                </Button>
                            }
                            {(report.status !== 2 && admin.report_edit_perm === 1) &&
                                <Button color="error" variant="contained" onClick={() => onChangeStatusButtonClick(report, { status: 2 })} sx={{ marginRight: "10px" }}>
                                    <FormattedMessage id="mark_as_archived" />
                                </Button>
                            }
                        </Box>

                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ReportDetailsDialog
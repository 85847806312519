import { Box } from '@mui/material'
import React from 'react'
import Header from '../../global/Header';
import { FormattedMessage } from 'react-intl';
import AdvertisementsTable from './AdvertisementsTable';

function AdvertisementsPage() {

    return (
        <Box sx={{ p: "75px" }}>

            <Header
                title={<FormattedMessage id={"advertisements.header.title"} />}
                subtitle={<FormattedMessage id={"advertisements.header.description"} />} />
            <AdvertisementsTable />
        </Box>
    )
}

export default AdvertisementsPage
import React, { useState } from 'react'
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Editor from 'react-simple-wysiwyg';

function AboutUsDialog({ initialSettings, dialogOpen, handleClose, handleFormSubmit }) {

    const [settings, setSettings] = useState(initialSettings);

    const resetForm = () => {
        setSettings(initialSettings);
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            open={dialogOpen}
            fullWidth
            maxWidth="lg"
            onClose={(e, reason) => {
                if (reason && reason === "backdropClick") {
                    return;
                }
                handleClose();
            }}>
            <Box m="20px" textAlign="center">
                <Typography variant='h3'>
                    <FormattedMessage id={"edit_about_us"} />
                </Typography>

                <Button
                    variant="standard"
                    color="error"
                    sx={{
                        borderRadius: "100%",
                        backgroundColor: "white",
                        maxWidth: "40px",
                        maxHeight: "40px",
                        minWidth: "40px",
                        minHeight: "40px",
                        position: "absolute",
                        top: "10px",
                        right: "10px"
                    }}
                    onClick={handleClose}>
                    <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                </Button>

            </Box>
            <DialogContent>
                <Box>
                    <Box display="grid" gap="20px" gridTemplateColumns="repeat(1, minmax(0, 1fr))" mx="75px">

                        <Typography variant="h4">
                            <FormattedMessage id="about_us_with_language_code" values={{ language: "en" }} />
                        </Typography>

                        <Editor
                            value={settings.about_us_en}
                            onChange={(e) => {
                                setSettings((prev) => ({
                                    ...prev,
                                    about_us_en: e.target.value
                                }));
                            }}
                        />

                        <Typography variant="h4">
                            <FormattedMessage id="about_us_with_language_code" values={{ language: "de" }} />
                        </Typography>
                        <Editor
                            value={settings.about_us_de}
                            onChange={(e) => {
                                setSettings((prev) => ({
                                    ...prev,
                                    about_us_de: e.target.value
                                }));
                            }}
                        />

                    </Box>
                    <Box display="flex" justifyContent="end" m="30px 20px 0px 20px" gap={"10px"}>
                        <Button type="button" color="warning" variant="contained" onClick={() => {
                            resetForm();
                        }}>
                            <FormattedMessage id="reset" />
                        </Button>
                        <Button type="button" color="error" variant="contained" onClick={handleClose}>
                            <FormattedMessage id="cancel" />

                        </Button>
                        <Button type="submit" color="secondary" variant="contained" onClick={() => handleFormSubmit(settings)}>
                            <FormattedMessage id="save" />
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default AboutUsDialog
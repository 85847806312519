import { Box, Button, Snackbar } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import AlertDialogSlide from '../../components/AlertDialogSlide';
import CategoryDialog from './CategoryDialog';
import { Table } from '../../components/Table';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

function CategoriesPage() {
    const { admin, token } = useContext(AdminContext);
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [maxPositions, setMaxPositions] = useState({
        forAdvertisements: 0,
        forServiceProviders: 0
    });
    const [isLoading, setLoading] = useState(true);
    const [orderModel, setOrderModel] = useState(null);
    const [filterModel, setFilterModel] = useState(null);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        rowsPerPage: admin.categories_table_row_count
    });
    const [categoryEvent, setCategoryEvent] = useState({ category: null, type: null });
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [snackbarState, setSnackbarState] = useState({ open: false, message: "" });
    const handleSnackbarOpen = (message) => {
        setSnackbarState({ open: true, message: message });
    };

    const handleSnackbarClose = () => {
        setSnackbarState({ open: false, message: "" });
    };

    const columns = [
        {
            id: 'position',
            name: <FormattedMessage id={"table.columns.position"} />,
            type: 'number',
            sortable: true, filterable: true
        }, {
            id: 'content_link',
            name: <FormattedMessage id={"table.columns.content_link"} />,
            sortable: false, filterable: false,
            render: (value) => <img src={value} alt='category' style={{ height: "30px", width: "30px" }} />
        }, {
            id: 'name',
            name: <FormattedMessage id={"table.columns.name"} />,
            type: 'text',
            sortable: true, filterable: true
        }, {
            id: 'for_advertisements',
            name: <FormattedMessage id={"categories.table.columns.for_advertisements"} />,
            type: 'select',
            options: [
                { value: 0, label: <FormattedMessage id={"categories.type.option.service_provider"} /> },
                { value: 1, label: <FormattedMessage id={"categories.type.option.advertisement"} /> }
            ],
            sortable: true, filterable: true,
            render: (value) => value === 0 ? <FormattedMessage id={"categories.type.option.service_provider"} /> : <FormattedMessage id={"categories.type.option.advertisement"} />
        }, {
            id: 'subcategory_count',
            name: <FormattedMessage id={"categories.table.columns.subcategory_count"} />,
            type: 'number',
            sortable: true, filterable: true
        }, {
            id: 'advertisement_count',
            name: <FormattedMessage id={"categories.table.columns.advertisement_count"} />,
            type: 'number',
            sortable: true, filterable: true
        }, {
            id: "service_provider_count",
            name: <FormattedMessage id={"categories.table.columns.service_provider_count"} />,
            type: 'number',
            sortable: true, filterable: true
        }, ...(admin.category_edit_perm === 1 || admin.category_deletion_perm === 1 ? [{
            id: 'actions',
            name: <FormattedMessage id={"table.columns.actions"} />,
            type: 'actions',
            sortable: false, filterable: false,
            getActions: () => [
                ...(admin.category_edit_perm === 1 ? [{
                    label: <FormattedMessage id={"move_to_up"} />,
                    action: (row) => {
                        changePositions(row, row.position, row.position - 1);
                    }
                },
                {
                    label: <FormattedMessage id={"move_to_down"} />,
                    action: (row) => {
                        changePositions(row, row.position, row.position + 1);
                    }
                },
                {
                    label: <FormattedMessage id={"edit"} />,
                    action: (row) => {
                        fetchCategoryToUpdate(row);
                    }
                }] : []),
                ...(admin.category_deletion_perm === 1 ? [{
                    label: <FormattedMessage id={"delete"} />,
                    action: (row) => {
                        setCategoryEvent({ category: row, type: "delete" })
                    }
                }] : [])
            ]
        }] : [])
    ];

    const fetchCategories = (paginationModel, orderModel, filterModel, token) => {
        setLoading(true);
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/getCategories`,
            {
                startIndex: paginationModel.page * paginationModel.rowsPerPage,
                limit: paginationModel.rowsPerPage,
                orderBy: orderModel ? orderModel.orderBy : null,
                orderType: orderModel ? orderModel.orderType : null,
                filter: filterModel ? filterModel : null
            },
            { headers: { token: token } }
        )
            .catch((err) => {
                setCategories([]);
                setLoading(false);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setCategories(response.data.categories);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                    setMaxPositions(response.data.maxPositions !== undefined ? response.data.maxPositions : { forAdvertisements: 0, forServiceProviders: 0 });
                }
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchCategories(paginationModel, orderModel, filterModel, token);
    }, [paginationModel, orderModel, filterModel, token]);

    useEffect(() => {
        if ((categoryEvent?.category && categoryEvent?.type === "edit") || (!categoryEvent?.category && categoryEvent?.type === "add")) {
            setEditDialogOpen(true);
        }
        else if (categoryEvent?.category && categoryEvent?.type === "delete") {
            setRemoveDialogOpen(true);
        }
    }, [categoryEvent]);

    const fetchCategoryToUpdate = (category) => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getCategoryToUpdate?categoryId=${category.id}`, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setCategoryEvent({
                        category: {
                            ...response.data.category,
                            position: category.position,
                            advertisement_count: category.advertisement_count,
                            subcategory_count: category.subcategory_count,
                            service_provider_count: category.service_provider_count
                        }, type: "edit"
                    });
                }
            });
    }

    const addCategory = async (category) => {
        if (category.content) {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadCategoryContent`, {
                categoryContent: category.content
            }, {
                headers: {
                    token: token,
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        category.content_path = response.data.contentPath;
                    }
                });
        }
        await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/createCategoryWithTranslations`, {
            ...category
        }, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setCategoryEvent({ category: null, type: null });
                    setEditDialogOpen(false);
                    fetchCategories(paginationModel, orderModel, filterModel, token);
                    handleSnackbarOpen(<FormattedMessage id={"category_added_successfully"} />);
                }
            });
    }

    const updateCategory = async (category) => {
        var categoryIx = categories.findIndex(c => c.id === categoryEvent.category.id);
        var contentLink;
        if (category.content) {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadCategoryContent`, {
                categoryContent: category.content
            }, {
                headers: {
                    token: token,
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        category.content_path = response.data.contentPath;
                        contentLink = category.content_link.slice(0, category.content_link.indexOf("/images") + 1) + response.data.contentPath;
                    }
                });
        }

        var categoryToUpdate = { ...category };
        delete categoryToUpdate.content;
        delete categoryToUpdate.content_link;
        delete categoryToUpdate.position;
        delete categoryToUpdate.advertisement_count;
        delete categoryToUpdate.subcategory_count;
        delete categoryToUpdate.service_provider_count;

        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateCategoryWithTranslations?categoryId=${categoryEvent.category.id}`, {
            category: categoryToUpdate
        }, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    categories[categoryIx] = categoryToUpdate;
                    categories[categoryIx].id = categoryEvent.category.id;
                    categories[categoryIx].position = categoryEvent.category.position;
                    categories[categoryIx].advertisement_count = categoryEvent.category.advertisement_count;
                    categories[categoryIx].subcategory_count = categoryEvent.category.subcategory_count;
                    categories[categoryIx].service_provider_count = categoryEvent.category.service_provider_count;
                    categories[categoryIx].for_advertisements = categoryEvent.category.for_advertisements;
                    if (contentLink) {
                        categories[categoryIx].content_link = contentLink;
                    } else {
                        categories[categoryIx].content_link = categoryEvent.category.content_link;
                    }
                    setCategories(Array.from(categories));
                    setCategoryEvent({ category: null, type: null });
                    setEditDialogOpen(false);
                    handleSnackbarOpen(<FormattedMessage id={"category_updated_successfully"} />);
                }
            });
    }

    const changePositions = (category, currentPosition, newPosition) => {
        const maxPosition = category.for_advertisements ? maxPositions.forAdvertisements : maxPositions.forServiceProviders;
        if (currentPosition === 1 && newPosition === 0) {
            handleSnackbarOpen(<FormattedMessage id={"already_top"} />);
            return;
        }
        if (currentPosition === maxPosition && newPosition === maxPosition + 1) {
            handleSnackbarOpen(<FormattedMessage id={"already_bottom"} />);
            return;
        }
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/changeCategoryPosition?categoryId=${category.id}&currentPosition=${currentPosition}&newPosition=${newPosition}`, {}, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleSnackbarOpen(<FormattedMessage id={"position_changed_successfully"} />);
                    fetchCategories(paginationModel, orderModel, filterModel, token);
                }
            });
    }

    const handleCategoryFormSubmit = async (category) => {
        if (categoryEvent.type === "add") {
            addCategory(category);
        } else if (categoryEvent.type === "edit") {
            updateCategory(category);
        }
    }

    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const handleRemoveDialogClose = () => {
        setRemoveDialogOpen(false);
    }
    const clickPositiveAnswer = () => {
        removeCategory(categoryEvent.category);
    }
    const clickNegativeAnswer = () => {
    }

    const removeCategory = (category) => {
        axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteCategory?categoryId=${category.id}`, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
                setCategoryEvent({ category: null, type: null })
                setRemoveDialogOpen(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = categories.findIndex(({ id }) => id === category.id);
                    var newCategories = Array.from(categories);
                    if (ix >= 0) {
                        newCategories.splice(ix, 1);
                        const maxPosition = category.for_advertisements ? maxPositions.forAdvertisements : maxPositions.forServiceProviders;
                        if (categories[ix].position !== maxPosition) {
                            for (var i = ix; i < newCategories.length; i++) {
                                if (newCategories[i].for_advertisements === category.for_advertisements) {
                                    newCategories[i].position -= 1;
                                }
                            }
                        }
                        setCategories(newCategories);
                    }
                    setCategoryEvent({ category: null, type: null });
                    setRemoveDialogOpen(false);
                    handleSnackbarOpen(<FormattedMessage id={"category_deleted_successfully"} />);
                }
            });
    }

    return (
        <Box sx={{ p: "75px" }}>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarState.open}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                message={snackbarState.message}
            />
            {
                categoryEvent.category && categoryEvent.type === "delete" &&
                <AlertDialogSlide
                    open={removeDialogOpen}
                    handleClose={(event, reason) => {
                        if (reason && reason === "backdropClick") {
                            return;
                        }
                        handleRemoveDialogClose();
                        setCategoryEvent({ category: null, type: null })
                    }}
                    clickPositiveAnswer={clickPositiveAnswer}
                    clickNegativeAnswer={clickNegativeAnswer}
                    positiveAnswer={<FormattedMessage id={"delete"} />}
                    negativeAnswer={<FormattedMessage id={"cancel"} />}
                    title={<FormattedMessage id={"are_you_sure_to_delete_category"} />}
                    description={<FormattedMessage id={"delete_category_description"} />}
                />
            }
            {
                ((categoryEvent.category && categoryEvent.type === "edit") || (!categoryEvent.category && categoryEvent.type === "add")) &&
                <CategoryDialog
                    dialogOpen={editDialogOpen}
                    handleClose={(event, reason) => {
                        if (reason && reason === "backdropClick") {
                            return;
                        }
                        setEditDialogOpen(false);
                        setCategoryEvent({ category: null, type: null });
                    }}
                    category={categoryEvent.category}
                    handleFormSubmit={handleCategoryFormSubmit}
                />
            }
            <Box display="flex" alignItems="center" gap="30px">
                <Header
                    title={<FormattedMessage id='categories.header.title' />}
                    subtitle={<FormattedMessage id='categories.header.description' />}
                />
                {admin.category_edit_perm === 1 &&
                    <Button variant="contained" color="success" onClick={() => setCategoryEvent({ category: null, type: "add" })}>
                        <FormattedMessage id={"add_new_category"} />
                    </Button>
                }
            </Box>
            <Table
                table_id={"categories_table"}
                isLoading={isLoading}
                columns={columns}
                rows={categories}
                rowCount={rowCount}
                onOrderChange={(order) => {
                    setOrderModel(order);
                }}
                onPaginationChange={(pagination) => {
                    setPaginationModel(pagination);
                }}
                onFilterChange={(filter) => {
                    setFilterModel(filter);
                }}
                onRowClick={(row, column) => {
                    if (column.id !== "actions") {
                        if (!row.for_advertisements) {
                            navigate(`/subcategories/${row.id}`);
                        }
                    }
                }}
            />

        </Box>
    )
}

export default CategoriesPage
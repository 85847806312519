import { Route, Routes } from "react-router-dom";
import Sidebar from "./global/Sidebar";
import { useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useContext, useState } from "react";
import { AdminContext } from "./context/AdminContext";
import LoginPage from "./pages/LoginPage";
import UsersPage from "./pages/UsersPage";
import Topbar from "./global/Topbar";
import AdminsPage from "./pages/AdminsPage";
import UserDetailsPage from "./pages/UsersPage/UserDeatilsPage";
import ReportsPage from "./pages/ReportsPage";
import ContactMessagesPage from "./pages/ContactMessagesPage";
import CategoriesPage from "./pages/CategoriesPage";
import SubcategoriesPage from "./pages/CategoriesPage/SubcategoriesPage";
import FaqPage from "./pages/FaqPage";
import { IntlProvider } from 'react-intl';
import English from './lang/en.json';
import Deutch from './lang/de.json';
import NotificationsPage from "./pages/NotificationsPage";
import NotificationDraftsPage from "./pages/NotificationsPage/NotificationDraftsPage";
import OnboardingsPage from "./pages/OnboardingsPage";
import AdvertisementsPage from "./pages/AdvertisementsPage";
import ForbiddenWordsPage from "./pages/ForbiddenWordsPage";
import DashboardPage from "./pages/DashboardPage";
import CommercialsPage from "./pages/CommercialsPage";

function App() {
  const { admin, token, isLoading } = useContext(AdminContext);
  const theme = useMode();
  return (
    <IntlProvider locale={admin?.language ? admin.language : 'en'} messages={admin?.language === 'de' ? Deutch : English}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {(admin && token && !isLoading) &&
          <>
            <Topbar />
            <div className="app">
              <Sidebar />
              <main className="content" style={{
                width: `100%`,
              }}>
                <Routes>
                  {admin.dashboard_perm === 1 && <Route exact path="/" element={<DashboardPage />} />}
                  {admin.dashboard_perm === 1 && <Route exact path="/dashboard" element={<DashboardPage />} />}
                  {admin.users_perm === 1 && <Route exact path="/users" element={<UsersPage />} />}
                  {admin.users_perm === 1 && <Route exact path="/users/:userId" element={<UserDetailsPage />} />}
                  {admin.categories_perm === 1 && <Route exact path="/categories" element={<CategoriesPage />} />}
                  {admin.categories_perm === 1 && <Route exact path="/subcategories/:categoryId" element={<SubcategoriesPage />} />}
                  {admin.advertisements_perm === 1 && <Route exact path="/advertisements" element={<AdvertisementsPage />} />}
                  {admin.onboardings_perm === 1 && <Route exact path="/onboardings" element={<OnboardingsPage />} />}
                  {admin.notifications_perm === 1 && <Route exact path="/notifications" element={<NotificationsPage />} />}
                  {admin.notification_drafts_perm === 1 && <Route exact path="/notifications/drafts" element={<NotificationDraftsPage />} />}
                  {admin.reports_perm === 1 && <Route exact path="/reports" element={<ReportsPage />} />}
                  {admin.contact_messages_perm === 1 && <Route exact path="/contactMessages" element={<ContactMessagesPage />} />}
                  {admin.faqs_perm === 1 && <Route exact path="/faqs" element={<FaqPage />} />}
                  {admin.forbidden_words_perm === 1 && <Route exact path="/forbiddenWords" element={<ForbiddenWordsPage />} />}
                  {admin.commercials_perm === 1 && <Route exact path="/commercials" element={<CommercialsPage />} />}
                  {admin.admins_perm === 1 && <Route exact path="/admins" element={<AdminsPage />} />}
                </Routes>
              </main>
            </div>
          </>
        }
        {
          !(admin || (token && token !== "null")) && !isLoading &&
          <div className="app">
            <main style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}>
              <LoginPage />
            </main>
          </div>
        }
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;

import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

function ServiceProviderPromotionalTextEditDialog({ settings, dialogOpen, handleClose, handleFormSubmit }) {

    const { formatMessage } = useIntl();

    var initialValues = {
        service_provider_promotional_text_title_1_en: settings?.service_provider_promotional_text_title_1_en || "",
        service_provider_promotional_text_description_1_en: settings?.service_provider_promotional_text_description_1_en || "",
        service_provider_promotional_text_title_2_en: settings?.service_provider_promotional_text_title_2_en || "",
        service_provider_promotional_text_description_2_en: settings?.service_provider_promotional_text_description_2_en || "",
        service_provider_promotional_text_title_3_en: settings?.service_provider_promotional_text_title_3_en || "",
        service_provider_promotional_text_checkbox_1_en: settings?.service_provider_promotional_text_checkbox_1_en || "",
        service_provider_promotional_text_checkbox_2_en: settings?.service_provider_promotional_text_checkbox_2_en || "",
        service_provider_promotional_text_checkbox_3_en: settings?.service_provider_promotional_text_checkbox_3_en || "",
        service_provider_promotional_text_checkbox_4_en: settings?.service_provider_promotional_text_checkbox_4_en || "",
        service_provider_promotional_text_title_1_de: settings?.service_provider_promotional_text_title_1_de || "",
        service_provider_promotional_text_description_1_de: settings?.service_provider_promotional_text_description_1_de || "",
        service_provider_promotional_text_title_2_de: settings?.service_provider_promotional_text_title_2_de || "",
        service_provider_promotional_text_description_2_de: settings?.service_provider_promotional_text_description_2_de || "",
        service_provider_promotional_text_title_3_de: settings?.service_provider_promotional_text_title_3_de || "",
        service_provider_promotional_text_checkbox_1_de: settings?.service_provider_promotional_text_checkbox_1_de || "",
        service_provider_promotional_text_checkbox_2_de: settings?.service_provider_promotional_text_checkbox_2_de || "",
        service_provider_promotional_text_checkbox_3_de: settings?.service_provider_promotional_text_checkbox_3_de || "",
        service_provider_promotional_text_checkbox_4_de: settings?.service_provider_promotional_text_checkbox_4_de || "",
    };

    var validationObject = {
        service_provider_promotional_text_title_1_en: yup.string().required(formatMessage({ id: "required" })),
        service_provider_promotional_text_description_1_en: yup.string().required(formatMessage({ id: "required" })),
        service_provider_promotional_text_title_2_en: yup.string().required(formatMessage({ id: "required" })),
        service_provider_promotional_text_description_2_en: yup.string().required(formatMessage({ id: "required" })),
        service_provider_promotional_text_title_3_en: yup.string().required(formatMessage({ id: "required" })),
        service_provider_promotional_text_checkbox_1_en: yup.string().required(formatMessage({ id: "required" })),
        service_provider_promotional_text_checkbox_2_en: yup.string().required(formatMessage({ id: "required" })),
        service_provider_promotional_text_checkbox_3_en: yup.string().required(formatMessage({ id: "required" })),
        service_provider_promotional_text_checkbox_4_en: yup.string().required(formatMessage({ id: "required" })),
        service_provider_promotional_text_title_1_de: yup.string().required(formatMessage({ id: "required" })),
        service_provider_promotional_text_description_1_de: yup.string().required(formatMessage({ id: "required" })),
        service_provider_promotional_text_title_2_de: yup.string().required(formatMessage({ id: "required" })),
        service_provider_promotional_text_description_2_de: yup.string().required(formatMessage({ id: "required" })),
        service_provider_promotional_text_title_3_de: yup.string().required(formatMessage({ id: "required" })),
        service_provider_promotional_text_checkbox_1_de: yup.string().required(formatMessage({ id: "required" })),
        service_provider_promotional_text_checkbox_2_de: yup.string().required(formatMessage({ id: "required" })),
        service_provider_promotional_text_checkbox_3_de: yup.string().required(formatMessage({ id: "required" })),
        service_provider_promotional_text_checkbox_4_de: yup.string().required(formatMessage({ id: "required" }))
    }

    const settingsSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            open={dialogOpen}
            fullWidth
            maxWidth="md"
            onClose={(e, reason) => {
                if (reason && reason === "backdropClick") {
                    return;
                }
                handleClose();
            }}>
            <Box m="20px" textAlign="center">
                <Typography variant='h3'>
                    <FormattedMessage id={"edit_service_provider_promotional_text"} />
                </Typography>

                <Button
                    variant="standard"
                    color="error"
                    sx={{
                        borderRadius: "100%",
                        backgroundColor: "white",
                        maxWidth: "40px",
                        maxHeight: "40px",
                        minWidth: "40px",
                        minHeight: "40px",
                        position: "absolute",
                        top: "10px",
                        right: "10px"
                    }}
                    onClick={handleClose}>
                    <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                </Button>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={settingsSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="20px" gridTemplateColumns="repeat(1, minmax(0, 1fr))" mx="75px" my="25px">

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "title_with_number_and_language_code" }, { number: 1, language: "en" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="service_provider_promotional_text_title_1_en"
                                        value={values.service_provider_promotional_text_title_1_en}
                                        error={!!touched.service_provider_promotional_text_title_1_en && !!errors.service_provider_promotional_text_title_1_en}
                                        helperText={touched.service_provider_promotional_text_title_1_en && errors.service_provider_promotional_text_title_1_en}
                                        sx={{ gridColumn: "span 1", }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "title_with_number_and_language_code" }, { number: 1, language: "de" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="service_provider_promotional_text_title_1_de"
                                        value={values.service_provider_promotional_text_title_1_de}
                                        error={!!touched.service_provider_promotional_text_title_1_de && !!errors.service_provider_promotional_text_title_1_de}
                                        helperText={touched.service_provider_promotional_text_title_1_de && errors.service_provider_promotional_text_title_1_de}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "description_with_number_and_language_code" }, { number: 1, language: "en" })}
                                        multiline
                                        minRows={3}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="service_provider_promotional_text_description_1_en"
                                        value={values.service_provider_promotional_text_description_1_en}
                                        error={!!touched.service_provider_promotional_text_description_1_en && !!errors.service_provider_promotional_text_description_1_en}
                                        helperText={touched.service_provider_promotional_text_description_1_en && errors.service_provider_promotional_text_description_1_en}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "description_with_number_and_language_code" }, { number: 1, language: "de" })}
                                        multiline
                                        minRows={3}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="service_provider_promotional_text_description_1_de"
                                        value={values.service_provider_promotional_text_description_1_de}
                                        error={!!touched.service_provider_promotional_text_description_1_de && !!errors.service_provider_promotional_text_description_1_de}
                                        helperText={touched.service_provider_promotional_text_description_1_de && errors.service_provider_promotional_text_description_1_de}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "title_with_number_and_language_code" }, { number: 2, language: "en" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="service_provider_promotional_text_title_2_en"
                                        value={values.service_provider_promotional_text_title_2_en}
                                        error={!!touched.service_provider_promotional_text_title_2_en && !!errors.service_provider_promotional_text_title_2_en}
                                        helperText={touched.service_provider_promotional_text_title_2_en && errors.service_provider_promotional_text_title_2_en}
                                        sx={{ gridColumn: "span 1", }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "title_with_number_and_language_code" }, { number: 2, language: "de" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="service_provider_promotional_text_title_2_de"
                                        value={values.service_provider_promotional_text_title_2_de}
                                        error={!!touched.service_provider_promotional_text_title_2_de && !!errors.service_provider_promotional_text_title_2_de}
                                        helperText={touched.service_provider_promotional_text_title_2_de && errors.service_provider_promotional_text_title_2_de}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "description_with_number_and_language_code" }, { number: 2, language: "en" })}
                                        multiline
                                        minRows={3}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="service_provider_promotional_text_description_2_en"
                                        value={values.service_provider_promotional_text_description_2_en}
                                        error={!!touched.service_provider_promotional_text_description_2_en && !!errors.service_provider_promotional_text_description_2_en}
                                        helperText={touched.service_provider_promotional_text_description_2_en && errors.service_provider_promotional_text_description_2_en}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "description_with_number_and_language_code" }, { number: 2, language: "de" })}
                                        multiline
                                        minRows={3}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="service_provider_promotional_text_description_2_de"
                                        value={values.service_provider_promotional_text_description_2_de}
                                        error={!!touched.service_provider_promotional_text_description_2_de && !!errors.service_provider_promotional_text_description_2_de}
                                        helperText={touched.service_provider_promotional_text_description_2_de && errors.service_provider_promotional_text_description_2_de}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "title_with_number_and_language_code" }, { number: 3, language: "en" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="service_provider_promotional_text_title_3_en"
                                        value={values.service_provider_promotional_text_title_3_en}
                                        error={!!touched.service_provider_promotional_text_title_3_en && !!errors.service_provider_promotional_text_title_3_en}
                                        helperText={touched.service_provider_promotional_text_title_3_en && errors.service_provider_promotional_text_title_3_en}
                                        sx={{ gridColumn: "span 1", }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "title_with_number_and_language_code" }, { number: 3, language: "de" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="service_provider_promotional_text_title_3_de"
                                        value={values.service_provider_promotional_text_title_3_de}
                                        error={!!touched.service_provider_promotional_text_title_3_de && !!errors.service_provider_promotional_text_title_3_de}
                                        helperText={touched.service_provider_promotional_text_title_3_de && errors.service_provider_promotional_text_title_3_de}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "checkbox_with_number_and_language_code" }, { number: 1, language: "en" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="service_provider_promotional_text_checkbox_1_en"
                                        value={values.service_provider_promotional_text_checkbox_1_en}
                                        error={!!touched.service_provider_promotional_text_checkbox_1_en && !!errors.service_provider_promotional_text_checkbox_1_en}
                                        helperText={touched.service_provider_promotional_text_checkbox_1_en && errors.service_provider_promotional_text_checkbox_1_en}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "checkbox_with_number_and_language_code" }, { number: 1, language: "de" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="service_provider_promotional_text_checkbox_1_de"
                                        value={values.service_provider_promotional_text_checkbox_1_de}
                                        error={!!touched.service_provider_promotional_text_checkbox_1_de && !!errors.service_provider_promotional_text_checkbox_1_de}
                                        helperText={touched.service_provider_promotional_text_checkbox_1_de && errors.service_provider_promotional_text_checkbox_1_de}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "checkbox_with_number_and_language_code" }, { number: 2, language: "en" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="service_provider_promotional_text_checkbox_2_en"
                                        value={values.service_provider_promotional_text_checkbox_2_en}
                                        error={!!touched.service_provider_promotional_text_checkbox_2_en && !!errors.service_provider_promotional_text_checkbox_2_en}
                                        helperText={touched.service_provider_promotional_text_checkbox_2_en && errors.service_provider_promotional_text_checkbox_2_en}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "checkbox_with_number_and_language_code" }, { number: 2, language: "de" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="service_provider_promotional_text_checkbox_2_de"
                                        value={values.service_provider_promotional_text_checkbox_2_de}
                                        error={!!touched.service_provider_promotional_text_checkbox_2_de && !!errors.service_provider_promotional_text_checkbox_2_de}
                                        helperText={touched.service_provider_promotional_text_checkbox_2_de && errors.service_provider_promotional_text_checkbox_2_de}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "checkbox_with_number_and_language_code" }, { number: 3, language: "en" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="service_provider_promotional_text_checkbox_3_en"
                                        value={values.service_provider_promotional_text_checkbox_3_en}
                                        error={!!touched.service_provider_promotional_text_checkbox_3_en && !!errors.service_provider_promotional_text_checkbox_3_en}
                                        helperText={touched.service_provider_promotional_text_checkbox_3_en && errors.service_provider_promotional_text_checkbox_3_en}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "checkbox_with_number_and_language_code" }, { number: 3, language: "de" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="service_provider_promotional_text_checkbox_3_de"
                                        value={values.service_provider_promotional_text_checkbox_3_de}
                                        error={!!touched.service_provider_promotional_text_checkbox_3_de && !!errors.service_provider_promotional_text_checkbox_3_de}
                                        helperText={touched.service_provider_promotional_text_checkbox_3_de && errors.service_provider_promotional_text_checkbox_3_de}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "checkbox_with_number_and_language_code" }, { number: 4, language: "en" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="service_provider_promotional_text_checkbox_4_en"
                                        value={values.service_provider_promotional_text_checkbox_4_en}
                                        error={!!touched.service_provider_promotional_text_checkbox_4_en && !!errors.service_provider_promotional_text_checkbox_4_en}
                                        helperText={touched.service_provider_promotional_text_checkbox_4_en && errors.service_provider_promotional_text_checkbox_4_en}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "checkbox_with_number_and_language_code" }, { number: 4, language: "de" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="service_provider_promotional_text_checkbox_4_de"
                                        value={values.service_provider_promotional_text_checkbox_4_de}
                                        error={!!touched.service_provider_promotional_text_checkbox_4_de && !!errors.service_provider_promotional_text_checkbox_4_de}
                                        helperText={touched.service_provider_promotional_text_checkbox_4_de && errors.service_provider_promotional_text_checkbox_4_de}
                                        sx={{ gridColumn: "span 1", }}
                                    />

                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px" gap={"10px"}>
                                    <Button type="button" color="warning" variant="contained" onClick={() => {
                                        resetForm();
                                    }}>
                                        <FormattedMessage id="reset" />
                                    </Button>
                                    <Button type="button" color="error" variant="contained" onClick={handleClose}>
                                        <FormattedMessage id="cancel" />

                                    </Button>
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <FormattedMessage id="save" />
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default ServiceProviderPromotionalTextEditDialog
import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { tokens } from '../../../theme';
import { FormattedMessage } from 'react-intl';

function CategoryDialog({ category, dialogOpen, handleClose, handleFormSubmit }) {

    const colors = tokens();

    var initialValues = {
        name: category?.name ? category?.name : "",
        ...(
            category ?
                {}
                :
                {
                    for_advertisements: category?.for_advertisements ? 1 : 0
                }
        ),
        content: null,
        content_link: category?.content_link ? category?.content_link : "",
        translations: category?.translations && category?.translations.length > 0 ? category?.translations : [
            { language: "de", translation: "" }
        ],
        keywords_for_suggestion: category?.keywords_for_suggestion ? category?.keywords_for_suggestion : ""
    };

    var validationObject = {
        name: yup.string().required(<FormattedMessage id="required" />),
        ...(
            category ?
                {}
                :
                {
                    for_advertisements: yup.boolean().required(<FormattedMessage id="required" />)
                }
        ),
        content: yup.mixed().nullable().when('content_link', {
            is: (content_link) => (!content_link || content_link === ""),
            then: () => yup.string().nonNullable("").required(<FormattedMessage id="required" />)
        }),
        content_link: yup.string(),
        translations: yup.array().of(yup.object().shape(
            {
                language: yup.string().required(<FormattedMessage id="required" />),
                translation: yup.string().required(<FormattedMessage id="required" />),
            }
        ).required(<FormattedMessage id="required" />)).required(<FormattedMessage id="required" />),
        keywords_for_suggestion: yup.string()
    }

    const categorySchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    {
                        category ?
                            <FormattedMessage id="edit_category" />
                            :
                            <FormattedMessage id="add_new_category" />
                    }
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={categorySchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box textAlign="center">
                                    <Typography variant="h6">
                                        <FormattedMessage id="category_image" />
                                    </Typography>
                                    <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[900], height: "150px", width: "150px" }}>
                                        {(values.content || (values.content_link && values.content_link !== "")) && <img alt='category' width="100px" src={values.content ? URL.createObjectURL(values.content) : values.content_link} />}
                                        <input id="content" name="content" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("content", event.target.files[0]); }} hidden />
                                    </Button>
                                </Box>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="75px" my="25px">

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={<FormattedMessage id="category_name_with_language_code" values={{ language: "en" }} />}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"name"}
                                        value={values.name}
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                        sx={{ gridColumn: "span 1", my: "20px" }}
                                    />
                                    {values.translations.map((translation, i) =>

                                        <TextField
                                            key={`translation-${i}`}
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label={<FormattedMessage id="category_name_with_language_code" values={{ language: translation.language }} />}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"translations[" + i + "].translation"}
                                            value={values.translations[i].translation}
                                            error={!!touched?.translations && !!touched?.translations[i] && !!touched?.translations[i]?.translation && !!errors?.translations && !!errors?.translations[i] && !!errors?.translations[i]?.translation}
                                            helperText={touched?.translations && touched?.translations[i] && touched?.translations[i]?.translation && errors?.translations && errors?.translations[i] && errors?.translations[i]?.translation}
                                            sx={{ gridColumn: "span 1", my: "20px" }}
                                        />
                                    )}

                                    {
                                        category ?
                                            ""
                                            :
                                            <FormControl
                                                fullWidth
                                                variant="filled"
                                                sx={{ gridColumn: "span 2" }}>
                                                <InputLabel id="filter-label1">
                                                    <FormattedMessage id="type" />
                                                </InputLabel>
                                                <Select
                                                    labelId="filter-label1"
                                                    label={<FormattedMessage id="type" />}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    name={"for_advertisements"}
                                                    value={values.for_advertisements}
                                                    error={!!touched.for_advertisements && !!errors.for_advertisements}
                                                >
                                                    <MenuItem value={0}>
                                                        <FormattedMessage id="categories.type.option.service_provider" />
                                                    </MenuItem>
                                                    <MenuItem value={1}>
                                                        <FormattedMessage id="categories.type.option.advertisement" />
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                    }

                                    {!!((category && category.for_advertisements) || values.for_advertisements) &&
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            multiline
                                            minRows={4}
                                            label={<FormattedMessage id="keywords_for_suggestion" />}
                                            //wrap label to next line
                                            InputLabelProps={values.keywords_for_suggestion && values.keywords_for_suggestion !== "" ? {} : { style: { whiteSpace: "pre-line" } }}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"keywords_for_suggestion"}
                                            value={values.keywords_for_suggestion}
                                            error={!!touched.keywords_for_suggestion && !!errors.keywords_for_suggestion}
                                            helperText={touched.keywords_for_suggestion && errors.keywords_for_suggestion}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                    }
                                </Box>

                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <Typography variant='h5'>
                                            <FormattedMessage id="save" />
                                        </Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default CategoryDialog
import { Box, Button, FormControl, InputLabel, Menu, MenuItem, Select, TextField, Typography } from '@mui/material'
import { AdminContext } from '../../context/AdminContext';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useContext, useState } from 'react'
import axios from 'axios';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function DashboardBox({ icon, title, endPoint, filterable, filterColumns, defaultFilters }) {
    const { admin, token } = useContext(AdminContext);
    const { formatMessage } = useIntl();
    const [count, setCount] = useState(0);

    const columnsForFilter = [];

    for (let i = 0; i < filterColumns.length; i++) {
        if (filterColumns[i].type === "number" || filterColumns[i].type === "date") {
            columnsForFilter.push({
                id: filterColumns[i].id,
                value: filterColumns[i].type === "date" ? null : "",
                type: filterColumns[i].type,
                min: true,
                max: false
            });
            columnsForFilter.push({
                id: filterColumns[i].id,
                value: filterColumns[i].type === "date" ? null : "",
                type: filterColumns[i].type,
                min: false,
                max: true
            });
        } else if (filterColumns[i].type === "boolean" || filterColumns[i].type === "select") {
            columnsForFilter.push({
                id: filterColumns[i].id,
                value: "",
                type: filterColumns[i].type
            })
        } else {
            columnsForFilter.push({
                id: filterColumns[i].id,
                value: "",
                type: filterColumns[i].type
            })
        }
    }

    for (let i = 0; i < defaultFilters?.length; i++) {
        if (defaultFilters[i].type === "number" || defaultFilters[i].type === "date") {
            columnsForFilter.push({
                id: defaultFilters[i].id,
                value: defaultFilters[i].value,
                type: defaultFilters[i].type,
                min: defaultFilters[i].min,
                max: defaultFilters[i].max
            });
        } else if (defaultFilters[i].type === "boolean" || defaultFilters[i].type === "select") {
            columnsForFilter.push({
                id: defaultFilters[i].id,
                value: defaultFilters[i].value,
                type: defaultFilters[i].type
            })
        } else {
            columnsForFilter.push({
                id: defaultFilters[i].id,
                value: defaultFilters[i].value,
                type: defaultFilters[i].type
            })
        }
    }

    const [filter, setFilter] = useState(columnsForFilter);

    const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState(null);
    const filterMenuOpen = Boolean(filterMenuAnchorEl);
    const handleFilterButtonClick = (event) => {
        setFilterMenuAnchorEl(event.currentTarget);
    };
    const handleFilterMenuClose = (reason) => {
        if (!reason) {
            setFilterMenuAnchorEl(null);
        }
    };

    const fetchCount = (filter) => {
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/${endPoint}`, {
            filter: filter
        }, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
                setCount(0);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setCount(response.data.count);
                }
            });
    };

    useState(() => {
        fetchCount(filter);
    }, []);

    return (
        <Box display="flex" flexDirection="column" alignItems="end">
            {(filterable && filterColumns?.length > 0) &&
                <Menu
                    anchorEl={filterMenuAnchorEl}
                    open={filterMenuOpen}
                    onClose={(reason) => handleFilterMenuClose(reason)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "10px 10px 10px 20px",
                            height: "75px",
                            backgroundColor: "#fff",
                        }}
                    >
                        <h3><FormattedMessage id="table.filter" /></h3>
                        <Box>
                            <Button
                                variant="standard"
                                color="error"
                                sx={{
                                    borderRadius: "100%"
                                }}
                                size='small'
                                onClick={
                                    () => {
                                        fetchCount(filter);
                                        handleFilterMenuClose();
                                    }
                                }
                            >
                                <h4 style={{ color: "green" }}>✔</h4>
                            </Button>
                            <Button
                                variant="standard"
                                color="error"
                                sx={{
                                    borderRadius: "100%"
                                }}
                                size='small'
                                onClick={
                                    () => {
                                        let newFilter = Array.from(columnsForFilter);
                                        setFilter(newFilter);
                                        fetchCount(newFilter);
                                        handleFilterMenuClose();
                                    }
                                }
                            >
                                <h4 style={{ color: "red" }}>✘</h4>
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        //scrolable
                        sx={{
                            maxHeight: "60vh",
                            overflow: "auto",
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            padding: "10px",
                            backgroundColor: "#fff",
                        }}
                    >
                        {filterColumns.map((column, index) => (

                            <Box key={index}
                                sx={{
                                    mt: "10px",
                                    px: "10px",
                                }}
                            >
                                {
                                    (column.type === 'text' || !column.type) &&
                                    <TextField
                                        label={column.name}
                                        fullWidth
                                        value={filter.find(f => f.id === column.id).value}
                                        type={column.type === 'number' ? 'number' : 'text'}
                                        onChange={(e) => {
                                            setFilter(prev => {
                                                var newFilter = [...prev];
                                                var filterIndex = newFilter.findIndex(f => f.id === column.id);
                                                newFilter[filterIndex] = {
                                                    id: column.id,
                                                    value: e.target.value,
                                                    type: "text"
                                                }
                                                return newFilter;
                                            });
                                        }}
                                    />
                                }
                                {
                                    column.type === "boolean" &&
                                    <FormControl fullWidth>
                                        <InputLabel id={`${column.id}-label`}>{column.name}</InputLabel>
                                        <Select
                                            label={column.name}
                                            value={filter.find(f => f.id === column.id).value}
                                            fullWidth
                                            onChange={(e) => {
                                                setFilter(prev => {
                                                    var newFilter = [...prev];
                                                    var filterIndex = newFilter.findIndex(f => f.id === column.id);
                                                    newFilter[filterIndex] = {
                                                        id: column.id,
                                                        value: e.target.value,
                                                        type: "boolean"
                                                    }
                                                    return newFilter;
                                                });
                                            }}
                                        >
                                            <MenuItem value={""}>
                                                <FormattedMessage id="all" />
                                            </MenuItem>
                                            <MenuItem value={1}>
                                                <FormattedMessage id="yes" />
                                            </MenuItem>
                                            <MenuItem value={0}>
                                                <FormattedMessage id="no" />
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                }
                                {
                                    column.type === "select" &&
                                    <FormControl fullWidth>
                                        <InputLabel id={`${column.id}-label`}>{column.name}</InputLabel>
                                        <Select
                                            labelId={`${column.id}-label`}
                                            label={column.name}
                                            value={filter.find(f => f.id === column.id).value}
                                            fullWidth
                                            onChange={(e) => {
                                                setFilter(prev => {
                                                    var newFilter = [...prev];
                                                    var filterIndex = newFilter.findIndex(f => f.id === column.id);
                                                    newFilter[filterIndex] = {
                                                        id: column.id,
                                                        value: e.target.value,
                                                        type: "select"
                                                    }
                                                    console.log(newFilter);
                                                    return newFilter;
                                                });
                                            }}
                                        >
                                            <MenuItem value={""}>
                                                <FormattedMessage id="all" />
                                            </MenuItem>
                                            {column.options.map((option, index) => (
                                                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                }
                                {
                                    column.type === "multiple-select" &&
                                    <FormControl fullWidth>
                                        <InputLabel id={`${column.id}-label`}>{column.name}</InputLabel>
                                        <Select
                                            labelId={`${column.id}-label`}
                                            label={column.name}
                                            value={filter.find(f => f.id === column.id).value?.length > 0 ? filter.find(f => f.id === column.id).value[0] : ""}
                                            fullWidth
                                            onChange={(e) => {
                                                setFilter(prev => {
                                                    var newFilter = [...prev];
                                                    var filterIndex = newFilter.findIndex(f => f.id === column.id);
                                                    newFilter[filterIndex] = {
                                                        id: column.id,
                                                        value: [e.target.value],
                                                        type: "select"
                                                    }
                                                    console.log(newFilter);
                                                    return newFilter;
                                                });
                                            }}
                                        >
                                            <MenuItem value={""}>
                                                <FormattedMessage id="all" />
                                            </MenuItem>
                                            {column.options.map((option, index) => (
                                                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                }
                                {
                                    column.type === "number" &&
                                    <Box
                                        sx={{
                                            my: "20px",
                                        }}
                                    >
                                        <TextField
                                            label={<>{column.name} {"("}<FormattedMessage id="table.min" />{")"}</>}
                                            fullWidth
                                            value={filter.find(f => f.id === column.id && f.min).value}
                                            type={'number'}
                                            onChange={(e) => {
                                                setFilter(prev => {
                                                    var newFilter = [...prev];
                                                    var filterIndex = newFilter.findIndex(f => f.id === column.id && f.min);
                                                    newFilter[filterIndex] = {
                                                        id: column.id,
                                                        value: e.target.value,
                                                        min: true,
                                                        type: "number"
                                                    }
                                                    return newFilter;
                                                });
                                            }}
                                        />

                                        <TextField
                                            label={<>{column.name} {"("}<FormattedMessage id="table.max" />{")"}</>}
                                            fullWidth
                                            value={filter.find(f => f.id === column.id && f.max).value}
                                            type={'number'}
                                            sx={{
                                                mt: "4px"
                                            }}
                                            onChange={(e) => {
                                                setFilter(prev => {
                                                    var newFilter = [...prev];
                                                    var filterIndex = newFilter.findIndex(f => f.id === column.id && f.max);
                                                    newFilter[filterIndex] = {
                                                        id: column.id,
                                                        value: e.target.value,
                                                        max: true,
                                                        type: "number"
                                                    }
                                                    return newFilter;
                                                });
                                            }}
                                        />

                                    </Box>
                                }
                                {
                                    column.type === "date" &&
                                    <Box
                                        sx={{
                                            my: "20px",
                                            display: "flex",
                                            gap: "10px",
                                            justifyContent: "space-between"
                                        }}
                                    >

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label={<>{column.name} {"("}<FormattedMessage id="table.min" />{")"}</>}
                                                fullWidth
                                                value={filter.find(f => f.id === column.id && f.min).value}
                                                type="text"
                                                onChange={(date) => {
                                                    setFilter(prev => {
                                                        var newFilter = [...prev];
                                                        var filterIndex = newFilter.findIndex(f => f.id === column.id && f.min);
                                                        newFilter[filterIndex] = {
                                                            id: column.id,
                                                            value: date,
                                                            min: true,
                                                            type: "date"
                                                        }
                                                        return newFilter;
                                                    });
                                                }}
                                            />
                                        </LocalizationProvider>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label={<>{column.name} {"("}<FormattedMessage id="table.max" />{")"}</>}
                                                fullWidth
                                                value={filter.find(f => f.id === column.id && f.max).value}
                                                type="text"
                                                onChange={(date) => {
                                                    setFilter(prev => {
                                                        var newFilter = [...prev];
                                                        var filterIndex = newFilter.findIndex(f => f.id === column.id && f.max);
                                                        newFilter[filterIndex] = {
                                                            id: column.id,
                                                            value: date,
                                                            max: true,
                                                            type: "date"
                                                        }
                                                        return newFilter;
                                                    });
                                                }}
                                            />
                                        </LocalizationProvider>

                                    </Box>
                                }
                            </Box>
                        ))}
                    </Box>
                </Menu>}
            <Button
                variant="contained"
                color="success"
                sx={{
                    visibility: (filterable && filterColumns?.length > 0) ? "visible" : "hidden"
                }}
                onClick={handleFilterButtonClick}
            >
                <FormattedMessage id="table.filter" />
            </Button>
            <Box display="flex" gap="50px" p="30px" mt={"10px"} minWidth="250px" justifyContent="space-between"
                sx={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "20px",
                    boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                }}>
                {icon}
                <Box display="flex" flexDirection="column" alignItems="end">
                    <Typography variant="h3">
                        {count}
                    </Typography>
                    <Typography variant="h6" sx={{ fontStyle: "italic" }}>
                        {title}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default DashboardBox
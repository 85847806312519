import { Box, IconButton, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { tokens } from '../theme';
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from 'react-router-dom';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import 'react-pro-sidebar/dist/scss/styles.scss';
import { useContext } from 'react';
import { AdminContext } from '../context/AdminContext';
import { AdsClick, Category, Dashboard, Edit, FlagCircle, FormatClear, NotificationAdd, PushPin, PushPinOutlined, Quiz, ReceiptLong, ThreeP } from '@mui/icons-material';
import axios from 'axios';
import { useIntl } from 'react-intl';
import ChangeSidebarPositionsDialog from '../components/ChangeSidebarPositionsDialog';

const Item = ({ title, to, icon, selected, setSelected, isCollapsed, defaultSidebarCollapsed, setIsCollapsed }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem active={selected === title} style={{ color: colors.redAccent[100] }} onClick={() => setSelected(title)} icon={icon}>
            <Typography sx={{ userSelect: "none" }}>
                {title}
            </Typography>
            <Link to={to} onClick={() => { if (isCollapsed === false && defaultSidebarCollapsed) { setIsCollapsed(true); } }} />
        </MenuItem>
    )
}

const SidebarItems = ({ admin, sidebarItemPositions, selected, setSelected, isCollapsed, defaultSidebarCollapsed, setIsCollapsed }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { formatMessage } = useIntl();
    const items = {
        dashboard: admin.dashboard_perm === 1 && <Item key={formatMessage({ id: "dashboard.header.title" })} title={formatMessage({ id: "dashboard.header.title" })} to="/dashboard" icon={<Dashboard />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />,
        users: <Item key={formatMessage({ id: "users.header.title" })} title={formatMessage({ id: "users.header.title" })} to="/users" icon={<Groups2OutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />,
        categories: <Item key={formatMessage({ id: "categories.header.title" })} title={formatMessage({ id: "categories.header.title" })} to="/categories" icon={<Category />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />,
        advertisements: <Item key={formatMessage({ id: "advertisements.header.title" })} title={formatMessage({ id: "advertisements.header.title" })} to="/advertisements" icon={<ReceiptLong />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />,
        onboardings: <Item key={formatMessage({ id: "onboarding.header.title" })} title={formatMessage({ id: "onboarding.header.title" })} to="/onboardings" icon={<MovieCreationIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />,
        notifications: <Item key={formatMessage({ id: "notifications.header.title" })} title={formatMessage({ id: "notifications.header.title" })} to="/notifications" icon={<NotificationAdd />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />,
        reports: <Item key={formatMessage({ id: "reports.header.title" })} title={formatMessage({ id: "reports.header.title" })} to="/reports" icon={<FlagCircle />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />,
        contact_messages: <Item key={formatMessage({ id: "contact_messages.header.title" })} title={formatMessage({ id: "contact_messages.header.title" })} to="/contactMessages" icon={<ThreeP />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />,
        forbidden_words: <Item key={formatMessage({ id: "forbidden_words.header.title" })} title={formatMessage({ id: "forbidden_words.header.title" })} to="/forbiddenWords" icon={<FormatClear />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />,
        commercials: <Item key={formatMessage({ id: "commercials.header.title" })} title={formatMessage({ id: "commercials.header.title" })} to="/commercials" icon={<AdsClick />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />,
        faqs: <Item key={formatMessage({ id: "faqs.header.title" })} title={formatMessage({ id: "faqs.header.title" })} to="/faqs" icon={<Quiz />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />,
        admins: <Item key={formatMessage({ id: "admins.header.title" })} title={formatMessage({ id: "admins.header.title" })} to="/admins" icon={<AdminPanelSettingsIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />
    }


    /**
     {admin.dashboard_perm === 1 && <Item title={formatMessage({ id: "dashboard.header.title" })} to="/dashboard" icon={<Dashboard />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />}
                        {admin.users_perm === 1 && <Item title={formatMessage({ id: "users.header.title" })} to="/users" icon={<Groups2OutlinedIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />}
                        {admin.categories_perm === 1 && <Item title={formatMessage({ id: "categories.header.title" })} to="/categories" icon={<Category />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />}
                        {admin.advertisements_perm === 1 && <Item title={formatMessage({ id: "advertisements.header.title" })} to="/advertisements" icon={<ReceiptLong />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />}
                        {admin.onboardings_perm === 1 && <Item title={formatMessage({ id: "onboarding.header.title" })} to="/onboardings" icon={<MovieCreationIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />}
                        {admin.notifications_perm === 1 && <Item title={formatMessage({ id: "notifications.header.title" })} to="/notifications" icon={<NotificationAdd />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />}
                        {admin.reports_perm === 1 && <Item title={formatMessage({ id: "reports.header.title" })} to="/reports" icon={<FlagCircle />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />}
                        {admin.contact_messages_perm === 1 && <Item title={formatMessage({ id: "contact_messages.header.title" })} to="/contactMessages" icon={<ThreeP />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />}
                        {admin.forbidden_words_perm === 1 && <Item title={formatMessage({ id: "forbidden_words.header.title" })} to="/forbiddenWords" icon={<FormatClear />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />}
                        {admin.commercials_perm === 1 && <Item title={formatMessage({ id: "commercials.header.title" })} to="/commercials" icon={<AdsClick />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />}
                        {admin.faqs_perm === 1 && <Item title={formatMessage({ id: "faqs.header.title" })} to="/faqs" icon={<Quiz />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />}
                        {admin.admins_perm === 1 && <Item title={formatMessage({ id: "admins.header.title" })} to="/admins" icon={<AdminPanelSettingsIcon />} selected={selected} setSelected={setSelected} isCollapsed={isCollapsed} defaultSidebarCollapsed={defaultSidebarCollapsed} setIsCollapsed={setIsCollapsed} />}
     */
    return (
        <>
            {Object.keys(sidebarItemPositions).sort((a, b) => sidebarItemPositions[a] - sidebarItemPositions[b]).filter((key) => {
                return admin[`${key.split("_order")[0]}_perm`] === 1;
            }).map((key) => {
                return items[key.split("_order")[0]];
            })}
        </>
    )

}

function Sidebar() {
    const { admin, token, setAdmin } = useContext(AdminContext);
    const { formatMessage } = useIntl();
    const colors = tokens();
    const [defaultSidebarCollapsed, setDefaultSidebarCollapsed] = useState(admin.is_sidebar_collapsed === 1 ? true : false);
    const [isCollapsed, setIsCollapsed] = useState(admin.is_sidebar_collapsed === 1 ? true : false);
    const [selected, setSelected] = useState(formatMessage({ id: "dashboard.header.title" }));
    const [sidebarWidth, setSidebarWidth] = useState({
        width: `${admin.sidebar_width}px`,
        collapsedWidth: "75px"
    });

    const [sidebarItemPositions, setSidebarItemPositions] = useState({
        dashboard_order: admin.dashboard_order || 1,
        users_order: admin.users_order || 2,
        categories_order: admin.categories_order || 3,
        advertisements_order: admin.advertisements_order || 4,
        onboardings_order: admin.onboardings_order || 5,
        notifications_order: admin.notifications_order || 6,
        reports_order: admin.reports_order || 7,
        contact_messages_order: admin.contact_messages_order || 8,
        forbidden_words_order: admin.forbidden_words_order || 9,
        commercials_order: admin.commercials_order || 10,
        faqs_order: admin.faqs_order || 11,
        admins_order: admin.admins_order || 12
    });

    const [isResizing, setIsResizing] = useState(false);
    const [changePositionsDialogOpen, setChangePositionsDialogOpen] = useState(false);

    const mouseMove = useCallback((e) => {
        if (isResizing) {
            setSidebarWidth({
                width: `${e.clientX > 400 ? 400 : e.clientX < 250 ? 250 : e.clientX}px`,
                collapsedWidth: "75px"
            });
            updateAdmin({
                sidebar_width: e.clientX > 400 ? 400 : e.clientX < 250 ? 250 : e.clientX
            });
        }
    }, [isResizing])

    const removeListeners = useCallback(() => {
        window.removeEventListener("mousemove", mouseMove);
        window.removeEventListener("mouseup", removeListeners);
    }, [mouseMove])

    const mouseUp = useCallback(() => {
        removeListeners();
        setIsResizing(false);
    }, [setIsResizing, removeListeners])


    useEffect(() => {
        if (isResizing) {
            window.addEventListener("mouseup", mouseUp);
            window.addEventListener("mousemove", mouseMove);
        }

        return () => {
            removeListeners();
        }
    }, [isResizing, mouseMove, removeListeners, mouseUp])


    useEffect(() => {
        const selectedTitle = window.location.pathname.split("/")[1];
        switch (selectedTitle) {
            case "dashboard":
                setSelected(formatMessage({ id: "dashboard.header.title" }));
                break;
            case "users":
                setSelected(formatMessage({ id: "users.header.title" }));
                break;
            case "categories":
                setSelected(formatMessage({ id: "categories.header.title" }));
                break;
            case "subcategories":
                setSelected(formatMessage({ id: "categories.header.title" }));
                break;
            case "advertisements":
                setSelected(formatMessage({ id: "advertisements.header.title" }));
                break;
            case "onboardings":
                setSelected(formatMessage({ id: "onboarding.header.title" }));
                break;
            case "notifications":
                setSelected(formatMessage({ id: "notifications.header.title" }));
                break;
            case "reports":
                setSelected(formatMessage({ id: "reports.header.title" }));
                break;
            case "contactMessages":
                setSelected(formatMessage({ id: "contact_messages.header.title" }));
                break;
            case "forbiddenWords":
                setSelected(formatMessage({ id: "forbidden_words.header.title" }));
                break;
            case "commercials":
                setSelected(formatMessage({ id: "commercials.header.title" }));
                break;
            case "faqs":
                setSelected(formatMessage({ id: "faqs.header.title" }));
                break;
            case "admins":
                setSelected(formatMessage({ id: "admins.header.title" }));
                break;
            default:
                setSelected(formatMessage({ id: "dashboard.header.title" }));

        }
    }, [])

    const updateAdmin = (adminToUpdate) => {
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateAdmin?adminId=${admin.id}`, {
            admin: adminToUpdate
        }, {
            headers: {
                token: token
            }
        }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                setAdmin((prev) => ({ ...prev, adminToUpdate }))
                if (adminToUpdate && Object.keys(adminToUpdate).some(key => key.endsWith("_order"))) {
                    setSidebarItemPositions((prev) => ({ ...prev, ...adminToUpdate }))
                }
                setChangePositionsDialogOpen(false);
            }
        });
    }

    return (
        <Box height="100vh" zIndex="10" sx={{
            "& .pro-sidebar-inner": {
                background: `${colors.primary[400]} !important`
            },
            "& .pro-icon-wrapper": {
                backgroundColor: "transparent !important"
            },
            "& .pro-inner-item:hover": {
                color: "#868dfb !important"
            },
            "& .pro-menu-item.active": {
                color: "#6870fa !important"
            }
        }}>
            {changePositionsDialogOpen &&
                <ChangeSidebarPositionsDialog
                    sidebarItemPositions={sidebarItemPositions}
                    dialogOpen={changePositionsDialogOpen}
                    handleClose={() => {
                        setChangePositionsDialogOpen(false);
                    }}
                    handleFormSubmit={(positions) => {
                        updateAdmin(positions);
                    }}
                />
            }
            <ProSidebar
                collapsed={isCollapsed}
                {...sidebarWidth}
            >
                <Menu style={{ padding: "0px" }}>
                    <MenuItem onClick={() => {
                        if (isCollapsed) {
                            setIsCollapsed(!isCollapsed)
                        }
                    }}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            color: colors.grey[100]
                        }}>
                        {!isCollapsed && (
                            <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                                <Typography variant="h3" color={colors.grey[100]}>
                                    Owajoo
                                </Typography>
                                <Box>

                                    <IconButton onClick={() => {
                                        setChangePositionsDialogOpen(true);
                                    }}>
                                        <Edit />
                                    </IconButton>

                                    <IconButton onClick={() => {
                                        updateAdmin({
                                            is_sidebar_collapsed: admin.is_sidebar_collapsed === 1 ? 0 : 1
                                        });
                                        setDefaultSidebarCollapsed(admin.is_sidebar_collapsed === 1 ? false : true);
                                    }}>
                                        {
                                            defaultSidebarCollapsed ? <PushPinOutlined /> : <PushPin />
                                        }
                                    </IconButton>

                                    <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                        <MenuOutlinedIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        )}
                    </MenuItem>

                    <Box pl={isCollapsed ? undefined : "10%"}>
                        <SidebarItems
                            sidebarItemPositions={sidebarItemPositions}
                            admin={admin}
                            selected={selected}
                            setSelected={setSelected}
                            defaultSidebarCollapsed={defaultSidebarCollapsed}
                            isCollapsed={isCollapsed}
                            setIsCollapsed={setIsCollapsed}
                        />
                    </Box>
                </Menu>
                {!isCollapsed &&
                    <Box
                        sx={{
                            backgroundColor: colors.primary[400],
                            position: "absolute",
                            right: "0",
                            top: "0",
                            height: "100%",
                            width: "7px",
                            zIndex: "1",
                            cursor: "ew-resize",
                            ":hover": {
                                backgroundColor: "#ccc"
                            }
                        }}
                        onMouseDown={(e) => {
                            setIsResizing(true);
                        }}
                        onMouseUp={(e) => {
                            setIsResizing(false);
                            removeListeners();
                        }}
                    >

                    </Box>
                }
            </ProSidebar>
        </Box>
    )
}

export default Sidebar
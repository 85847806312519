import { Box, Snackbar } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import { Table } from '../../components/Table';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import NotificationDialog from './NotificationDialog';
import NotificationDraftDialog from './NotificationDraftsPage/NotificationDraftDialog';

function NotificationsPage() {
    const { admin, token } = useContext(AdminContext);
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [orderModel, setOrderModel] = useState(null);
    const [filterModel, setFilterModel] = useState(null);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        rowsPerPage: admin.notifications_table_row_count
    });
    const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);
    const [notificationToResend, setNotificationToResend] = useState(null);
    const [notificationDraftDialogOpen, setNotificationDraftDialogOpen] = useState(false);
    const [notificationToAddDraft, setNotificationToAddDraft] = useState(null);
    const [snackbarState, setSnackbarState] = useState({ open: false, message: "" });
    const handleSnackbarOpen = (message) => {
        setSnackbarState({ open: true, message: message });
    };

    const handleSnackbarClose = () => {
        setSnackbarState({ open: false, message: "" });
    };

    const columns = [
        {
            id: 'id',
            name: formatMessage({ id: "table.columns.id" }),
            type: 'number',
            sortable: true, filterable: true
        },
        {
            id: 'content_en',
            name: formatMessage({ id: "notifications.table.columns.content_with_language_code" }, { language: "EN" }),
            type: 'text',
            sortable: true, filterable: true
        },
        {
            id: 'content_de',
            name: formatMessage({ id: "notifications.table.columns.content_with_language_code" }, { language: "DE" }),
            type: 'text',
            sortable: true,
            filterable: true
        },
        {
            id: 'user_ids',
            name: formatMessage({ id: "notifications.table.columns.user_ids" }),
            type: 'text',
            sortable: false,
            filterable: false
        },
        {
            id: 'user_filter',
            name: formatMessage({ id: "notifications.table.columns.user_filter" }),
            type: 'text',
            render: (value, row) => {
                if (value === null || value === undefined || value === "" || value === "{}") return "";
                if (typeof value === "string") {
                    value = JSON.parse(value);
                }
                let valueCheck = value.filter((filter) => filter.id === "id" && filter.value === 0 && filter.min === true);
                if ((valueCheck && valueCheck.length > 0) || (!row.user_ids && Object.values(value).filter((filter) => filter.value !== "" && filter.value !== null && filter.value !== undefined).length === 0)) {
                    //all users
                    return <p style={{ margin: 0 }}>
                        <b>{formatMessage({ id: "all_users" })}</b>
                    </p>
                }
                return <>
                    {value.map((filter, index) => {
                        let filterId = `${filter.id === "name" || filter.id === "id" || filter.id === "created_date" ? "" : "users."}table.columns.${filter.id}`;
                        let filterSuffix = filter.min ? ` (${formatMessage({ id: "table.min" })})` : filter.max ? ` (${formatMessage({ id: "table.max" })})` : "";
                        let filterValue = filter.value;
                        if (filter.type === "boolean") {
                            filterValue = filter.value == 1 ? "✔" : "✘";
                        } else if (filter.type === "date") {
                            filterValue = new Date(filter.value).toLocaleDateString();
                        } else if (filter.type === "select") {
                            if (filter.id === "account_type") {
                                filterValue = formatMessage({ id: `users.${filter.id}.option.${filter.value == 0 ? "personal" : "corporate"}` });
                            } else if (filter.id === "gender") {
                                filterValue = formatMessage({ id: `users.${filter.id}.option.${filter.value == 0 ? "male" : filter.value == 1 ? "female" : "other"}` });
                            }
                        }
                        if (filter.value !== "" && filter.value !== null && filter.value !== undefined) {
                            return <p key={index} style={{ margin: 0 }}>
                                <b>{formatMessage({ id: filterId })}{filterSuffix}:</b> {filterValue}
                            </p>
                        } else {
                            return "";
                        }
                    })}
                </>
            },
            sortable: false,
            filterable: false
        },
        {
            id: 'delivery_type',
            name: formatMessage({ id: "notifications.table.columns.delivery_type" }),
            type: 'select',
            options: [
                { value: "notification", label: <FormattedMessage id={"notifications.delivery_type.option.notification"} /> },
                { value: "email", label: <FormattedMessage id={"notifications.delivery_type.option.email"} /> },
                { value: "notification_and_email", label: <FormattedMessage id={"notifications.delivery_type.option.notification_and_email"} /> },
                { value: "notification_if_allowed_or_email", label: <FormattedMessage id={"notifications.delivery_type.option.notification_if_allowed_or_email"} /> }
            ],
            sortable: true,
            filterable: true,
            render: (value) => {
                return formatMessage({ id: `notifications.delivery_type.option.${value}` })
            }
        },
        {
            id: "created_date",
            name: formatMessage({ id: "notifications.table.columns.send_date" }),
            type: 'date',
            sortable: true,
            filterable: true,
            render: (value) => {
                return new Date(value).toLocaleDateString() + " " + new Date(value).toLocaleTimeString();
            }
        },
        ...(admin.notification_drafts_perm === 1 || admin.resend_notification_perm === 1 ? [{
            id: 'actions',
            name: <FormattedMessage id={"table.columns.actions"} />,
            type: 'actions',
            sortable: false,
            filterable: false,
            getActions: () => [
                ...(admin.resend_notification_perm === 1 ? [{
                    label: <FormattedMessage id={"resend"} />,
                    action: (row) => {
                        setNotificationToResend(row);
                        setNotificationDialogOpen(true);
                    }
                }] : []),
                ...(admin.notification_drafts_perm === 1 ? [{
                    label: <FormattedMessage id={"add_to_drafts"} />,
                    action: (row) => {
                        setNotificationToAddDraft(row);
                        setNotificationDraftDialogOpen(true);
                    }
                }] : [])
            ]
        }] : [])
    ];

    const fetchNotifications = async (paginationModel, orderModel, filterModel, token) => {
        setLoading(true);
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/getSystemNotificationLogs`,
            {
                startIndex: paginationModel.page * paginationModel.rowsPerPage,
                limit: paginationModel.rowsPerPage,
                orderBy: orderModel ? orderModel.orderBy : null,
                orderType: orderModel ? orderModel.orderType : null,
                filter: filterModel ? filterModel : null
            },
            { headers: { token: token } }
        )
            .catch((err) => {
                setNotifications([]);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setNotifications(response.data.notificationLogs);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                }
                setLoading(false);
            });
    }

    const createNotificationDraft = (values) => {
        axios.post(
            `${process.env.REACT_APP_API_URL}webAdmin/createNotificationDraft`,
            {
                content_en: values.content_en,
                content_de: values.content_de,
                type: values.type
            },
            { headers: { token: token } }
        )
            .catch((error) => {
                console.log(error);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setNotificationDraftDialogOpen(false);
                    setNotificationToAddDraft(null);
                    handleSnackbarOpen(formatMessage({ id: "notification_draft_added_successfully" }));
                }
            });
    }

    useEffect(() => {
        fetchNotifications(paginationModel, orderModel, filterModel, token);
    }, [paginationModel, orderModel, filterModel, token]);

    return (
        <Box sx={{ p: "75px" }}>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarState.open}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                message={snackbarState.message}
            />
            {notificationToResend &&
                <NotificationDialog
                    notification={notificationToResend}
                    dialogOpen={notificationDialogOpen}
                    handleClose={
                        (e, reason) => {
                            if (reason !== "backdropClick") {
                                setNotificationDialogOpen(false);
                                setNotificationToResend(null);
                            }
                        }
                    }
                    onFormSubmit={() => {
                        setNotificationDialogOpen(false);
                        setNotificationToResend(null);
                        fetchNotifications(paginationModel, orderModel, filterModel, token);
                        handleSnackbarOpen(<FormattedMessage id={"notification_sent_successfully"} />);
                    }}
                />
            }
            {notificationToAddDraft &&
                <NotificationDraftDialog
                    draftFromNotification={true}
                    notificationDraft={{ ...notificationToAddDraft, type: "general" }}
                    dialogOpen={notificationDraftDialogOpen}
                    handleClose={
                        (e, reason) => {
                            if (reason !== "backdropClick") {
                                setNotificationDraftDialogOpen(false);
                                setNotificationToAddDraft(null);
                            }
                        }
                    }
                    onFormSubmit={(values) => {
                        createNotificationDraft(values);
                    }}
                />
            }
            <Header
                title={<FormattedMessage id={"notifications.header.title"} />}
                subtitle={<FormattedMessage id={"notifications.header.description"} />} />
            <Table
                table_id={"notifications_table"}
                isLoading={isLoading}
                columns={columns}
                rows={notifications}
                rowCount={rowCount}
                onOrderChange={(order) => {
                    setOrderModel(order);
                }}
                onPaginationChange={(pagination) => {
                    setPaginationModel(pagination);
                }}
                onFilterChange={(filter) => {
                    setFilterModel(filter);
                }}
                onRowClick={(row) => {

                }}
                additionalButtons={admin.notification_drafts_perm === 1 ? [
                    {
                        label: <FormattedMessage id={"see_drafts"} />,
                        color: "info",
                        onClick: (e) => {
                            navigate('/notifications/drafts');
                        }
                    },
                ] : []}
            />
        </Box>
    )
}

export default NotificationsPage
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, CircularProgress, Dialog, IconButton, Menu, MenuItem, Snackbar, Typography } from '@mui/material';
import React, { useCallback } from 'react'
import Header from '../../../global/Header';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AlertDialogSlide from '../../../components/AlertDialogSlide';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AdminContext } from '../../../context/AdminContext';
import CommentBox from '../../../components/Comment/CommentBox';
import CommentEditDialog from '../../../components/Comment/CommentEditDialog';
import { Table } from '../../../components/Table';
import { FormattedMessage } from 'react-intl';
import { MoreHoriz } from '@mui/icons-material';
import UserDialog from '../UserDialog';
import MakePremiumDialog from '../MakePremiumDialog';
import NotificationDialog from '../../NotificationsPage/NotificationDialog';
import SuspensionDialog from '../SuspensionDialog';
import AdvertisementsTable from '../../AdvertisementsPage/AdvertisementsTable';

function UserDetailsPage() {
    const { token, admin } = useContext(AdminContext);
    const { userId } = useParams();

    const [user, setUser] = useState(null);
    const [userLogs, setUserLogs] = useState([]);
    const [commentsByUser, setCommentsByUser] = useState([]);
    const [commentsToUser, setCommentsToUser] = useState([]);
    const [hasMoreUserLogs, setHasMoreUserLogs] = useState(true);
    const [hasMoreCommentsByUser, setHasMoreCommentsByUser] = useState(true);
    const [hasMoreCommentsToUser, setHasMoreCommentsToUser] = useState(true);

    const [notificationDialogState, setNotificationDialogState] = useState({
        open: false,
        user_ids: [],
        draft_type: "general"
    });
    const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
    const [makePremiumDialogState, setMakePremiumDialogState] = useState({ open: false, duration: "" });
    const [openUserPhotoDialog, setOpenUserPhotoDialog] = useState(false);

    const [commentEvent, setCommentEvent] = useState({ comment: null, type: null });
    const [commentEditDialogOpen, setCommentEditDialogOpen] = useState(false);

    const [suspensionDialogOpen, setSuspensionDialogOpen] = useState(false);

    const [alertDialogState, setAlertDialogState] = useState({ open: false, type: "" });

    const [editMenuAnchorEl, setEditMenuAnchorEl] = useState(null);
    const editMenuOpen = Boolean(editMenuAnchorEl);

    const [snackbarState, setSnackbarState] = useState({ open: false, type: "", message: "" });
    const handleSnackbarOpen = (type, message) => {
        setSnackbarState({ open: true, type: type, message: message });
    };

    const handleSnackbarClose = () => {
        setSnackbarState({ open: false, type: "", message: "" });
    }

    const handleEditMenuOpen = (event) => {
        setEditMenuAnchorEl(event.currentTarget);
    };
    const handleEditMenuClose = () => {
        setEditMenuAnchorEl(null);
    };
    const handleAlertDialogClose = () => {
        setAlertDialogState({ open: false, type: "" });
    }

    const commentEditDialogHandleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        setCommentEvent({ comment: null, type: null });
        setCommentEditDialogOpen(false);
    }

    const fetchUser = (userId, token) => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getUser?userId=${userId}`, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setUser(response.data.user);
                }
            });
    }

    const fetchUserLogs = useCallback((startIndex) => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getUserLogs?userId=${userId}&startIndex=${startIndex}&limit=20`, { headers: { "token": token } }).catch((err) => {
            console.log("err: " + err);
            setHasMoreUserLogs(false);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                setHasMoreUserLogs(response.data.userLogs && response.data.userLogs.length === 20);
                if (startIndex === 0) {
                    setUserLogs([...response.data.userLogs]);
                } else {
                    setUserLogs((prev) => [...prev, ...response.data.userLogs]);
                }
            }
        });
    }, [userId, token]);

    const fetchCommentsByUser = useCallback((startIndex) => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getUserMadeComments?userId=${userId}&startIndex=${startIndex}&limit=10`, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
                setHasMoreCommentsByUser(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMoreCommentsByUser(response.data.comments && response.data.comments.length === 10);
                    if (startIndex === 0) {
                        setCommentsByUser([...response.data.comments]);
                    } else {
                        setCommentsByUser((prev) => [...prev, ...response.data.comments]);
                    }
                }
            });
    }, [token, userId]);

    const fetchCommentsToUser = useCallback((startIndex) => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getUserComments?userId=${userId}&startIndex=${startIndex}&limit=10`, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
                setHasMoreCommentsToUser(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMoreCommentsToUser(response.data.comments && response.data.comments.length === 10);
                    if (startIndex === 0) {
                        setCommentsToUser([...response.data.comments]);
                    } else {
                        setCommentsToUser((prev) => [...prev, ...response.data.comments]);
                    }
                }
            });
    }, [token, userId]);

    useEffect(() => {
        fetchUser(userId, token);
        fetchUserLogs(0);
        fetchCommentsByUser(0);
        fetchCommentsToUser(0);
    }, [fetchCommentsByUser, fetchCommentsToUser, fetchUserLogs, token, userId]);

    useEffect(() => {
        if (commentEvent?.comment && commentEvent.type.includes("delete")) {
            setAlertDialogState({ open: true, type: "comment_deletion" });
        } else if (commentEvent?.comment && commentEvent.type.includes("edit")) {
            setCommentEditDialogOpen(true);
        }
    }, [commentEvent]);

    const updateUser = async (values) => {
        let profile_photo_link = user.profile_photo_link;
        if (values.photo) {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadUserProfilePhoto`, {
                profilePhoto: values.photo
            }, {
                headers: {
                    token: token,
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        delete values.photo;
                        delete values.profile_photo_link;
                        values.profile_photo_path = response.data.contentPath;
                        profile_photo_link = response.data.contentLink;
                    }
                });
        } else {
            delete values.photo;
            delete values.profile_photo_link;
        }

        let emailChanged = user.email && values.email && user.email !== values.email;
        let phoneNumberChanged = user.phone_number && values.phone_number && user.phone_number !== values.phone_number;

        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateUser?userId=${user.id}&emailChanged=${emailChanged}&phoneNumberChanged=${phoneNumberChanged}`, {
            user: values,
        }, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response) {
                    if (response.data.success === true && response.data.status === 201) {
                        if (values.hasOwnProperty("suspended_until")) {
                            setUser((prev) => { return { ...prev, ...values, ...(values.suspended_until ? { suspended: 1 } : { suspended: 0 }), profile_photo_link: profile_photo_link } });
                        } else {
                            setUser((prev) => { return { ...prev, ...values, profile_photo_link: profile_photo_link } });
                        }
                        setEditUserDialogOpen(false);
                        handleSnackbarOpen("success", <FormattedMessage id="user_updated_successfully" />);
                        setNotificationDialogState({ open: true, user_ids: [user.id], draft_type: "after_user_edit" });
                    } else if (response.data.success === false) {
                        if (response.data.status === 409) {
                            handleSnackbarOpen("error", <FormattedMessage id={`${response.data.code === "auth/email-already-exists" ? "email" : "phone_number"}_already_exists`} />);
                        } else if (response.data.status === 400) {
                            handleSnackbarOpen("error", <FormattedMessage id="invalid_email" />);
                        } else {
                            handleSnackbarOpen("error", <FormattedMessage id="an_error_occured" />);
                        }
                    }
                }
            });
    }

    const makeUserPremium = (userId, duration, values) => {
        axios.post(`https://api.revenuecat.com/v1/subscribers/${userId}/entitlements/premium_pack/promotional`, {
            duration: duration
        }, {
            headers: {
                "Authorization": `Bearer ${process.env.REACT_APP_REVENUECAT_API_KEY}`,
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.status === 201) {
                    updateUser({ is_premium: 1, bio: values.bio, subcategoryIds: values.subcategoryIds });
                }
            });
    }

    const revokeUserPremium = (userId) => {
        axios.post(`https://api.revenuecat.com/v1/subscribers/${userId}/entitlements/premium_pack/revoke_promotionals`, {}, {
            headers: {
                "Authorization": `Bearer ${process.env.REACT_APP_REVENUECAT_API_KEY}`,
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.status === 200) {
                    updateUser({ is_premium: 0 });
                }
            });
    }

    const updateComment = (values, type) => {
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateComment?reviewId=${commentEvent.comment.id}`, {
            review: values
        }, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    if (type === "edit_comments_by") {
                        let ix = commentsByUser.findIndex(({ id }) => id === commentEvent.comment.id);
                        if (ix >= 0) {
                            commentsByUser[ix] = { ...commentsByUser[ix], ...(values) };
                            commentsByUser[ix].avarage_point = (values.timing_point + values.communication_point + values.service_quality_point + values.price_point) / 4;
                            setCommentsByUser(Array.from(commentsByUser));
                        }
                    } else if (type === "edit_comments_to") {
                        let ix = commentsToUser.findIndex(({ id }) => id === commentEvent.comment.id);
                        if (ix >= 0) {
                            commentsToUser[ix] = { ...commentsToUser[ix], ...(values) };
                            commentsToUser[ix].avarage_point = (values.timing_point + values.communication_point + values.service_quality_point + values.price_point) / 4;
                            setCommentsToUser(Array.from(commentsToUser));
                        }
                    }

                    setCommentEvent({ comment: null, type: null });
                }
            });
    }

    const sendDeleteRequest = (type) => {
        if (type === "user") {
            axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteUserWithoutApple?id=${user.id}&firebase_id=${user.firebase_id}`, { headers: { "token": token } })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        window.location.href = "/users";
                    }
                });
        } else if (type === "comment_by" || type === "comment_to") {
            axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteComment?reviewId=${commentEvent.comment.id}`, { headers: { "token": token } })
                .catch((err) => {
                    console.log("err: " + err);
                    setCommentEvent({ comment: null, type: null });
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        if (type === "comment_by") {
                            let ix = commentsByUser.findIndex(({ id }) => id === commentEvent.comment.id);
                            if (ix >= 0) {
                                commentsByUser.splice(ix, 1);
                                setCommentsByUser(Array.from(commentsByUser));
                            }
                            setCommentEvent({ comment: null, type: null });
                        } else if (type === "comment_to") {
                            let ix = commentsToUser.findIndex(({ id }) => id === commentEvent.comment.id);
                            if (ix >= 0) {
                                commentsToUser.splice(ix, 1);
                                setCommentsToUser(Array.from(commentsToUser));
                            }
                            setCommentEvent({ comment: null, type: null });
                        }
                    }
                });
        }
    }


    const sendEmailForVerification = () => {
        axios.post(`${process.env.REACT_APP_API_URL}webAdmin/sendVerificationEmail?userId=${user.id}`, {}, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleSnackbarOpen("success", <FormattedMessage id="verification_email_sent" />);
                }
            });
    }

    const sendEmailForPasswordReset = () => {
        axios.post(`${process.env.REACT_APP_API_URL}webAdmin/sendPasswordResetEmail?userId=${user.id}`, {}, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleSnackbarOpen("success", <FormattedMessage id="password_reset_email_sent" />);
                }
            });
    }


    const columns = [
        {
            id: 'id',
            name: <FormattedMessage id={"table.columns.id"} />,
            type: 'number',
            sortable: false, filterable: false
        }, {
            id: 'profile_photo_link',
            name: <FormattedMessage id={"users.table.columns.profile_photo"} />,
            sortable: false,
            filterable: false,
            render: (value) => <img src={value} alt='profile-pic' style={{ height: "30px", width: "30px" }} />
        }, {
            id: 'name',
            name: <FormattedMessage id={"table.columns.name"} />,
            type: 'text',
            sortable: false, filterable: false
        }, ...(
            user?.account_type === 0 ? [
                {
                    id: 'surname',
                    name: <FormattedMessage id={"users.table.columns.surname"} />,
                    type: 'text',
                    sortable: false, filterable: false
                }
            ] : [
                {
                    id: 'contact_person',
                    name: <FormattedMessage id={"users.table.columns.contact_person"} />,
                    type: 'text',
                    sortable: false, filterable: false
                },
                {
                    id: 'vat',
                    name: <FormattedMessage id={"users.table.columns.vat"} />,
                    type: 'text',
                    sortable: false, filterable: false
                },
                {
                    id: 'website',
                    name: <FormattedMessage id={"users.table.columns.website"} />,
                    type: 'text',
                    sortable: false, filterable: false
                }
            ]

        ), {
            id: 'email',
            name: <FormattedMessage id={"users.table.columns.email"} />,
            type: 'text',
            sortable: false, filterable: false
        }, {
            id: 'phone_number',
            name: <FormattedMessage id={"users.table.columns.phone_number"} />,
            type: 'text',
            sortable: false, filterable: false
        }, {
            id: 'gender',
            name: <FormattedMessage id={"users.table.columns.gender"} />,
            type: 'select',
            options: [{ value: 0, label: <FormattedMessage id={"users.gender.option.male"} /> }, { value: 1, label: <FormattedMessage id={"users.gender.option.female"} /> }, { value: 2, label: <FormattedMessage id={"users.gender.option.secret"} /> }],
            sortable: false, filterable: false,
            render: (value, row) => {
                if (value !== undefined && value !== null && row.account_type === 0) {
                    return value === 0 ? <FormattedMessage id={"users.gender.option.male"} /> : value === 1 ? <FormattedMessage id={"users.gender.option.female"} /> : <FormattedMessage id={"users.gender.option.secret"} />
                } else {
                    return "-"
                }
            }
        }, {
            id: 'country',
            name: <FormattedMessage id={"users.table.columns.country"} />,
            type: 'text',
            sortable: false, filterable: false
        }, {
            id: 'city',
            name: <FormattedMessage id={"users.table.columns.city"} />,
            type: 'text',
            sortable: false, filterable: false
        }, {
            id: 'county',
            name: <FormattedMessage id={"users.table.columns.county"} />,
            type: 'text',
            sortable: false, filterable: false
        }, {
            id: 'blocked_count',
            name: <FormattedMessage id={"users.table.columns.blocked_count"} />,
            type: 'number',
            sortable: false, filterable: false
        }, {
            id: 'blocking_count',
            name: <FormattedMessage id={"users.table.columns.blocking_count"} />,
            type: 'number',
            sortable: false, filterable: false
        }, {
            id: 'user_agreement',
            name: <FormattedMessage id={"users.table.columns.user_agreement"} />,
            type: 'boolean',
            sortable: false, filterable: false
        }, {
            id: 'is_premium',
            name: <FormattedMessage id={"users.table.columns.is_premium"} />,
            type: 'boolean',
            sortable: false,
            filterable: false
        }, {
            id: 'account_type',
            name: <FormattedMessage id={"users.table.columns.account_type"} />,
            type: 'select',
            options: [{ value: 0, label: <FormattedMessage id={"users.account_type.option.personal"} /> }, { value: 1, label: <FormattedMessage id={"users.account_type.option.corporate"} /> }],
            sortable: false, filterable: false,
            render: (value) => {
                return value === 0 ? <FormattedMessage id={"users.account_type.option.personal"} /> : <FormattedMessage id={"users.account_type.option.corporate"} />
            }
        }, ...(
            user?.is_premium === 1 ? [
                {
                    id: 'avarage_point',
                    name: <FormattedMessage id={"users.table.columns.avarage_point"} />,
                    type: 'number',
                    sortable: false, filterable: false
                }
            ] : []
        ), {
            id: 'suspended',
            name: <FormattedMessage id={"users.table.columns.suspended"} />,
            type: 'boolean',
            sortable: false, filterable: false
        }, {
            id: 'suspended_until',
            name: <FormattedMessage id={"users.table.columns.suspended_until"} />,
            type: 'date',
            sortable: false, filterable: false,
            render: (value, row) => {
                if (row.suspended) {
                    let dateValue = new Date(value)
                    if (dateValue.getFullYear() === 1111 && dateValue.getMonth() === 0 && dateValue.getDate() === 1) {
                        return <FormattedMessage id={"indefinitely"} />
                    }
                    return new Date(value).toLocaleDateString()
                } else {
                    return "-"
                }
            }
        }, {
            id: 'suspended_reason',
            name: <FormattedMessage id={"users.table.columns.suspended_reason"} />,
            type: 'text',
            sortable: false, filterable: false,
            render: (value, row) => {
                if (row.suspended) {
                    return value
                }
                return "-"
            }
        }, {
            id: 'suspension_appeal',
            name: <FormattedMessage id={"users.table.columns.suspension_appeal"} />,
            type: 'text',
            sortable: false, filterable: false,
            render: (value, row) => {
                if (row.suspended && value) {
                    return <b>{value}</b>
                }
                return "-"
            }
        }, ...(
            user?.account_type === 0 ? [
                {
                    id: "birth_date",
                    name: <FormattedMessage id={"users.table.columns.birth_date"} />,
                    type: 'date',
                    sortable: false, filterable: false,
                    render: (value) => {
                        return new Date(value).toLocaleDateString()
                    }
                }
            ] : []
        ), {
            id: "created_date",
            name: <FormattedMessage id={"table.columns.created_date"} />,
            type: 'date',
            sortable: false, filterable: false,
            render: (value) => {
                return new Date(value).toLocaleDateString()
            }
        }
    ];

    return (
        <Box sx={{ p: "75px" }}>
            {!user &&
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress color="inherit" />
                </Box>
            }
            {user &&
                <Box>
                    <Snackbar
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        open={snackbarState.open}
                        autoHideDuration={5000}
                        onClose={handleSnackbarClose}>
                        <Alert onClose={handleSnackbarClose} severity={snackbarState.type} sx={{ width: '100%' }}>
                            {snackbarState.message}
                        </Alert>
                    </Snackbar>

                    <Menu
                        anchorEl={editMenuAnchorEl}
                        open={editMenuOpen}
                        onClose={handleEditMenuClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        {
                            !!(admin.direct_notification_perm === 1) &&
                            <MenuItem
                                onClick={() => {
                                    setNotificationDialogState({ open: true, user_ids: [user.id], draft_type: "general" });
                                    handleEditMenuClose();
                                }}>
                                <FormattedMessage id="send_notification" />
                            </MenuItem>
                        }

                        {!!(admin.user_edit_perm === 1) &&
                            <MenuItem
                                onClick={() => {
                                    setEditUserDialogOpen(true);
                                    handleEditMenuClose();
                                }}>
                                <FormattedMessage id="edit" />
                            </MenuItem>
                        }

                        {!!(admin.user_deletion_perm === 1) &&
                            <MenuItem
                                onClick={() => {
                                    setAlertDialogState({ open: true, type: "user_deletion" });
                                    handleEditMenuClose();
                                }}>
                                <FormattedMessage id="delete" />
                            </MenuItem>
                        }
                        {(user?.is_premium === 1 && !!(admin.user_edit_perm === 1)) &&
                            <MenuItem
                                onClick={() => {
                                    setAlertDialogState({ open: true, type: "revoke_user_premium" });
                                    handleEditMenuClose();
                                }}>
                                <FormattedMessage id="make_user_standart" />
                            </MenuItem>
                        }
                        {(user?.is_premium === 0 && !!(admin.user_edit_perm === 1)) &&
                            ["monthly", "six_month", "yearly"].map((duration) => (
                                <MenuItem
                                    key={duration}
                                    onClick={() => {
                                        setMakePremiumDialogState({ open: true, duration: duration });
                                        handleEditMenuClose();
                                    }}>
                                    <FormattedMessage id={`make_user_service_provider_${duration}`} />
                                </MenuItem>
                            ))
                        }
                        {user.email && user.email_verified === 0 &&
                            <MenuItem
                                onClick={() => {
                                    setAlertDialogState({ open: true, type: "send_verification_email", onApprove: sendEmailForVerification });
                                    handleEditMenuClose();
                                }}>
                                <FormattedMessage id="send_verification_email" />
                            </MenuItem>
                        }
                        {user.email &&
                            <MenuItem
                                onClick={() => {
                                    setAlertDialogState({ open: true, type: "send_password_reset_email", onApprove: sendEmailForPasswordReset });
                                    handleEditMenuClose();
                                }}>
                                <FormattedMessage id="send_password_reset_email" />
                            </MenuItem>
                        }
                        {(!!(user.suspended) && !!(admin.user_suspension_perm)) &&
                            <MenuItem
                                onClick={() => {
                                    setAlertDialogState({ open: true, type: "user_unsuspension", onApprove: () => updateUser({ suspended_until: null, suspended_reason: null, suspension_appeal: null }) });
                                    handleEditMenuClose();
                                }}>
                                <FormattedMessage id="unsuspend_user" />
                            </MenuItem>
                        }
                        {(!user.suspended && !!(admin.user_suspension_perm)) &&
                            <MenuItem
                                onClick={() => {
                                    setSuspensionDialogOpen(true);
                                    handleEditMenuClose();
                                }}>
                                <FormattedMessage id="suspend_user" />
                            </MenuItem>
                        }
                    </Menu>
                    {notificationDialogState.open &&
                        <NotificationDialog
                            dialogOpen={notificationDialogState.open}
                            notification={{
                                user_ids: notificationDialogState.user_ids,
                            }}
                            onFormSubmit={() => {
                                setNotificationDialogState({
                                    open: false,
                                    user_ids: [],
                                    draft_type: "general"
                                })
                            }}
                            initialDraftType={notificationDialogState.draft_type}
                            handleClose={(event, reason) => {
                                if (reason && reason === "backdropClick") {
                                    return;
                                }
                                setNotificationDialogState({
                                    open: false,
                                    user_ids: [],
                                    draft_type: "general"
                                })
                            }}
                        />}
                    {alertDialogState.open &&
                        <AlertDialogSlide
                            open={alertDialogState.open}
                            handleClose={(event, reason) => {
                                if (reason && reason === "backdropClick") {
                                    return;
                                }
                                if (alertDialogState.type === "comment_deletion") {
                                    setCommentEvent({ comment: null, type: null });
                                } else {
                                    handleAlertDialogClose();
                                }
                            }}
                            clickPositiveAnswer={() => {
                                if (alertDialogState.type === "revoke_user_premium") {
                                    revokeUserPremium(user.id)
                                } else if (alertDialogState.type === "comment_update" || alertDialogState.type === "user_update" || alertDialogState.type.includes("email") || alertDialogState.type === "user_unsuspension" || alertDialogState.type === "user_suspension") {
                                    alertDialogState.onApprove();
                                } else {
                                    sendDeleteRequest(alertDialogState.type === "comment_deletion" ? (commentEvent.type === "delete_comments_by" ? "comment_by" : "comment_to") : "user")
                                }
                                setAlertDialogState({ open: false, type: "" });
                            }}
                            clickNegativeAnswer={() => { }}
                            positiveAnswer={alertDialogState.type.includes("deletion") ? <FormattedMessage id="delete" /> : <FormattedMessage id="yes" />}
                            negativeAnswer={alertDialogState.type.includes("deletion") ? <FormattedMessage id="cancel" /> : <FormattedMessage id="no" />}
                            title={<FormattedMessage id={`are_you_sure_to_${alertDialogState.type === "comment_deletion" ? "delete_comment" : alertDialogState.type === "user_deletion" ? "delete_user" : alertDialogState.type === "user_suspension" ? "suspend_user" : alertDialogState.type === "user_unsuspension" ? "unsuspend_user" : alertDialogState.type === "revoke_user_premium" ? "revoke_user_premium" : alertDialogState.type === "comment_update" ? "update_comment" : alertDialogState.type.includes("email") ? "send_email" : "update_user"}`} />}
                            description={<FormattedMessage id={`${alertDialogState.type === "comment_deletion" ? "delete_comment" : alertDialogState.type === "user_deletion" ? "delete_user" : alertDialogState.type === "user_suspension" ? "suspend_user" : alertDialogState.type === "user_unsuspension" ? "unsuspend_user" : alertDialogState.type === "revoke_user_premium" ? "revoke_user_premium" : alertDialogState.type === "comment_update" ? "update_comment" : alertDialogState.type.includes("email") ? "send_email" : "update_user"}_description`} />}
                        />
                    }

                    <UserDialog
                        targetUser={user}
                        dialogOpen={editUserDialogOpen}
                        handleClose={(event, reason) => {
                            if (reason && reason === "backdropClick") {
                                return;
                            }
                            setEditUserDialogOpen(false)
                        }}
                        handleFormSubmit={(values) => {
                            setAlertDialogState({ open: true, type: "user_update", onApprove: () => updateUser(values) });
                        }}
                    />
                    {
                        makePremiumDialogState.open &&
                        <MakePremiumDialog
                            makePremiumDialogState={makePremiumDialogState}
                            setMakePremiumDialogState={setMakePremiumDialogState}
                            handleSubmit={(values) => {
                                setAlertDialogState({
                                    open: true,
                                    type: "user_update",
                                    onApprove: () => {
                                        makeUserPremium(user.id, makePremiumDialogState.duration, values)
                                        setMakePremiumDialogState({ open: false, duration: "" });
                                    }
                                });
                            }}
                            user={user}
                        />
                    }

                    {suspensionDialogOpen &&
                        <SuspensionDialog
                            open={suspensionDialogOpen}
                            onClose={() => {
                                setSuspensionDialogOpen(false);
                            }}
                            handleSubmit={(values) => {
                                setAlertDialogState({ open: true, type: "user_suspension", onApprove: () => { updateUser(values); setSuspensionDialogOpen(false); } });
                            }}
                        />
                    }

                    {
                        commentEvent?.comment && commentEvent.type?.includes("edit") &&
                        <CommentEditDialog
                            comment={commentEvent.comment}
                            dialogOpen={commentEditDialogOpen}
                            handleClose={commentEditDialogHandleClose}
                            handleFormSubmit={
                                (values) => {
                                    setAlertDialogState({ open: true, type: "comment_update", onApprove: () => updateComment(values, commentEvent.type) });
                                }
                            }
                        />
                    }

                    <Dialog
                        open={openUserPhotoDialog}
                        onClose={(event, reason) => {
                            if (reason && reason === "backdropClick") {
                                return;
                            }
                            setOpenUserPhotoDialog(false)
                        }}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                        PaperProps={{
                            style: {
                                backgroundColor: "transparent",
                                boxShadow: "none"
                            }
                        }}
                    >
                        <Box>
                            <Button
                                variant="standard"
                                color="error"
                                sx={{
                                    borderRadius: "100%",
                                    backgroundColor: "white",
                                    maxWidth: "40px",
                                    maxHeight: "40px",
                                    minWidth: "40px",
                                    minHeight: "40px",
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    ":hover": {
                                        backgroundColor: "white"
                                    }
                                }}
                                onClick={() => setOpenUserPhotoDialog(false)}>
                                <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                            </Button>
                            <img src={user.profile_photo_link} alt="profile-user" />
                        </Box>
                    </Dialog>

                    <Box display="flex">
                        <Box display="flex">
                            <IconButton style={{ width: "40px", height: "40px", marginRight: "5px" }}
                                onClick={() => {
                                    window.history.back();
                                }}>
                                <ArrowBackIosNewOutlinedIcon />
                            </IconButton>
                            <Box display="flex" mr="5px">
                                <img
                                    alt="profile-user"
                                    src={user.profile_photo_link}
                                    width="35px"
                                    height="35px"
                                    style={{ cursor: "pointer", borderRadius: "50%" }}
                                    onClick={() => setOpenUserPhotoDialog(true)}
                                />
                            </Box>
                            <Box display="flex">
                                <Header
                                    title={
                                        <>
                                            {user.name} {user.surname} - {user.is_premium ? <FormattedMessage id="service_provider_capital" /> : <FormattedMessage id="standart_capital" />}
                                        </>
                                    }
                                    subtitle={""} />
                            </Box>
                        </Box>

                        <Box display="flex" gap="20px" mb="30px" ml="20px" alignItems="center">
                            <IconButton
                                onClick={handleEditMenuOpen}
                                style={{
                                    width: "40px",
                                    height: "40px"
                                }}>
                                <MoreHoriz style={{
                                    width: "30px",
                                    height: "30px"
                                }} />
                            </IconButton>
                        </Box>
                    </Box>

                    <Box mx="30px">
                        <Table
                            table_id={"users_table"}
                            columns={columns}
                            rows={[user]}
                            rowCount={1}
                        />
                    </Box>

                    <Box m="30px 20px 0 20px" pb="30px">
                        <Accordion disabled={userLogs.length < 1}>
                            <AccordionSummary id='userLog-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>
                                    <FormattedMessage id="userLogs" />
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <InfiniteScroll
                                    dataLength={userLogs.length}
                                    next={() => fetchUserLogs(userLogs.length)}
                                    hasMore={hasMoreUserLogs}
                                    loader={<h4>
                                        <FormattedMessage id="loading_elipsis" />
                                    </h4>}
                                    height={310}
                                >
                                    <Box m="10px 0 0 0" maxHeight="300px" display="flex" flexDirection="column">
                                        {userLogs.map((userLog) =>
                                            <Typography key={userLog.id}>
                                                <FormattedMessage
                                                    id={`userLog.${userLog.type.toLowerCase()}`}
                                                    values={{ date: new Date(userLog.created_date).toLocaleDateString() + " " + new Date(userLog.created_date).toLocaleTimeString() }} />
                                            </Typography>
                                        )}
                                    </Box>
                                </InfiniteScroll>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion disabled={!(admin.advertisements_perm === 1)}>
                            <AccordionSummary id='advertisement-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>
                                    <FormattedMessage id="advertisements" />
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <AdvertisementsTable userId={user.id} />
                                {/* <InfiniteScroll
                                    dataLength={advertisements.length}
                                    next={() => fetchAdvertisements(advertisements.length)}
                                    hasMore={hasMoreAdvertisements}
                                    loader={
                                        <h4>
                                            <FormattedMessage id="loading_elipsis" />
                                        </h4>
                                    }
                                    height={510}
                                >
                                    <Box m="10px 0 0 0" maxHeight="500px" display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))">
                                        {advertisements.map((advertisement) => <AdvertisementBox
                                            key={advertisement.id}
                                            advertisement={advertisement}
                                            onInfoButtonClick={() => setAdvertisementEvent({ advertisement: advertisement, type: "info", fetched: false })}
                                            onEditButtonClick={() => setAdvertisementEvent({ advertisement: advertisement, type: "edit", fetched: false })}
                                            onDeleteButtonClick={() => setAdvertisementEvent({ advertisement: advertisement, type: "delete", fetched: false })}
                                        />)}
                                    </Box>
                                </InfiniteScroll> */}
                            </AccordionDetails>
                        </Accordion>
                        <Accordion disabled={commentsByUser.length < 1}>
                            <AccordionSummary id='comments-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>
                                    <FormattedMessage id="comments_by_user" />
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <InfiniteScroll
                                    dataLength={commentsByUser.length}
                                    next={() => fetchCommentsByUser(commentsByUser.length)}
                                    hasMore={hasMoreCommentsByUser}
                                    loader={<h4>
                                        <FormattedMessage id="loading_elipsis" />
                                    </h4>}
                                    height={510}
                                >
                                    <Box m="10px 0 0 0" maxHeight="500px" display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))">
                                        {commentsByUser.map((comment) => <CommentBox
                                            key={comment.id}
                                            comment={comment}
                                            onNotificationSendButtonClick={(userIds) => setNotificationDialogState({ open: true, user_ids: [...userIds], draft_type: "comment" })}
                                            onEditButtonClick={() => setCommentEvent({ comment: comment, type: "edit_comments_by" })}
                                            onDeleteButtonClick={() => setCommentEvent({ comment: comment, type: "delete_comments_by" })}
                                        />)}
                                    </Box>
                                </InfiniteScroll>
                            </AccordionDetails>
                        </Accordion>
                        {user && user.is_premium === 1 &&
                            <Accordion disabled={commentsToUser.length < 1}>
                                <AccordionSummary id='comments-accordion' expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant='h4'>
                                        <FormattedMessage id="comments_to_user" />
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <InfiniteScroll
                                        dataLength={commentsToUser.length}
                                        next={() => fetchCommentsToUser(commentsToUser.length)}
                                        hasMore={hasMoreCommentsToUser}
                                        loader={<h4>
                                            <FormattedMessage id="loading_elipsis" />
                                        </h4>}
                                        height={510}
                                    >
                                        <Box m="10px 0 0 0" maxHeight="500px" display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))">
                                            {commentsToUser.map((comment) => <CommentBox
                                                key={comment.id}
                                                comment={comment}
                                                onNotificationSendButtonClick={(userIds) => setNotificationDialogState({ open: true, user_ids: [...userIds], draft_type: "comment" })}
                                                onEditButtonClick={() => setCommentEvent({ comment: comment, type: "edit_comments_to" })}
                                                onDeleteButtonClick={() => setCommentEvent({ comment: comment, type: "delete_comments_to" })}
                                            />)}
                                        </Box>
                                    </InfiniteScroll>
                                </AccordionDetails>
                            </Accordion>
                        }
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default UserDetailsPage;
import { Box, Menu, MenuItem } from '@mui/material'
import axios from 'axios';
import * as XLSX from 'xlsx';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import { Table } from '../../components/Table';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import NotificationDialog from '../NotificationsPage/NotificationDialog';

function UsersPage() {
    const { admin, token } = useContext(AdminContext);
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [orderModel, setOrderModel] = useState(null);
    const [filterModel, setFilterModel] = useState(null);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        rowsPerPage: admin.users_table_row_count
    });

    const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);
    const [exportMenuAnchorEl, setExportMenuAnchorEl] = useState(null);
    const exportMenuOpen = Boolean(exportMenuAnchorEl);

    const handleExportMenuOpen = (event) => {
        setExportMenuAnchorEl(event.currentTarget);
    };
    const handleExportMenuClose = () => {
        setExportMenuAnchorEl(null);
    };

    const columns = [
        {
            id: 'id',
            name: formatMessage({ id: "table.columns.id" }),
            type: 'number',
            sortable: true, filterable: true
        },
        {
            id: 'profile_photo_link',
            name: formatMessage({ id: "users.table.columns.profile_photo" }),
            sortable: false,
            filterable: false,
            render: (value) => <img src={value} alt='profile-pic' style={{ height: "30px", width: "30px" }} />
        },
        {
            id: 'name',
            name: formatMessage({ id: "table.columns.name" }),
            type: 'text',
            sortable: true, filterable: true
        }, {
            id: 'surname',
            name: formatMessage({ id: "users.table.columns.surname" }),
            type: 'text',
            sortable: true, filterable: true
        }, {
            id: 'email',
            name: formatMessage({ id: "users.table.columns.email" }),
            type: 'text',
            sortable: true, filterable: true
        }, {
            id: 'phone_number',
            name: formatMessage({ id: "users.table.columns.phone_number" }),
            type: 'text',
            sortable: true, filterable: true
        }, {
            id: 'gender',
            name: formatMessage({ id: "users.table.columns.gender" }),
            type: 'select',
            options: [
                { value: 0, label: <FormattedMessage id={"users.gender.option.male"} /> },
                { value: 1, label: <FormattedMessage id={"users.gender.option.female"} /> },
                { value: 2, label: <FormattedMessage id={"users.gender.option.secret"} /> }
            ],
            sortable: true, filterable: true,
            render: (value, row) => {
                if (value !== undefined && value !== null && row.account_type === 0) {
                    return value === 0 ? formatMessage({ id: "users.gender.option.male" }) : value === 1 ? formatMessage({ id: "users.gender.option.female" }) : formatMessage({ id: "users.gender.option.secret" })
                } else {
                    return "-"
                }
            }
        }, {
            id: 'country',
            name: formatMessage({ id: "users.table.columns.country" }),
            type: 'text',
            sortable: true, filterable: true
        }, {
            id: 'city',
            name: formatMessage({ id: "users.table.columns.city" }),
            type: 'text',
            sortable: true, filterable: true
        }, {
            id: 'county',
            name: formatMessage({ id: "users.table.columns.county" }),
            type: 'text',
            sortable: true, filterable: true
        }, {
            id: 'blocked_count',
            name: formatMessage({ id: "users.table.columns.blocked_count" }),
            type: 'number',
            sortable: true, filterable: true
        }, {
            id: 'blocking_count',
            name: formatMessage({ id: "users.table.columns.blocking_count" }),
            type: 'number',
            sortable: true, filterable: true
        }, {
            id: 'user_agreement',
            name: formatMessage({ id: "users.table.columns.user_agreement" }),
            type: 'boolean',
            sortable: true, filterable: true
        }, {
            id: 'is_premium',
            name: formatMessage({ id: "users.table.columns.is_premium" }),
            type: 'boolean',
            sortable: true,
            filterable: true
        }, {
            id: 'account_type',
            name: formatMessage({ id: "users.table.columns.account_type" }),
            type: 'select',
            options: [{ value: 0, label: <FormattedMessage id={"users.account_type.option.personal"} /> }, { value: 1, label: <FormattedMessage id={"users.account_type.option.corporate"} /> }],
            sortable: true, filterable: true,
            render: (value) => {
                return value === 0 ? formatMessage({ id: "users.account_type.option.personal" }) : formatMessage({ id: "users.account_type.option.corporate" })
            }
        }, {
            id: 'avarage_point',
            name: formatMessage({ id: "users.table.columns.avarage_point" }),
            type: 'number',
            sortable: true, filterable: true
        }, {
            id: 'suspended',
            name: formatMessage({ id: "users.table.columns.suspended" }),
            type: 'boolean',
            sortable: true, filterable: true
        }, {
            id: "birth_date",
            name: formatMessage({ id: "users.table.columns.birth_date" }),
            type: 'date',
            sortable: true, filterable: true,
            render: (value, row) => {
                if (row.account_type === 0) {
                    return new Date(value).toLocaleDateString()
                } else {
                    return "-"
                }
            }
        }, {
            id: "created_date",
            name: formatMessage({ id: "table.columns.created_date" }),
            type: 'date',
            sortable: true, filterable: true,
            render: (value) => {
                return new Date(value).toLocaleDateString()
            }
        }
    ];

    const fetchUsers = async (paginationModel, orderModel, filterModel, token) => {
        setLoading(true);
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/getUsers`,
            {
                startIndex: paginationModel.page * paginationModel.rowsPerPage,
                limit: paginationModel.rowsPerPage,
                orderBy: orderModel ? orderModel.orderBy : null,
                orderType: orderModel ? orderModel.orderType : null,
                filter: filterModel ? filterModel : null
            },
            { headers: { token: token } }
        )
            .catch((err) => {
                setUsers([]);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setUsers(response.data.users);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                }
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchUsers(paginationModel, orderModel, filterModel, token);
    }, [paginationModel, orderModel, filterModel, token]);

    const handleExport = (fileExtension) => {
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/getUsers`,
            {
                orderBy: "created_date",
                orderType: "DESC"
            },
            { headers: { token: token } }
        )
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    let usersToExport = response.data.users.map((user) => {
                        let userToExport = {};
                        columns.forEach((column) => {
                            userToExport[column.name] = column.id === "gender" || column.id === "account_type" || column.id.includes("date") ? column.render(user[column.id], user) : user[column.id];
                        });
                        return userToExport;
                    });
                    const worksheet = XLSX.utils.json_to_sheet(usersToExport);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
                    XLSX.writeFile(workbook, `Owajoo-Users-${(new Date()).toISOString()}.${fileExtension}`);
                }
            });
    };

    return (
        <Box sx={{ p: "75px" }}>
            <Menu
                anchorEl={exportMenuAnchorEl}
                open={exportMenuOpen}
                onClose={handleExportMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem
                    onClick={() => {
                        handleExport("xlsx");
                        handleExportMenuClose();
                    }}>
                    XLSX
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleExport("csv");
                        handleExportMenuClose();
                    }}>
                    CSV
                </MenuItem>
            </Menu>
            <NotificationDialog
                dialogOpen={notificationDialogOpen}
                notification={{
                    user_filter: filterModel ? filterModel : [{
                        id: "id",
                        value: 0,
                        type: "number",
                        min: true,
                        max: false
                    }]
                }}
                onFormSubmit={() => {
                    setNotificationDialogOpen(false)
                }}
                initialDraftType='general'
                handleClose={(event, reason) => {
                    if (reason && reason === "backdropClick") {
                        return;
                    }
                    setNotificationDialogOpen(false)
                }}
            />
            <Header
                title={<FormattedMessage id={"users.header.title"} />}
                subtitle={<FormattedMessage id={"users.header.description"} />} />
            <Table
                table_id={"users_table"}
                isLoading={isLoading}
                columns={columns}
                rows={users}
                rowCount={rowCount}
                onOrderChange={(order) => {
                    setOrderModel(order);
                }}
                onPaginationChange={(pagination) => {
                    setPaginationModel(pagination);
                }}
                onFilterChange={(filter) => {
                    setFilterModel(filter);
                }}
                onRowClick={(row) => {
                    navigate(`/users/${row.id}`);
                }}
                additionalButtons={[
                    ...(admin.direct_notification_perm === 1 ? [{
                        label: <FormattedMessage id={"send_notification"} />,
                        color: "info",
                        onClick: (e) => {
                            setNotificationDialogOpen(true);
                        }
                    }] : []),
                    {
                        label: <FormattedMessage id={"export"} />,
                        color: "info",
                        onClick: (e) => handleExportMenuOpen(e)
                    }
                ]}
            />
        </Box>
    )
}

export default UsersPage
import { Alert, Box, IconButton, Menu, MenuItem, Snackbar } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import LogoutIcon from '@mui/icons-material/Logout';
import { AdminContext } from '../context/AdminContext';
import AlertDialogSlide from '../components/AlertDialogSlide';
import { FormattedMessage, useIntl } from 'react-intl';
import { Settings } from '@mui/icons-material';
import axios from 'axios';
import ServiceProviderPromotionalTextEditDialog from '../components/ServiceProviderPromotionalTextEditDialog';
import ChatGptSettingsEditDialog from '../components/ChatGptSettingsEditDialog';
import AboutUsDialog from '../components/AboutUsDialog';
import UserAgreementDialog from '../components/UserAgreementDialog';
import PrivacyPolicyDialog from '../components/PrivacyPolicyDialog';

function Topbar() {
    const [settings, setSettings] = useState(null);
    const { setToken, token, admin } = useContext(AdminContext);
    const { formatMessage } = useIntl();
    const [chatGptSettingsDialogOpen, setChatGptSettingsDialogOpen] = useState(false);
    const [serviceProviderPromotionalTextDialogOpen, setServiceProviderPromotionalTextDialogOpen] = useState(false);
    const [aboutUsDialogOpen, setAboutUsDialogOpen] = useState(false);
    const [userAgreementDialogOpen, setUserAgreementDialogOpen] = useState(false);
    const [privacyPolicyDialogOpen, setPrivacyPolicyDialogOpen] = useState(false);
    const [alertDialogState, setAlertDialogState] = useState({
        open: false,
        onApprove: () => { },
        title: "",
        description: "",
        positiveAnswer: "",
        negativeAnswer: ""
    });

    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const [snackbarState, setSnackbarState] = useState({ open: false, type: "", message: "" });
    const handleSnackbarOpen = (type, message) => {
        setSnackbarState({ open: true, type: type, message: message });
    };
    const handleSnackbarClose = () => {
        setSnackbarState({ open: false, type: "", message: "" });
    }

    useEffect(() => {
        if (token) {
            fetchSettings(token);
        }
    }, [token]);

    const fetchSettings = (token) => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getSettings`, {
            headers: {
                token: token
            }
        }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                setSettings(response.data.settings);
            }
        });
    };

    const updateSettings = (settings) => {
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateSettings`, {
            settings: settings
        }, {
            headers: {
                token: token
            }
        }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                setSettings((prev) => ({ ...prev, ...settings }));
                handleSnackbarOpen("success", formatMessage({ id: "settings_updated_successfully" }));
            }
        });
    };

    const updateAdminLanguage = (language) => {
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateAdmin?adminId=${admin.id}`, {
            admin: {
                language: language
            }
        }, {
            headers: {
                token: token
            }
        }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                //refresh page
                window.location.reload();
            }
        });
    };


    return (
        <Box
            position="absolute"
            right="0"
            display="flex"
            gap="10px"
            pt="10px"
            pr="20px"
            justifyContent="end"
            alignItems="center"
            zIndex="5"
            sx={{
                backgroundColor: "#fcfcfc",
                width: "100%",
                height: "50px"
            }}
        >
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarState.open}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarState.type} sx={{ width: '100%' }}>
                    {snackbarState.message}
                </Alert>
            </Snackbar>
            <Menu
                id="menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    setAlertDialogState({
                        open: true,
                        onApprove: () => {
                            updateAdminLanguage(admin.language === "en" ? "de" : "en");
                        },
                        title: <FormattedMessage id={"are_you_sure_to_change_panel_language"} />,
                        description: <FormattedMessage id={"change_panel_language_description_with_value"} values={{ language: admin.language === "en" ? "Deutsch" : "English" }} />,
                        positiveAnswer: <FormattedMessage id={"yes"} />,
                        negativeAnswer: <FormattedMessage id={"cancel"} />
                    })
                }}>
                    <FormattedMessage id="change_panel_language_with_value" values={{ language: admin.language === "en" ? "Deutsch" : "English" }} />
                </MenuItem>

                {admin.gpt_settings_edit_perm === 1 &&
                    <MenuItem onClick={() => {
                        setChatGptSettingsDialogOpen(true);
                        handleMenuClose();
                    }}>
                        <FormattedMessage id="edit_chatgpt_settings" />
                    </MenuItem>
                }
                {admin.service_provider_promotional_text_edit_perm === 1 &&
                    <MenuItem onClick={() => {
                        setServiceProviderPromotionalTextDialogOpen(true);
                        handleMenuClose();
                    }}>
                        <FormattedMessage id="edit_service_provider_promotional_text" />
                    </MenuItem>
                }
                {admin.about_app_edit_perm === 1 &&
                    <MenuItem onClick={() => {
                        setAboutUsDialogOpen(true);
                        handleMenuClose();
                    }}>
                        <FormattedMessage id="edit_about_us" />
                    </MenuItem>
                }
                {admin.user_agreement_edit_perm === 1 &&
                    <MenuItem onClick={() => {
                        setUserAgreementDialogOpen(true);
                        handleMenuClose();
                    }}>
                        <FormattedMessage id="edit_user_agreement" />
                    </MenuItem>
                }
                {admin.privacy_policy_edit_perm === 1 &&
                    <MenuItem onClick={() => {
                        setPrivacyPolicyDialogOpen(true);
                        handleMenuClose();
                    }}>
                        <FormattedMessage id="edit_privacy_policy" />
                    </MenuItem>
                }
            </Menu>
            {chatGptSettingsDialogOpen &&
                <ChatGptSettingsEditDialog
                    dialogOpen={chatGptSettingsDialogOpen}
                    handleClose={() => setChatGptSettingsDialogOpen(false)}
                    handleFormSubmit={(values) => {
                        setAlertDialogState({
                            open: true,
                            onApprove: () => {
                                updateSettings(values);
                                setChatGptSettingsDialogOpen(false);
                            },
                            title: <FormattedMessage id={"are_you_sure_to_update_chatgpt_settings"} />,
                            description: <FormattedMessage id={"update_chatgpt_settings_description"} />,
                            positiveAnswer: <FormattedMessage id={"yes"} />,
                            negativeAnswer: <FormattedMessage id={"cancel"} />
                        })
                    }}
                    settings={settings}
                />
            }
            {serviceProviderPromotionalTextDialogOpen &&
                <ServiceProviderPromotionalTextEditDialog
                    dialogOpen={serviceProviderPromotionalTextDialogOpen}
                    handleClose={() => setServiceProviderPromotionalTextDialogOpen(false)}
                    handleFormSubmit={(values) => {
                        setAlertDialogState({
                            open: true,
                            onApprove: () => {
                                updateSettings(values);
                                setServiceProviderPromotionalTextDialogOpen(false);
                            },
                            title: <FormattedMessage id={"are_you_sure_to_update_service_provider_promotional_text"} />,
                            description: <FormattedMessage id={"update_service_provider_promotional_text_description"} />,
                            positiveAnswer: <FormattedMessage id={"yes"} />,
                            negativeAnswer: <FormattedMessage id={"cancel"} />
                        })
                    }}
                    settings={settings}
                />
            }
            {aboutUsDialogOpen &&
                <AboutUsDialog
                    initialSettings={{
                        about_us_en: settings?.about_us_en || "",
                        about_us_de: settings?.about_us_de || ""
                    }}
                    dialogOpen={aboutUsDialogOpen}
                    handleClose={() => setAboutUsDialogOpen(false)}
                    handleFormSubmit={(value) => {
                        setAlertDialogState({
                            open: true,
                            onApprove: () => {
                                updateSettings(value);
                                setAboutUsDialogOpen(false);
                            },
                            title: <FormattedMessage id={"are_you_sure_to_update_about_us"} />,
                            description: <FormattedMessage id={"update_about_us_description"} />,
                            positiveAnswer: <FormattedMessage id={"yes"} />,
                            negativeAnswer: <FormattedMessage id={"cancel"} />
                        })
                    }}
                />
            }
            {userAgreementDialogOpen &&
                <UserAgreementDialog
                    initialSettings={{
                        user_agreement: settings?.user_agreement || ""
                    }}
                    dialogOpen={userAgreementDialogOpen}
                    handleClose={() => setUserAgreementDialogOpen(false)}
                    handleFormSubmit={(value) => {
                        setAlertDialogState({
                            open: true,
                            onApprove: () => {
                                updateSettings(value);
                                setUserAgreementDialogOpen(false);
                            },
                            title: <FormattedMessage id={"are_you_sure_to_update_user_agreement"} />,
                            description: <FormattedMessage id={"update_user_agreement_description"} />,
                            positiveAnswer: <FormattedMessage id={"yes"} />,
                            negativeAnswer: <FormattedMessage id={"cancel"} />
                        })
                    }}
                />
            }
            {privacyPolicyDialogOpen &&
                <PrivacyPolicyDialog
                    initialSettings={{
                        privacy_policy: settings?.privacy_policy || ""
                    }}
                    dialogOpen={privacyPolicyDialogOpen}
                    handleClose={() => setPrivacyPolicyDialogOpen(false)}
                    handleFormSubmit={(value) => {
                        setAlertDialogState({
                            open: true,
                            onApprove: () => {
                                updateSettings(value);
                                setPrivacyPolicyDialogOpen(false);
                            },
                            title: <FormattedMessage id={"are_you_sure_to_update_privacy_policy"} />,
                            description: <FormattedMessage id={"update_privacy_policy_description"} />,
                            positiveAnswer: <FormattedMessage id={"yes"} />,
                            negativeAnswer: <FormattedMessage id={"cancel"} />
                        })
                    }}
                />
            }
            {alertDialogState?.open &&
                <AlertDialogSlide
                    open={alertDialogState.open}
                    handleClose={(event, reason) => {
                        if (reason && reason === "backdropClick") {
                            return;
                        }
                        setAlertDialogState({
                            open: false,
                            onApprove: () => { },
                            title: "",
                            description: "",
                            positiveAnswer: "",
                            negativeAnswer: ""
                        })
                    }}
                    clickPositiveAnswer={() => {
                        alertDialogState.onApprove();
                        setAlertDialogState({
                            open: false,
                            onApprove: () => { },
                            title: "",
                            description: "",
                            positiveAnswer: "",
                            negativeAnswer: ""
                        });
                    }}
                    clickNegativeAnswer={() => {
                        setAlertDialogState({
                            open: false,
                            onApprove: () => { },
                            title: "",
                            description: "",
                            positiveAnswer: "",
                            negativeAnswer: ""
                        });
                    }}
                    positiveAnswer={alertDialogState.positiveAnswer}
                    negativeAnswer={alertDialogState.negativeAnswer}
                    title={alertDialogState.title}
                    description={alertDialogState.description}
                />
            }

            <h4>{admin.username}</h4>

            {(!!settings && (admin.gpt_settings_edit_perm === 1 || admin.service_provider_promotional_text_edit_perm === 1 || admin.about_app_edit_perm === 1 || admin.user_agreement_edit_perm === 1 || admin.privacy_policy_edit_perm === 1)) &&
                <IconButton onClick={handleMenuOpen}>
                    <Settings />
                </IconButton>
            }

            <IconButton onClick={() =>
                setAlertDialogState({
                    open: true,
                    onApprove: () => {
                        setToken(null);
                    },
                    title: <FormattedMessage id={"are_you_sure_to_logout"} />,
                    description: <FormattedMessage id={"logout_description"} />,
                    positiveAnswer: <FormattedMessage id={"logout"} />,
                    negativeAnswer: <FormattedMessage id={"cancel"} />
                })
            }>
                <LogoutIcon />
            </IconButton>
        </Box >
    )
}

export default Topbar
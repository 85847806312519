import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

function SubcategoryDialog({ subcategory, categories, dialogOpen, handleClose, handleFormSubmit }) {

    var initialValues = {
        ...(
            subcategory && categories?.length > 0 ?
                {
                    category_id: subcategory?.category_id ? subcategory?.category_id : categories[0].id
                }
                :
                {}
        ),
        name: subcategory?.name ? subcategory?.name : "",
        translations: subcategory?.translations && subcategory?.translations.length > 0 ? subcategory?.translations : [
            { language: "de", translation: "" }
        ]
    };

    var validationObject = {
        ...(
            subcategory && categories?.length > 0 ?
                {
                    category_id: yup.number().required(<FormattedMessage id="required" />)
                }
                :
                {}
        ),
        name: yup.string().required(<FormattedMessage id="required" />),
        translations: yup.array().of(yup.object().shape(
            {
                language: yup.string().required(<FormattedMessage id="required" />),
                translation: yup.string().required(<FormattedMessage id="required" />),
            }
        ).required(<FormattedMessage id="required" />)).required(<FormattedMessage id="required" />)
    }

    const subcategorySchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    {
                        subcategory ?
                            <FormattedMessage id="edit_subcategory" />
                            :
                            <FormattedMessage id="add_new_subcategory" />
                    }
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={subcategorySchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(1, minmax(0, 1fr))" mx="75px" my="25px">

                                    {
                                        subcategory && categories?.length > 0 ?
                                            <FormControl
                                                fullWidth
                                                variant="filled"
                                                sx={{ gridColumn: "span 1" }}>
                                                <InputLabel id="filter-label1">
                                                    <FormattedMessage id="category" />
                                                </InputLabel>
                                                <Select
                                                    labelId="filter-label1"
                                                    label={<FormattedMessage id="type" />}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    name={"category_id"}
                                                    value={values.category_id}
                                                    error={!!touched.category_id && !!errors.category_id}
                                                >
                                                    {categories.map((category, i) => (
                                                        <MenuItem value={category.id} key={i}>
                                                            {category.name}
                                                        </MenuItem>
                                                    ))
                                                    }
                                                </Select>
                                            </FormControl>
                                            :
                                            ""
                                    }

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={<FormattedMessage id="subcategory_name_with_language_code" values={{ language: "en" }} />}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"name"}
                                        value={values.name}
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                        sx={{ gridColumn: "span 1", my: "20px" }}
                                    />
                                    {values.translations.map((translation, i) =>

                                        <TextField
                                            key={`translation-${i}`}
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label={<FormattedMessage id="subcategory_name_with_language_code" values={{ language: translation.language }} />}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"translations[" + i + "].translation"}
                                            value={values.translations[i].translation}
                                            error={!!touched?.translations && !!touched?.translations[i] && !!touched?.translations[i]?.translation && !!errors?.translations && !!errors?.translations[i] && !!errors?.translations[i]?.translation}
                                            helperText={touched?.translations && touched?.translations[i] && touched?.translations[i]?.translation && errors?.translations && errors?.translations[i] && errors?.translations[i]?.translation}
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                    )}
                                </Box>

                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <Typography variant='h5'>
                                            <FormattedMessage id="save" />
                                        </Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default SubcategoryDialog
import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, Select, TextField, Typography, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import { tokens } from '../../../theme';
import { FormattedMessage, useIntl } from 'react-intl';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function CommercialDialog({ commercial, dialogOpen, handleClose, handleFormSubmit }) {
    const { formatMessage } = useIntl();
    const colors = tokens();

    var initialValues = {
        link: commercial?.link ? commercial?.link : "",
        content: null,
        content_link: commercial?.content_link ? commercial?.content_link : "",
        type: commercial?.type ? commercial?.type : "vertical",
        description: commercial?.description ? commercial?.description : "",
        postal_code: commercial?.postal_code ? commercial?.postal_code : "",
        distance_from_postal_code_in_km: commercial?.distance_from_postal_code_in_km ? commercial?.distance_from_postal_code_in_km : "",
        is_active: (commercial?.is_active === null || commercial?.is_active === undefined) ? true : (commercial?.is_active === 1 ? true : false),
        expiration_date: commercial?.expiration_date ? dayjs(commercial?.expiration_date) : null,
        account_type: commercial?.account_type ? commercial?.account_type : "",
        gender: commercial?.gender ? commercial?.gender : "",
        min_age: commercial?.min_age ? commercial?.min_age : 0,
        max_age: commercial?.max_age ? commercial?.max_age : 100,
        language: commercial?.language ? commercial?.language : "en",
        show_count_limit: commercial?.show_count_limit ? commercial?.show_count_limit : ""
    };

    var validationObject = {
        link: yup.string().required(<FormattedMessage id="required" />),
        content: yup.mixed().nullable().when('content_link', {
            is: (content_link) => (!content_link || content_link === ""),
            then: () => yup.string().nonNullable("").required(<FormattedMessage id="required" />)
        }),
        content_link: yup.string(),
        type: yup.string().required(<FormattedMessage id="required" />),
        description: yup.string().nullable().when('type', {
            is: (type) => type === "ad_like",
            then: () => yup.string().required(<FormattedMessage id="required" />)
        }),
        postal_code: yup.string().nullable(),
        distance_from_postal_code_in_km: yup.number().nullable().when('postal_code', {
            is: (postal_code) => postal_code && postal_code !== "",
            then: () => yup.number().min(1, formatMessage({ id: "min_value" }, { value: 1 })).required(<FormattedMessage id="required" />)
        }),
        is_active: yup.boolean().required(<FormattedMessage id="required" />),
        expiration_date: yup.date().nullable(),
        account_type: yup.number().nullable(),
        gender: yup.number().nullable(),
        min_age: yup.number().nullable().min(0, formatMessage({ id: "min_value" }, { value: 0 })).max(100, formatMessage({ id: "max_value" }, { value: 100 })),
        max_age: yup.number().nullable().min(0, formatMessage({ id: "min_value" }, { value: 0 })).max(100, formatMessage({ id: "max_value" }, { value: 100 })),
        language: yup.string().required(<FormattedMessage id="required" />),
        show_count_limit: yup.number().nullable().min(1, formatMessage({ id: "min_value" }, { value: 1 }))
    }

    const commercialSchema = yup.object().shape(validationObject);

    const checkIsPhoto = (values) => {
        if (!values) {
            return false;
        }
        if (values.content) {
            //check uploaded file is image or not
            if (values.content.type.includes("image")) {
                return true;
            } else {
                return false;
            }
        } else {
            if (values.content_link && values.content_link !== "") {
                if (values.content_link.includes("mp4") || values.content_link.includes("mov") || values.content_link.includes("flv")) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            fullWidth
            maxWidth="md"
            open={dialogOpen}
            onClose={handleClose}>
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    variant="standard"
                    color="error"
                    sx={{
                        borderRadius: "100%",
                        backgroundColor: "white",
                        maxWidth: "40px",
                        maxHeight: "40px",
                        minWidth: "40px",
                        minHeight: "40px",
                        position: "absolute",
                        top: "10px",
                        right: "10px"
                    }}
                    onClick={handleClose}>
                    <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                </Button>
            </Box>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    {
                        commercial ?
                            <FormattedMessage id="edit_commercial" />
                            :
                            <FormattedMessage id="add_new_commercial" />
                    }
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={commercialSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm }) => (
                            <form onSubmit={handleSubmit}>

                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="75px" my="25px">
                                    <Box textAlign="center" sx={{ gridColumn: "span 2" }}>
                                        <Typography variant="h6">
                                            <FormattedMessage id="commercial_content" />
                                        </Typography>
                                        <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[900], height: "150px", width: "150px" }}>
                                            {(values.content || (values.content_link && values.content_link !== "")) && <Box component={checkIsPhoto(values) ? "img" : "video"} controls alt='commercial' width="100px" src={values.content ? URL.createObjectURL(values.content) : values.content_link} />}
                                            <input id="content" name="content" type="file" accept=".png, .jpeg, .jpg .mov .mp4 .flv" onChange={(event) => { setFieldValue("content", event.target.files[0]); }} hidden />
                                        </Button>
                                    </Box>

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.is_active}
                                                size="small"
                                            />}
                                        variant="filled"
                                        label={formatMessage({ id: "commercials.table.columns.is_active" }).charAt(0).toUpperCase() + formatMessage({ id: "commercials.table.columns.is_active" }).slice(1).toLocaleLowerCase()}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"is_active"}
                                        value={values.is_active}
                                        sx={{ gridColumn: "span 1" }} />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={<FormattedMessage id="link" />}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"link"}
                                        value={values.link}
                                        error={!!touched.link && !!errors.link}
                                        helperText={touched.link && errors.link}
                                        sx={{ gridColumn: "span 2", mt: "20px" }}
                                    />

                                    <FormControl
                                        fullWidth
                                        variant="filled"
                                        sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="account-type-label">
                                            {formatMessage({ id: "type" })}
                                        </InputLabel>
                                        <Select
                                            labelId="account-type-label"
                                            label={formatMessage({ id: "type" })}
                                            onChange={(e) => setFieldValue("type", e.target.value)}
                                            value={values.type}
                                        >
                                            <MenuItem value={"vertical"}>
                                                <FormattedMessage id="commercials.table.columns.type.vertical" />
                                            </MenuItem>
                                            <MenuItem value={"horizontal"}>
                                                <FormattedMessage id="commercials.table.columns.type.horizontal" />
                                            </MenuItem>
                                            <MenuItem value={"ad_like"}>
                                                <FormattedMessage id="commercials.table.columns.type.ad_like" />
                                            </MenuItem>
                                        </Select>
                                    </FormControl>

                                    {values.type !== "ad_like" &&
                                        <Box sx={{ gridColumn: "span 1" }} />
                                    }

                                    {values.type === "ad_like" &&
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label={formatMessage({ id: "table.columns.description" }).charAt(0).toUpperCase() + formatMessage({ id: "table.columns.description" }).slice(1).toLocaleLowerCase()}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"description"}
                                            value={values.description}
                                            error={!!touched.description && !!errors.description}
                                            helperText={touched.description && errors.description}
                                            sx={{ gridColumn: "span 2" }}
                                        />
                                    }

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "commercials.table.columns.postal_code" }).charAt(0).toUpperCase() + formatMessage({ id: "commercials.table.columns.postal_code" }).slice(1).toLocaleLowerCase()}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"postal_code"}
                                        value={values.postal_code}
                                        error={!!touched.postal_code && !!errors.postal_code}
                                        helperText={touched.postal_code && errors.postal_code}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                    {!!(values.postal_code) &&
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="number"
                                            label={formatMessage({ id: "commercials.table.columns.distance_in_km" }).charAt(0).toUpperCase() + formatMessage({ id: "commercials.table.columns.distance_in_km" }).slice(1).toLocaleLowerCase()}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"distance_from_postal_code_in_km"}
                                            value={values.distance_from_postal_code_in_km}
                                            error={!!touched.distance_from_postal_code_in_km && !!errors.distance_from_postal_code_in_km}
                                            helperText={touched.distance_from_postal_code_in_km && errors.distance_from_postal_code_in_km}
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                    }

                                    {!(values.postal_code) &&
                                        <Box sx={{ gridColumn: "span 1" }} />
                                    }


                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            fullWidth
                                            variant="filled"
                                            label={formatMessage({ id: "expiration_date_with_explanation" })}
                                            onBlur={handleBlur}
                                            onChange={(date) => setFieldValue("expiration_date", date)}
                                            name={"expiration_date"}
                                            value={values.expiration_date}
                                            error={!!touched.expiration_date && !!errors.expiration_date}
                                            helperText={touched.expiration_date && errors.expiration_date}
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                    </LocalizationProvider>

                                    <FormControl
                                        fullWidth
                                        variant="filled"
                                        sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="account-type-label">
                                            {formatMessage({ id: "users.table.columns.account_type" }).charAt(0).toUpperCase() + formatMessage({ id: "users.table.columns.account_type" }).slice(1).toLocaleLowerCase()}
                                        </InputLabel>
                                        <Select
                                            labelId="account-type-label"
                                            label={formatMessage({ id: "users.table.columns.account_type" }).charAt(0).toUpperCase() + formatMessage({ id: "users.table.columns.account_type" }).slice(1).toLocaleLowerCase()}
                                            onChange={(e) => setFieldValue("account_type", e.target.value)}
                                            value={values.account_type}
                                        >
                                            <MenuItem value={""}>
                                                <FormattedMessage id="not_selected" />
                                            </MenuItem>
                                            <MenuItem value={0}>
                                                <FormattedMessage id="users.account_type.option.personal" />
                                            </MenuItem>
                                            <MenuItem value={1}>
                                                <FormattedMessage id="users.account_type.option.corporate" />
                                            </MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl
                                        fullWidth
                                        variant="filled"
                                        sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="gender-label">
                                            {formatMessage({ id: "users.table.columns.gender" }).charAt(0).toUpperCase() + formatMessage({ id: "users.table.columns.gender" }).slice(1).toLocaleLowerCase()}
                                        </InputLabel>
                                        <Select
                                            labelId="gender-label"
                                            label={formatMessage({ id: "users.table.columns.gender" }).charAt(0).toUpperCase() + formatMessage({ id: "users.table.columns.gender" }).slice(1).toLocaleLowerCase()}
                                            onChange={(e) => setFieldValue("gender", e.target.value)}
                                            value={values.gender}
                                        >
                                            <MenuItem value={""}>
                                                <FormattedMessage id="not_selected" />
                                            </MenuItem>
                                            <MenuItem value={0}>
                                                <FormattedMessage id="users.gender.option.male" />
                                            </MenuItem>
                                            <MenuItem value={1}>
                                                <FormattedMessage id="users.gender.option.female" />
                                            </MenuItem>
                                            <MenuItem value={2}>
                                                <FormattedMessage id="users.gender.option.secret" />
                                            </MenuItem>
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "commercials.table.columns.min_age" }).charAt(0).toUpperCase() + formatMessage({ id: "commercials.table.columns.min_age" }).slice(1).toLocaleLowerCase()}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"min_age"}
                                        value={values.min_age}
                                        error={!!touched.min_age && !!errors.min_age}
                                        helperText={touched.min_age && errors.min_age}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "commercials.table.columns.max_age" }).charAt(0).toUpperCase() + formatMessage({ id: "commercials.table.columns.max_age" }).slice(1).toLocaleLowerCase()}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"max_age"}
                                        value={values.max_age}
                                        error={!!touched.max_age && !!errors.max_age}
                                        helperText={touched.max_age && errors.max_age}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                    <FormControl
                                        fullWidth
                                        variant="filled"
                                        sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="language-label">
                                            {formatMessage({ id: "commercials.table.columns.language" }).charAt(0).toUpperCase() + formatMessage({ id: "commercials.table.columns.language" }).slice(1).toLocaleLowerCase()}
                                        </InputLabel>
                                        <Select
                                            labelId="language-label"
                                            label={formatMessage({ id: "commercials.table.columns.language" }).charAt(0).toUpperCase() + formatMessage({ id: "commercials.table.columns.language" }).slice(1).toLocaleLowerCase()}
                                            onChange={(e) => setFieldValue("language", e.target.value)}
                                            value={values.language}
                                        >
                                            <MenuItem value={"en"}>
                                                <FormattedMessage id="en" />
                                            </MenuItem>
                                            <MenuItem value={"de"}>
                                                <FormattedMessage id="de" />
                                            </MenuItem>
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={formatMessage({ id: "commercials.table.columns.show_count_limit" }).charAt(0).toUpperCase() + formatMessage({ id: "commercials.table.columns.show_count_limit" }).slice(1).toLocaleLowerCase()}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"show_count_limit"}
                                        value={values.show_count_limit}
                                        error={!!touched.show_count_limit && !!errors.show_count_limit}
                                        helperText={touched.show_count_limit && errors.show_count_limit}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                </Box>

                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px" gap={"10px"}>
                                    <Button type="button" color="warning" variant="contained" onClick={() => {
                                        resetForm();
                                    }}>
                                        <FormattedMessage id="reset" />
                                    </Button>
                                    <Button type="button" color="error" variant="contained" onClick={handleClose}>
                                        <FormattedMessage id="cancel" />

                                    </Button>
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <FormattedMessage id="save" />

                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default CommercialDialog
import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

function AdminDialog({ targetAdmin, dialogOpen, handleClose, handleFormSubmit }) {

    var initialValues = {
        username: targetAdmin?.username ? targetAdmin?.username : "",
        ...(!targetAdmin ? { password: "" } : {}),
        dashboard_perm: targetAdmin?.dashboard_perm !== undefined && targetAdmin?.dashboard_perm !== null ? targetAdmin?.dashboard_perm : 0,
        users_perm: targetAdmin?.users_perm !== undefined && targetAdmin?.users_perm !== null ? targetAdmin?.users_perm : 0,
        categories_perm: targetAdmin?.categories_perm !== undefined && targetAdmin?.categories_perm !== null ? targetAdmin?.categories_perm : 0,
        advertisements_perm: targetAdmin?.advertisements_perm !== undefined && targetAdmin?.advertisements_perm !== null ? targetAdmin?.advertisements_perm : 0,
        onboardings_perm: targetAdmin?.onboardings_perm !== undefined && targetAdmin?.onboardings_perm !== null ? targetAdmin?.onboardings_perm : 0,
        reports_perm: targetAdmin?.reports_perm !== undefined && targetAdmin?.reports_perm !== null ? targetAdmin?.reports_perm : 0,
        contact_messages_perm: targetAdmin?.contact_messages_perm !== undefined && targetAdmin?.contact_messages_perm !== null ? targetAdmin?.contact_messages_perm : 0,
        faqs_perm: targetAdmin?.faqs_perm !== undefined && targetAdmin?.faqs_perm !== null ? targetAdmin?.faqs_perm : 0,
        forbidden_words_perm: targetAdmin?.forbidden_words_perm !== undefined && targetAdmin?.forbidden_words_perm !== null ? targetAdmin?.forbidden_words_perm : 0,
        commercials_perm: targetAdmin?.commercials_perm !== undefined && targetAdmin?.commercials_perm !== null ? targetAdmin?.commercials_perm : 0,
        notifications_perm: targetAdmin?.notifications_perm !== undefined && targetAdmin?.notifications_perm !== null ? targetAdmin?.notifications_perm : 0,
        admins_perm: targetAdmin?.admins_perm !== undefined && targetAdmin?.admins_perm !== null ? targetAdmin?.admins_perm : 0,
        user_edit_perm: targetAdmin?.user_edit_perm !== undefined && targetAdmin?.user_edit_perm !== null ? targetAdmin?.user_edit_perm : 0,
        user_suspension_perm: targetAdmin?.user_suspension_perm !== undefined && targetAdmin?.user_suspension_perm !== null ? targetAdmin?.user_suspension_perm : 0,
        user_deletion_perm: targetAdmin?.user_deletion_perm !== undefined && targetAdmin?.user_deletion_perm !== null ? targetAdmin?.user_deletion_perm : 0,
        category_edit_perm: targetAdmin?.category_edit_perm !== undefined && targetAdmin?.category_edit_perm !== null ? targetAdmin?.category_edit_perm : 0,
        category_deletion_perm: targetAdmin?.category_deletion_perm !== undefined && targetAdmin?.category_deletion_perm !== null ? targetAdmin?.category_deletion_perm : 0,
        advertisement_edit_perm: targetAdmin?.advertisement_edit_perm !== undefined && targetAdmin?.advertisement_edit_perm !== null ? targetAdmin?.advertisement_edit_perm : 0,
        advertisement_deletion_perm: targetAdmin?.advertisement_deletion_perm !== undefined && targetAdmin?.advertisement_deletion_perm !== null ? targetAdmin?.advertisement_deletion_perm : 0,
        comment_edit_perm: targetAdmin?.comment_edit_perm !== undefined && targetAdmin?.comment_edit_perm !== null ? targetAdmin?.comment_edit_perm : 0,
        comment_deletion_perm: targetAdmin?.comment_deletion_perm !== undefined && targetAdmin?.comment_deletion_perm !== null ? targetAdmin?.comment_deletion_perm : 0,
        direct_notification_perm: targetAdmin?.direct_notification_perm !== undefined && targetAdmin?.direct_notification_perm !== null ? targetAdmin?.direct_notification_perm : 0,
        advertisement_notification_perm: targetAdmin?.advertisement_notification_perm !== undefined && targetAdmin?.advertisement_notification_perm !== null ? targetAdmin?.advertisement_notification_perm : 0,
        comment_notification_perm: targetAdmin?.comment_notification_perm !== undefined && targetAdmin?.comment_notification_perm !== null ? targetAdmin?.comment_notification_perm : 0,
        report_notification_perm: targetAdmin?.report_notification_perm !== undefined && targetAdmin?.report_notification_perm !== null ? targetAdmin?.report_notification_perm : 0,
        contact_message_notification_perm: targetAdmin?.contact_message_notification_perm !== undefined && targetAdmin?.contact_message_notification_perm !== null ? targetAdmin?.contact_message_notification_perm : 0,
        resend_notification_perm: targetAdmin?.resend_notification_perm !== undefined && targetAdmin?.resend_notification_perm !== null ? targetAdmin?.resend_notification_perm : 0,
        notification_drafts_perm: targetAdmin?.notification_drafts_perm !== undefined && targetAdmin?.notification_drafts_perm !== null ? targetAdmin?.notification_drafts_perm : 0,
        report_edit_perm: targetAdmin?.report_edit_perm !== undefined && targetAdmin?.report_edit_perm !== null ? targetAdmin?.report_edit_perm : 0,
        report_deletion_perm: targetAdmin?.report_deletion_perm !== undefined && targetAdmin?.report_deletion_perm !== null ? targetAdmin?.report_deletion_perm : 0,
        faq_edit_perm: targetAdmin?.faq_edit_perm !== undefined && targetAdmin?.faq_edit_perm !== null ? targetAdmin?.faq_edit_perm : 0,
        faq_deletion_perm: targetAdmin?.faq_deletion_perm !== undefined && targetAdmin?.faq_deletion_perm !== null ? targetAdmin?.faq_deletion_perm : 0,
        commercial_create_perm: targetAdmin?.commercial_create_perm !== undefined && targetAdmin?.commercial_create_perm !== null ? targetAdmin?.commercial_create_perm : 0,
        commercial_edit_perm: targetAdmin?.commercial_edit_perm !== undefined && targetAdmin?.commercial_edit_perm !== null ? targetAdmin?.commercial_edit_perm : 0,
        commercial_deletion_perm: targetAdmin?.commercial_deletion_perm !== undefined && targetAdmin?.commercial_deletion_perm !== null ? targetAdmin?.commercial_deletion_perm : 0,
        gpt_settings_edit_perm: targetAdmin?.gpt_settings_edit_perm !== undefined && targetAdmin?.gpt_settings_edit_perm !== null ? targetAdmin?.gpt_settings_edit_perm : 0,
        service_provider_promotional_text_edit_perm: targetAdmin?.service_provider_promotional_text_edit_perm !== undefined && targetAdmin?.service_provider_promotional_text_edit_perm !== null ? targetAdmin?.service_provider_promotional_text_edit_perm : 0,
        about_app_edit_perm: targetAdmin?.about_app_edit_perm !== undefined && targetAdmin?.about_app_edit_perm !== null ? targetAdmin?.about_app_edit_perm : 0,
        user_agreement_edit_perm: targetAdmin?.user_agreement_edit_perm !== undefined && targetAdmin?.user_agreement_edit_perm !== null ? targetAdmin?.user_agreement_edit_perm : 0,
        privacy_policy_edit_perm: targetAdmin?.privacy_policy_edit_perm !== undefined && targetAdmin?.privacy_policy_edit_perm !== null ? targetAdmin?.privacy_policy_edit_perm : 0,
        admin_edit_perm: targetAdmin?.admin_edit_perm !== undefined && targetAdmin?.admin_edit_perm !== null ? targetAdmin?.admin_edit_perm : 0,
    }

    var validationObject = {
        username: yup.string().required(<FormattedMessage id="required" />),
        ...(!targetAdmin ? { password: yup.string().required(<FormattedMessage id="required" />) } : {}),
        dashboard_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        users_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        categories_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        advertisements_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        onboardings_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        reports_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        contact_messages_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        faqs_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        forbidden_words_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        commercials_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        notifications_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        admins_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        user_edit_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        user_suspension_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        user_deletion_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        category_edit_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        category_deletion_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        advertisement_edit_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        advertisement_deletion_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        comment_edit_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        comment_deletion_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        direct_notification_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        advertisement_notification_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        comment_notification_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        report_notification_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        contact_message_notification_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        resend_notification_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        notification_drafts_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        report_edit_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        report_deletion_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        faq_edit_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        faq_deletion_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        commercial_create_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        commercial_edit_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        commercial_deletion_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        gpt_settings_edit_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        service_provider_promotional_text_edit_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        about_app_edit_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        user_agreement_edit_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        privacy_policy_edit_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
        admin_edit_perm: yup.number().min(0, <FormattedMessage id="please_select" />).required(<FormattedMessage id="required" />),
    }

    const adminSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            open={dialogOpen}
            fullWidth
            maxWidth="md"
            sx={{ margin: "0 auto" }}
            onClose={(e, reason) => {
                if (reason && reason === "backdropClick") {
                    return;
                }
                handleClose();
            }}>
            <Box m="20px" mx="150px" textAlign="center">
                {targetAdmin &&
                    <Typography variant='h3'>
                        <FormattedMessage id="edit_admin" />
                    </Typography>
                }
                {!targetAdmin &&
                    <Typography variant='h3'>
                        <FormattedMessage id="add_new_admin" />
                    </Typography>
                }
                <Button
                    variant="standard"
                    color="error"
                    sx={{
                        borderRadius: "100%",
                        backgroundColor: "white",
                        maxWidth: "40px",
                        maxHeight: "40px",
                        minWidth: "40px",
                        minHeight: "40px",
                        position: "absolute",
                        top: "10px",
                        right: "10px"
                    }}
                    onClick={handleClose}>
                    <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                </Button>
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={adminSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))" mx="25px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={<FormattedMessage id="username" />}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"username"}
                                        value={values.username}
                                        error={!!touched.username && !!errors.username}
                                        helperText={touched.username && errors.username}
                                        sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                    />
                                    {!targetAdmin &&
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label={<FormattedMessage id="password" />}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"password"}
                                            value={values.password}
                                            error={!!touched.password && !!errors.password}
                                            helperText={touched.password && errors.password}
                                            sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                        />}

                                    <Typography gridColumn="span 3" variant='h4' mt="20px">
                                        <FormattedMessage id="permissions" />
                                    </Typography>
                                    <PermSelectFormControl
                                        label={<FormattedMessage id="dashboard_perm" />}
                                        name="dashboard_perm"
                                        value={values.dashboard_perm}
                                        error={!!touched.dashboard_perm && !!errors.dashboard_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="users_perm" />}
                                        name="users_perm"
                                        value={values.users_perm}
                                        error={!!touched.users_perm && !!errors.users_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="categories_perm" />}
                                        name="categories_perm"
                                        value={values.categories_perm}
                                        error={!!touched.categories_perm && !!errors.categories_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="advertisements_perm" />}
                                        name="advertisements_perm"
                                        value={values.advertisements_perm}
                                        error={!!touched.advertisements_perm && !!errors.advertisements_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="onboardings_perm" />}
                                        name="onboardings_perm"
                                        value={values.onboardings_perm}
                                        error={!!touched.onboardings_perm && !!errors.onboardings_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="reports_perm" />}
                                        name="reports_perm"
                                        value={values.reports_perm}
                                        error={!!touched.reports_perm && !!errors.reports_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="contact_messages_perm" />}
                                        name="contact_messages_perm"
                                        value={values.contact_messages_perm}
                                        error={!!touched.contact_messages_perm && !!errors.contact_messages_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="faqs_perm" />}
                                        name="faqs_perm"
                                        value={values.faqs_perm}
                                        error={!!touched.faqs_perm && !!errors.faqs_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="forbidden_words_perm" />}
                                        name="forbidden_words_perm"
                                        value={values.forbidden_words_perm}
                                        error={!!touched.forbidden_words_perm && !!errors.forbidden_words_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="commercials_perm" />}
                                        name="commercials_perm"
                                        value={values.commercials_perm}
                                        error={!!touched.commercials_perm && !!errors.commercials_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="notifications_perm" />}
                                        name="notifications_perm"
                                        value={values.notifications_perm}
                                        error={!!touched.notifications_perm && !!errors.notifications_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="admins_perm" />}
                                        name="admins_perm"
                                        value={values.admins_perm}
                                        error={!!touched.admins_perm && !!errors.admins_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="user_edit_perm" />}
                                        name="user_edit_perm"
                                        value={values.user_edit_perm}
                                        error={!!touched.user_edit_perm && !!errors.user_edit_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="user_suspension_perm" />}
                                        name="user_suspension_perm"
                                        value={values.user_suspension_perm}
                                        error={!!touched.user_suspension_perm && !!errors.user_suspension_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="user_deletion_perm" />}
                                        name="user_deletion_perm"
                                        value={values.user_deletion_perm}
                                        error={!!touched.user_deletion_perm && !!errors.user_deletion_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="category_edit_perm" />}
                                        name="category_edit_perm"
                                        value={values.category_edit_perm}
                                        error={!!touched.category_edit_perm && !!errors.category_edit_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="category_deletion_perm" />}
                                        name="category_deletion_perm"
                                        value={values.category_deletion_perm}
                                        error={!!touched.category_deletion_perm && !!errors.category_deletion_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="advertisement_edit_perm" />}
                                        name="advertisement_edit_perm"
                                        value={values.advertisement_edit_perm}
                                        error={!!touched.advertisement_edit_perm && !!errors.advertisement_edit_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="advertisement_deletion_perm" />}
                                        name="advertisement_deletion_perm"
                                        value={values.advertisement_deletion_perm}
                                        error={!!touched.advertisement_deletion_perm && !!errors.advertisement_deletion_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="comment_edit_perm" />}
                                        name="comment_edit_perm"
                                        value={values.comment_edit_perm}
                                        error={!!touched.comment_edit_perm && !!errors.comment_edit_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="comment_deletion_perm" />}
                                        name="comment_deletion_perm"
                                        value={values.comment_deletion_perm}
                                        error={!!touched.comment_deletion_perm && !!errors.comment_deletion_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="direct_notification_perm" />}
                                        name="direct_notification_perm"
                                        value={values.direct_notification_perm}
                                        error={!!touched.direct_notification_perm && !!errors.direct_notification_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="advertisement_notification_perm" />}
                                        name="advertisement_notification_perm"
                                        value={values.advertisement_notification_perm}
                                        error={!!touched.advertisement_notification_perm && !!errors.advertisement_notification_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="comment_notification_perm" />}
                                        name="comment_notification_perm"
                                        value={values.comment_notification_perm}
                                        error={!!touched.comment_notification_perm && !!errors.comment_notification_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="report_notification_perm" />}
                                        name="report_notification_perm"
                                        value={values.report_notification_perm}
                                        error={!!touched.report_notification_perm && !!errors.report_notification_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="contact_message_notification_perm" />}
                                        name="contact_message_notification_perm"
                                        value={values.contact_message_notification_perm}
                                        error={!!touched.contact_message_notification_perm && !!errors.contact_message_notification_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="resend_notification_perm" />}
                                        name="resend_notification_perm"
                                        value={values.resend_notification_perm}
                                        error={!!touched.resend_notification_perm && !!errors.resend_notification_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="notification_drafts_perm" />}
                                        name="notification_drafts_perm"
                                        value={values.notification_drafts_perm}
                                        error={!!touched.notification_drafts_perm && !!errors.notification_drafts_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="report_edit_perm" />}
                                        name="report_edit_perm"
                                        value={values.report_edit_perm}
                                        error={!!touched.report_edit_perm && !!errors.report_edit_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="report_deletion_perm" />}
                                        name="report_deletion_perm"
                                        value={values.report_deletion_perm}
                                        error={!!touched.report_deletion_perm && !!errors.report_deletion_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="faq_edit_perm" />}
                                        name="faq_edit_perm"
                                        value={values.faq_edit_perm}
                                        error={!!touched.faq_edit_perm && !!errors.faq_edit_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="faq_deletion_perm" />}
                                        name="faq_deletion_perm"
                                        value={values.faq_deletion_perm}
                                        error={!!touched.faq_deletion_perm && !!errors.faq_deletion_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="commercial_create_perm" />}
                                        name="commercial_create_perm"
                                        value={values.commercial_create_perm}
                                        error={!!touched.commercial_create_perm && !!errors.commercial_create_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="commercial_edit_perm" />}
                                        name="commercial_edit_perm"
                                        value={values.commercial_edit_perm}
                                        error={!!touched.commercial_edit_perm && !!errors.commercial_edit_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="commercial_deletion_perm" />}
                                        name="commercial_deletion_perm"
                                        value={values.commercial_deletion_perm}
                                        error={!!touched.commercial_deletion_perm && !!errors.commercial_deletion_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="gpt_settings_edit_perm" />}
                                        name="gpt_settings_edit_perm"
                                        value={values.gpt_settings_edit_perm}
                                        error={!!touched.gpt_settings_edit_perm && !!errors.gpt_settings_edit_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="service_provider_promotional_text_edit_perm" />}
                                        name="service_provider_promotional_text_edit_perm"
                                        value={values.service_provider_promotional_text_edit_perm}
                                        error={!!touched.service_provider_promotional_text_edit_perm && !!errors.service_provider_promotional_text_edit_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="about_app_edit_perm" />}
                                        name="about_app_edit_perm"
                                        value={values.about_app_edit_perm}
                                        error={!!touched.about_app_edit_perm && !!errors.about_app_edit_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="user_agreement_edit_perm" />}
                                        name="user_agreement_edit_perm"
                                        value={values.user_agreement_edit_perm}
                                        error={!!touched.user_agreement_edit_perm && !!errors.user_agreement_edit_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="privacy_policy_edit_perm" />}
                                        name="privacy_policy_edit_perm"
                                        value={values.privacy_policy_edit_perm}
                                        error={!!touched.privacy_policy_edit_perm && !!errors.privacy_policy_edit_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <PermSelectFormControl
                                        label={<FormattedMessage id="admin_edit_perm" />}
                                        name="admin_edit_perm"
                                        value={values.admin_edit_perm}
                                        error={!!touched.admin_edit_perm && !!errors.admin_edit_perm}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px" gap="10px">
                                    <Button type="button" color="warning" variant="contained" onClick={() => {
                                        resetForm();
                                    }}>
                                        <FormattedMessage id="reset" />
                                    </Button>
                                    <Button type="button" color="error" variant="contained" onClick={handleClose}>
                                        <FormattedMessage id="cancel" />

                                    </Button>
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <FormattedMessage id="save" />

                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

function PermSelectFormControl({ label, name, value, error, onChange, onBlur }) {
    return (
        <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
            <InputLabel id={`${label}-label`}>{label}</InputLabel>
            <Select
                labelId={`${label}-label`}
                label={label}
                onBlur={onBlur}
                onChange={onChange}
                name={name}
                value={value}
                error={!!error}
            >
                <MenuItem value={0}><FormattedMessage id="no" /></MenuItem>
                <MenuItem value={1}><FormattedMessage id="yes" /></MenuItem>
            </Select>
        </FormControl>
    )
}

export default AdminDialog
import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

function ForbiddenWordDialog({ forbiddenWord, dialogOpen, handleClose, handleFormSubmit }) {

    var initialValues = {
        word: forbiddenWord?.word ? forbiddenWord.word : "",
    }

    var validationObject = {
        word: yup.string().required(<FormattedMessage id="required" />)
    }
    const forbiddenWordSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            fullWidth
            {...(
                forbiddenWord ?
                    { maxWidth: "xs" }
                    :
                    { maxWidth: "md" }
            )}
            open={dialogOpen}
            onClose={handleClose}>
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    variant="standard"
                    color="error"
                    sx={{
                        borderRadius: "100%",
                        backgroundColor: "white",
                        maxWidth: "40px",
                        maxHeight: "40px",
                        minWidth: "40px",
                        minHeight: "40px",
                        position: "absolute",
                        top: "10px",
                        right: "10px"
                    }}
                    onClick={handleClose}>
                    <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                </Button>
            </Box>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    {
                        forbiddenWord ?
                            <FormattedMessage id="edit_forbidden_word" />
                            :
                            <FormattedMessage id="add_new_forbidden_words" />
                    }
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={forbiddenWordSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>

                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(1, minmax(0, 1fr))" mx="75px" my="25px">

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        {...(
                                            forbiddenWord ?
                                                {}
                                                :
                                                { multiline: true, rows: 4 }
                                        )
                                        }
                                        label={forbiddenWord ? <FormattedMessage id="word" /> : <FormattedMessage id="words" />}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"word"}
                                        value={values.word}
                                        error={!!touched.word && !!errors.word}
                                        helperText={touched.word && errors.word}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                </Box>

                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <Typography variant='h5'>
                                            <FormattedMessage id="save" />
                                        </Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default ForbiddenWordDialog
import { Box, Button, Snackbar } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import AlertDialogSlide from '../../components/AlertDialogSlide';
import { Table } from '../../components/Table';
import { FormattedMessage, useIntl } from 'react-intl';
import CommercialDialog from './CommercialDialog';

function CommercialsPage() {
    const { admin, token } = useContext(AdminContext);
    const { formatMessage } = useIntl();
    const [commercials, setCommercials] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [orderModel, setOrderModel] = useState(null);
    const [filterModel, setFilterModel] = useState(null);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        rowsPerPage: admin.commercials_table_row_count
    });
    const [commercialEvent, setCommercialEvent] = useState({ commercial: null, type: null });
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [snackbarState, setSnackbarState] = useState({ open: false, message: "" });
    const handleSnackbarOpen = (message) => {
        setSnackbarState({ open: true, message: message });
    };

    const handleSnackbarClose = () => {
        setSnackbarState({ open: false, message: "" });
    };

    const columns = [
        {
            id: 'id',
            name: <FormattedMessage id={"table.columns.id"} />,
            type: 'number',
            sortable: true,
            filterable: true
        }, {
            id: 'content_link',
            name: <FormattedMessage id={"table.columns.content_link"} />,
            sortable: false,
            filterable: false,
            minWidth: 200,
            render: (value, row) => {
                return <Box
                    component={row.is_photo ? "img" : "video"}
                    controls
                    borderRadius="12px"
                    src={value}
                    alt={row.type + " commercial"}
                    width={166}
                    height={100}
                    style={{
                        objectFit: row.is_photo ? "cover" : "contain",
                    }}
                />
            }
        }, {
            id: 'type',
            name: <FormattedMessage id={"commercials.table.columns.type"} />,
            type: 'select',
            options: [
                { value: "vertical", label: <FormattedMessage id={"commercials.table.columns.type.vertical"} /> },
                { value: "horizontal", label: <FormattedMessage id={"commercials.table.columns.type.horizontal"} /> },
                { value: "ad_like", label: <FormattedMessage id={"commercials.table.columns.type.ad_like"} /> }
            ],
            sortable: true,
            filterable: true,
            render: (value) => <FormattedMessage id={`commercials.table.columns.type.${value}`} />
        }, {
            id: 'postal_code',
            name: <FormattedMessage id={"commercials.table.columns.postal_code"} />,
            type: 'text',
            sortable: true,
            filterable: true,
            render: (value) => {
                if (value) {
                    return value;
                } else {
                    return "-";
                }
            }
        }, {
            id: 'distance_from_postal_code_in_km',
            name: <FormattedMessage id={"commercials.table.columns.distance_in_km"} />,
            type: 'number',
            sortable: true,
            filterable: true,
            render: (value, row) => {
                if (value && row.postal_code) {
                    return value;
                } else {
                    return "-";
                }
            }
        }, {
            id: 'is_active',
            name: <FormattedMessage id={"commercials.table.columns.is_active"} />,
            type: 'boolean',
            sortable: true,
            filterable: true
        },
        {
            id: "expiration_date",
            name: <FormattedMessage id={"advertisements.table.columns.expiration_date"} />,
            type: 'date',
            sortable: true, filterable: true,
            render: (value) => {
                if (value) {
                    return new Date(value).toLocaleDateString()
                } else {
                    return "-";
                }
            }
        }, {
            id: 'account_type',
            name: formatMessage({ id: "users.table.columns.account_type" }),
            type: 'select',
            options: [{ value: 0, label: <FormattedMessage id={"users.account_type.option.personal"} /> }, { value: 1, label: <FormattedMessage id={"users.account_type.option.corporate"} /> }],
            sortable: true, filterable: true,
            render: (value) => {
                if (value !== undefined && value !== null) {
                    return value === 0 ? formatMessage({ id: "users.account_type.option.personal" }) : formatMessage({ id: "users.account_type.option.corporate" })
                } else {
                    return "-";
                }
            }
        }, {
            id: 'gender',
            name: formatMessage({ id: "users.table.columns.gender" }),
            type: 'select',
            options: [
                { value: 0, label: <FormattedMessage id={"users.gender.option.male"} /> },
                { value: 1, label: <FormattedMessage id={"users.gender.option.female"} /> },
                { value: 2, label: <FormattedMessage id={"users.gender.option.secret"} /> }
            ],
            sortable: true, filterable: true,
            render: (value, row) => {
                if (value !== undefined && value !== null) {
                    return value === 0 ? formatMessage({ id: "users.gender.option.male" }) : value === 1 ? formatMessage({ id: "users.gender.option.female" }) : formatMessage({ id: "users.gender.option.secret" })
                } else {
                    return "-"
                }
            }
        }, {
            id: "min_age",
            name: <FormattedMessage id={"commercials.table.columns.min_age"} />,
            type: 'number',
            sortable: true, filterable: true,
            render: (value) => {
                if (value !== undefined && value !== null) {
                    return value;
                } else {
                    return "-";
                }
            }
        }, {
            id: "max_age",
            name: <FormattedMessage id={"commercials.table.columns.min_age"} />,
            type: 'number',
            sortable: true, filterable: true,
            render: (value) => {
                if (value !== undefined && value !== null) {
                    return value;
                } else {
                    return "-";
                }
            }
        }, {
            id: "language",
            name: <FormattedMessage id={"commercials.table.columns.language"} />,
            type: 'select',
            options: [
                { value: "en", label: "English" },
                { value: "de", label: "Deutsch" }
            ],
            sortable: true, filterable: true,
            render: (value) => <FormattedMessage id={value} />
        }, {
            id: 'show_count_limit',
            name: <FormattedMessage id={"commercials.table.columns.show_count_limit"} />,
            type: 'number',
            sortable: true,
            filterable: true,
            render: (value) => {
                if (value !== undefined && value !== null) {
                    return value;
                } else {
                    return "-";
                }
            }
        }, {
            id: 'show_count',
            name: <FormattedMessage id={"commercials.table.columns.show_count"} />,
            type: 'number',
            sortable: true,
            filterable: true,
        }, {
            id: 'click_count',
            name: <FormattedMessage id={"commercials.table.columns.click_count"} />,
            type: 'number',
            sortable: true,
            filterable: true,
        }, {
            id: "created_date",
            name: <FormattedMessage id={"table.columns.created_date"} />,
            type: 'date',
            sortable: true, filterable: true,
            render: (value) => {
                return new Date(value).toLocaleDateString()
            }
        }, {
            id: 'actions',
            name: <FormattedMessage id={"table.columns.actions"} />,
            type: 'actions',
            sortable: false, filterable: false,
            getActions: () => [
                {
                    label: <FormattedMessage id={"edit"} />,
                    action: (row) => {
                        setCommercialEvent({
                            commercial: {
                                ...row,
                            }, type: "edit"
                        });
                    }
                },
                {
                    label: <FormattedMessage id={"delete"} />,
                    action: (row) => {
                        setCommercialEvent({ commercial: row, type: "delete" })
                    }
                }
            ]
        }
    ];

    const fetchCommercials = (paginationModel, orderModel, filterModel, token) => {
        setLoading(true);
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/getCommercials`,
            {
                startIndex: paginationModel.page * paginationModel.rowsPerPage,
                limit: paginationModel.rowsPerPage,
                orderBy: orderModel ? orderModel.orderBy : null,
                orderType: orderModel ? orderModel.orderType : null,
                filter: filterModel ? filterModel : null
            },
            { headers: { token: token } }
        )
            .catch((err) => {
                setCommercials([]);
                setLoading(false);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setCommercials(response.data.commercials);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                }
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchCommercials(paginationModel, orderModel, filterModel, token);
    }, [paginationModel, orderModel, filterModel, token]);

    useEffect(() => {
        if ((commercialEvent?.commercial && commercialEvent?.type === "edit") || (!commercialEvent?.commercial && commercialEvent?.type === "add")) {
            setEditDialogOpen(true);
        }
        else if (commercialEvent?.commercial && commercialEvent?.type === "delete") {
            setRemoveDialogOpen(true);
        }
    }, [commercialEvent]);

    const addCommercial = async (commercial) => {
        if (commercial.content) {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadCommercialContent`, {
                commercialContent: commercial.content
            }, {
                headers: {
                    token: token,
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        commercial.content_path = response.data.contentPath;
                    }
                });
        }

        delete commercial.content;
        delete commercial.content_link;

        await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/createCommercial`, {
            ...commercial
        }, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setCommercialEvent({ commercial: null, type: null });
                    setEditDialogOpen(false);
                    fetchCommercials(paginationModel, orderModel, filterModel, token);
                    handleSnackbarOpen(<FormattedMessage id={"commercial_added_successfully"} />);
                }
            });
    }

    const updateCommercial = async (commercial) => {
        if (commercial.content) {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadCommercialContent`, {
                commercialContent: commercial.content
            }, {
                headers: {
                    token: token,
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        commercial.content_path = response.data.contentPath;
                    }
                });
        }

        var commercialToUpdate = { ...commercial };
        delete commercialToUpdate.content;
        delete commercialToUpdate.content_link;

        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateCommercial?commercialId=${commercialEvent.commercial.id}`, {
            commercial: commercialToUpdate
        }, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchCommercials(paginationModel, orderModel, filterModel, token);
                    setCommercialEvent({ commercial: null, type: null });
                    setEditDialogOpen(false);
                    handleSnackbarOpen(<FormattedMessage id={"commercial_updated_successfully"} />);
                }
            });
    }

    const handleCommercialFormSubmit = async (commercial) => {
        let newCommercial = { ...commercial };
        //set values null whick is === ""
        Object.keys(newCommercial).forEach((key) => {
            if (newCommercial[key] === "") {
                newCommercial[key] = null;
            }
        });

        if (newCommercial.expiration_date) {
            newCommercial.expiration_date = newCommercial.expiration_date.format("YYYY-MM-DD");
        }

        newCommercial.is_active = newCommercial.is_active ? 1 : 0;

        console.log("newCommercial: ", newCommercial);

        if (commercialEvent.type === "add") {
            addCommercial(newCommercial);
        } else if (commercialEvent.type === "edit") {
            updateCommercial(newCommercial);
        }
    }

    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const handleRemoveDialogClose = () => {
        setRemoveDialogOpen(false);
    }
    const clickPositiveAnswer = () => {
        removeCommercial(commercialEvent.commercial);
    }
    const clickNegativeAnswer = () => {
    }

    const removeCommercial = (commercial) => {
        axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteCommercial?commercialId=${commercial.id}`, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
                setCommercialEvent({ commercial: null, type: null })
                setRemoveDialogOpen(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = commercials.findIndex(({ id }) => id === commercial.id);
                    var newCommercials = Array.from(commercials);
                    if (ix >= 0) {
                        newCommercials.splice(ix, 1);
                        setCommercials(newCommercials);
                    }
                    setCommercialEvent({ commercial: null, type: null });
                    setRemoveDialogOpen(false);
                    handleSnackbarOpen(<FormattedMessage id={"commercial_deleted_successfully"} />);
                }
            });
    }

    return (
        <Box sx={{ p: "75px" }}>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarState.open}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                message={snackbarState.message}
            />
            {
                commercialEvent.commercial && commercialEvent.type === "delete" &&
                <AlertDialogSlide
                    open={removeDialogOpen}
                    handleClose={(event, reason) => {
                        if (reason && reason === "backdropClick") {
                            return;
                        }
                        handleRemoveDialogClose();
                        setCommercialEvent({ commercial: null, type: null })
                    }}
                    clickPositiveAnswer={clickPositiveAnswer}
                    clickNegativeAnswer={clickNegativeAnswer}
                    positiveAnswer={<FormattedMessage id={"delete"} />}
                    negativeAnswer={<FormattedMessage id={"cancel"} />}
                    title={<FormattedMessage id={"are_you_sure_to_delete_commercial"} />}
                    description={<FormattedMessage id={"delete_commercial_description"} />}
                />
            }
            {
                ((commercialEvent.commercial && commercialEvent.type === "edit") || (!commercialEvent.commercial && commercialEvent.type === "add")) &&
                <CommercialDialog
                    dialogOpen={editDialogOpen}
                    handleClose={(event, reason) => {
                        if (reason && reason === "backdropClick") {
                            return;
                        }
                        setEditDialogOpen(false);
                        setCommercialEvent({ commercial: null, type: null });
                    }}
                    commercial={commercialEvent.commercial}
                    handleFormSubmit={handleCommercialFormSubmit}
                />
            }
            <Box display="flex" alignItems="center" gap="30px">
                <Header
                    title={<FormattedMessage id={"commercials.header.title"} />}
                    subtitle={<FormattedMessage id={"commercials.header.description"} />}
                />
                <Button variant="contained" color="success" onClick={() => setCommercialEvent({ commercial: null, type: "add" })}>
                    <FormattedMessage id={"add_new_commercial"} />
                </Button>
            </Box>
            <Table
                table_id={"commercials_table"}
                isLoading={isLoading}
                columns={columns}
                rows={commercials}
                rowCount={rowCount}
                onOrderChange={(order) => {
                    setOrderModel(order);
                }}
                onPaginationChange={(pagination) => {
                    setPaginationModel(pagination);
                }}
                onFilterChange={(filter) => {
                    setFilterModel(filter);
                }}
                onRowClick={(row, column) => {

                }}
            />

        </Box>
    )
}

export default CommercialsPage
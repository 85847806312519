import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

function NotificationDraftDialog({ draftFromNotification, notificationDraft, dialogOpen, handleClose, onFormSubmit }) {
    const { formatMessage } = useIntl();

    var initialValues = {
        type: notificationDraft?.type || "general",
        content_en: notificationDraft?.content_en || "",
        content_de: notificationDraft?.content_de || ""
    };

    var validationObject = {
        type: yup.string().required(formatMessage({ id: "required" })),
        content_en: yup.string().required(formatMessage({ id: "required" })),
        content_de: yup.string().required(formatMessage({ id: "required" }))
    }

    const notificationDraftSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            fullWidth
            open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    <FormattedMessage id={notificationDraft && !draftFromNotification ? "edit_notification_draft" : "add_new_notification_draft"} />
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={onFormSubmit}
                        initialValues={initialValues}
                        validationSchema={notificationDraftSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="75px" my="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "notifications.table.columns.content_with_language_code" }, { language: "en" })[0].toUpperCase() + formatMessage({ id: "notifications.table.columns.content_with_language_code" }, { language: "en" }).slice(1).toLocaleLowerCase()}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"content_en"}
                                        value={values.content_en}
                                        multiline
                                        rows={4}
                                        error={!!touched.content_en && !!errors.content_en}
                                        helperText={touched.content_en && errors.content_en}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "notifications.table.columns.content_with_language_code" }, { language: "de" })[0].toUpperCase() + formatMessage({ id: "notifications.table.columns.content_with_language_code" }, { language: "de" }).slice(1).toLocaleLowerCase()}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"content_de"}
                                        value={values.content_de}
                                        multiline
                                        rows={4}
                                        error={!!touched.content_de && !!errors.content_de}
                                        helperText={touched.content_de && errors.content_de}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                    <FormControl
                                        fullWidth
                                        variant="filled"
                                        sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label1">
                                            {formatMessage({ id: "type" })}
                                        </InputLabel>
                                        <Select
                                            labelId="filter-label1"
                                            label={formatMessage({ id: "type" })}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"type"}
                                            value={values.type}
                                            error={!!touched.type && !!errors.type}
                                        >
                                            <MenuItem value={"general"}>
                                                <FormattedMessage id={"notification_drafts.type.option.general"} />
                                            </MenuItem>
                                            <MenuItem value={"after_user_edit"}>
                                                <FormattedMessage id={"notification_drafts.type.option.after_user_edit"} />
                                            </MenuItem>
                                            <MenuItem value={"advertisement"}>
                                                <FormattedMessage id={"notification_drafts.type.option.advertisement"} />
                                            </MenuItem>
                                            <MenuItem value={"after_advertisement_edit"}>
                                                <FormattedMessage id={"notification_drafts.type.option.after_advertisement_edit"} />
                                            </MenuItem>
                                            <MenuItem value={"report_and_support_answers"}>
                                                <FormattedMessage id={"notification_drafts.type.option.report_and_support_answers"} />
                                            </MenuItem>
                                            <MenuItem value={"comment"}>
                                                <FormattedMessage id={"notification_drafts.type.option.comment"} />
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px" gap={"10px"}>
                                    <Button type="button" color="warning" variant="contained" onClick={() => {
                                        resetForm();
                                    }}>
                                        <FormattedMessage id="reset" />
                                    </Button>
                                    <Button type="button" color="error" variant="contained" onClick={handleClose}>
                                        <FormattedMessage id="cancel" />

                                    </Button>
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <FormattedMessage id="save" />
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default NotificationDraftDialog
import { Box, IconButton, Snackbar } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../global/Header';
import { AdminContext } from '../../../context/AdminContext';
import { Table } from '../../../components/Table';
import { FormattedMessage, useIntl } from 'react-intl';
import { ArrowBackIosNewOutlined } from '@mui/icons-material';
import NotificationDraftDialog from './NotificationDraftDialog';
import AlertDialogSlide from '../../../components/AlertDialogSlide';

function NotificationDraftsPage() {
    const { admin, token } = useContext(AdminContext);
    const { formatMessage } = useIntl();
    const [drafts, setDrafts] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [orderModel, setOrderModel] = useState(null);
    const [filterModel, setFilterModel] = useState(null);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        rowsPerPage: admin.notification_drafts_table_row_count
    });
    const [notificationDraftToEdit, setNotificationDraftToEdit] = useState(null);
    const [notificationDraftDialogOpen, setNotificationDraftDialogOpen] = useState(false);
    const [alertDialogState, setAlertDialogState] = useState({ open: false, title: "", description: "", positiveAnswer: "", negativeAnswer: "", clickPositiveAnswer: null, clickNegativeAnswer: null });
    const [snackbarState, setSnackbarState] = useState({ open: false, message: "" });
    const handleSnackbarOpen = (message) => {
        setSnackbarState({ open: true, message: message });
    };

    const handleSnackbarClose = () => {
        setSnackbarState({ open: false, message: "" });
    };

    const columns = [
        {
            id: 'id',
            name: formatMessage({ id: "table.columns.id" }),
            type: 'number',
            sortable: true, filterable: true
        },
        {
            id: 'content_en',
            name: formatMessage({ id: "notifications.table.columns.content_with_language_code" }, { language: "EN" }),
            type: 'text',
            sortable: true,
            filterable: true
        },
        {
            id: 'content_de',
            name: formatMessage({ id: "notifications.table.columns.content_with_language_code" }, { language: "DE" }),
            type: 'text',
            sortable: true,
            filterable: true
        },
        {
            id: 'type',
            name: formatMessage({ id: "type" }).toUpperCase(),
            type: 'select',
            options: [
                { value: "general", label: <FormattedMessage id={"notification_drafts.type.option.general"} /> },
                { value: "after_user_edit", label: <FormattedMessage id={"notification_drafts.type.option.after_user_edit"} /> },
                { value: "advertisement", label: <FormattedMessage id={"notification_drafts.type.option.advertisement"} /> },
                { value: "after_advertisement_edit", label: <FormattedMessage id={"notification_drafts.type.option.after_advertisement_edit"} /> },
                { value: "report_and_support_answers", label: <FormattedMessage id={"notification_drafts.type.option.report_and_support_answers"} /> },
                { value: "comment", label: <FormattedMessage id={"notification_drafts.type.option.comment"} /> }
            ],
            sortable: true,
            filterable: true,
            render: (value) => {
                return formatMessage({ id: `notification_drafts.type.option.${value}` })
            }
        },
        {
            id: 'actions',
            name: <FormattedMessage id={"table.columns.actions"} />,
            type: 'actions',
            sortable: false,
            filterable: false,
            getActions: () => [
                {
                    label: <FormattedMessage id={"edit"} />,
                    action: (row) => {
                        setNotificationDraftToEdit(row);
                        setNotificationDraftDialogOpen(true);
                    }
                },
                {
                    label: <FormattedMessage id={"delete"} />,
                    action: (row) => {
                        setAlertDialogState({
                            open: true,
                            title: formatMessage({ id: "are_you_sure_to_delete_notification_draft" }),
                            description: formatMessage({ id: "delete_notification_draft_description" }),
                            positiveAnswer: formatMessage({ id: "delete" }),
                            negativeAnswer: formatMessage({ id: "cancel" }),
                            clickPositiveAnswer: () => {
                                deleteNotificationDraft(row.id);
                            },
                            clickNegativeAnswer: () => { },
                            handleClose: (event, reason) => {
                                if (reason !== "backdropClick") {
                                    setAlertDialogState({ open: false, title: "", description: "", positiveAnswer: "", negativeAnswer: "", clickPositiveAnswer: null, clickNegativeAnswer: null });
                                }
                            }
                        })
                    }
                }
            ]
        }
    ];

    const fetchNotificationDrafts = async (paginationModel, orderModel, filterModel, token) => {
        setLoading(true);
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/getNotificationDrafts`,
            {
                startIndex: paginationModel.page * paginationModel.rowsPerPage,
                limit: paginationModel.rowsPerPage,
                orderBy: orderModel ? orderModel.orderBy : null,
                orderType: orderModel ? orderModel.orderType : null,
                filter: filterModel ? filterModel : null
            },
            { headers: { token: token } }
        )
            .catch((err) => {
                setDrafts([]);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setDrafts(response.data.drafts);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                }
                setLoading(false);
            });
    }

    const updateNotificationDraft = (values) => {
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/updateNotificationDraft?draftId=${notificationDraftToEdit.id}`,
            {
                content_en: values.content_en,
                content_de: values.content_de,
                type: values.type
            },
            { headers: { token: token } }
        )
            .catch((error) => {
                console.log(error);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchNotificationDrafts(paginationModel, orderModel, filterModel, token);
                    setNotificationDraftToEdit(null);
                    setNotificationDraftDialogOpen(false);
                    setAlertDialogState({ open: false, title: "", description: "", positiveAnswer: "", negativeAnswer: "", clickPositiveAnswer: null, clickNegativeAnswer: null });
                    handleSnackbarOpen(formatMessage({ id: "notification_draft_updated_successfully" }));
                }
            });
    }

    const createNotificationDraft = (values) => {
        axios.post(
            `${process.env.REACT_APP_API_URL}webAdmin/createNotificationDraft`,
            {
                content_en: values.content_en,
                content_de: values.content_de,
                type: values.type
            },
            { headers: { token: token } }
        )
            .catch((error) => {
                console.log(error);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchNotificationDrafts(paginationModel, orderModel, filterModel, token);
                    setNotificationDraftDialogOpen(false);
                    handleSnackbarOpen(formatMessage({ id: "notification_draft_added_successfully" }));
                }
            });
    }

    const deleteNotificationDraft = (draftId) => {
        axios.delete(
            `${process.env.REACT_APP_API_URL}webAdmin/deleteNotificationDraft?draftId=${draftId}`,
            { headers: { token: token } }
        )
            .catch((error) => {
                console.log(error);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchNotificationDrafts(paginationModel, orderModel, filterModel, token);
                    setAlertDialogState({ open: false, title: "", description: "", positiveAnswer: "", negativeAnswer: "", clickPositiveAnswer: null, clickNegativeAnswer: null });
                    handleSnackbarOpen(formatMessage({ id: "notification_draft_deleted_successfully" }));
                }
            });
    }

    useEffect(() => {
        fetchNotificationDrafts(paginationModel, orderModel, filterModel, token);
    }, [paginationModel, orderModel, filterModel, token]);

    return (
        <Box sx={{ p: "75px" }}>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackbarState.open}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                message={snackbarState.message}
            />

            {alertDialogState.open &&
                <AlertDialogSlide
                    {...alertDialogState}
                />
            }

            <NotificationDraftDialog
                notificationDraft={notificationDraftToEdit}
                dialogOpen={notificationDraftDialogOpen}
                handleClose={
                    (e, reason) => {
                        if (reason !== "backdropClick") {
                            setNotificationDraftDialogOpen(false);
                            setNotificationDraftToEdit(null);
                        }
                    }
                }
                onFormSubmit={(values) => {
                    if (notificationDraftToEdit) {
                        setAlertDialogState({
                            open: true,
                            title: formatMessage({ id: "are_you_sure_to_update_notification_draft" }),
                            description: formatMessage({ id: "update_notification_draft_description" }),
                            positiveAnswer: formatMessage({ id: "yes" }),
                            negativeAnswer: formatMessage({ id: "no" }),
                            clickPositiveAnswer: () => {
                                updateNotificationDraft(values);
                            },
                            clickNegativeAnswer: () => { },
                            handleClose: (event, reason) => {
                                if (reason !== "backdropClick") {
                                    setAlertDialogState({ open: false, title: "", description: "", positiveAnswer: "", negativeAnswer: "", clickPositiveAnswer: null, clickNegativeAnswer: null });
                                }
                            }
                        });
                    } else {
                        createNotificationDraft(values);
                    }
                }}
            />

            <Box display="flex">
                <Box display="flex">
                    <IconButton style={{ width: "40px", height: "40px", marginRight: "5px" }}
                        onClick={() => {
                            window.history.back();
                        }}>
                        <ArrowBackIosNewOutlined />
                    </IconButton>
                    <Box display="flex">
                        <Header
                            title={<FormattedMessage id={"notification_drafts.header.title"} />}
                            subtitle={<FormattedMessage id={"notification_drafts.header.description"} />} />
                    </Box>
                </Box>
            </Box>

            <Table
                table_id={"notification_drafts_table"}
                isLoading={isLoading}
                columns={columns}
                rows={drafts}
                rowCount={rowCount}
                onOrderChange={(order) => {
                    setOrderModel(order);
                }}
                onPaginationChange={(pagination) => {
                    setPaginationModel(pagination);
                }}
                onFilterChange={(filter) => {
                    setFilterModel(filter);
                }}
                onRowClick={(row) => {

                }}
                additionalButtons={[
                    {
                        label: <FormattedMessage id={"add_new_draft"} />,
                        color: "info",
                        onClick: () => {
                            setNotificationDraftToEdit(null);
                            setNotificationDraftDialogOpen(true);
                        }
                    }
                ]}
            />
        </Box>
    )
}

export default NotificationDraftsPage
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage } from 'react-intl';
import CommentBox from './CommentBox';
import { AdminContext } from '../../context/AdminContext';
import axios from 'axios';
import AlertDialogSlide from '../AlertDialogSlide';

function ReviewDeletionRequestsDialog({ dialogOpen, handleClose }) {
    const { token, admin } = useContext(AdminContext);
    const [commentsToDelete, setCommentsToDelete] = useState([]);
    const [hasMoreCommentToDelete, setHasMoreCommentToDelete] = useState(true);
    const [alertDialogState, setAlertDialogState] = useState({
        open: false,
        title: "",
        description: "",
        positiveAnswer: "",
        negativeAnswer: "",
        onApprove: () => { }
    });
    const handleAlertDialogClose = () => {
        setAlertDialogState({
            open: false,
            title: "",
            description: "",
            positiveAnswer: "",
            negativeAnswer: "",
            onApprove: () => { }
        });
    }


    const fetchCommentsToDelete = async (startIndex, token) => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getCommentsToDelete?startIndex=${startIndex}&limit=10`, { headers: { token: token } })
            .catch((err) => {
                console.log("err: " + err);
                setHasMoreCommentToDelete(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMoreCommentToDelete(response.data.comments && response.data.comments.length === 10);
                    if (startIndex === 0) {
                        setCommentsToDelete([...response.data.comments]);
                    } else {
                        setCommentsToDelete((prev) => [...prev, ...response.data.comments]);
                    }
                }
            });
    }

    useEffect(() => {
        fetchCommentsToDelete(0, token);
    }, [token]);

    const deleteComment = async (commentId) => {
        axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteComment?reviewId=${commentId}`, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    let ix = commentsToDelete.findIndex(({ id }) => id === commentId);
                    if (ix >= 0) {
                        commentsToDelete.splice(ix, 1);
                        setCommentsToDelete(Array.from(commentsToDelete));
                    }
                }
            });
    }

    const rejectCommentDeletionRequest = async (commentId) => {
        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateComment?reviewId=${commentId}`, {
            review: {
                deletion_status: 2
            }
        }, { headers: { "token": token } })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    let ix = commentsToDelete.findIndex(({ id }) => id === commentId);
                    if (ix >= 0) {
                        commentsToDelete.splice(ix, 1);
                        setCommentsToDelete(Array.from(commentsToDelete));
                    }
                }
            });
    }


    return (
        <>
            {alertDialogState.open &&
                <AlertDialogSlide
                    open={alertDialogState.open}
                    handleClose={(event, reason) => {
                        if (reason && reason === "backdropClick") {
                            return;
                        }
                        handleAlertDialogClose();
                    }}
                    clickPositiveAnswer={() => {
                        alertDialogState.onApprove();
                        setAlertDialogState({ open: false, type: "" });
                    }}
                    clickNegativeAnswer={() => { }}
                    positiveAnswer={alertDialogState.positiveAnswer}
                    negativeAnswer={alertDialogState.negativeAnswer}
                    title={alertDialogState.title}
                    description={alertDialogState.description}
                />
            }
            <Dialog
                sx={{ margin: "0 auto" }}
                maxWidth="xl"
                fullWidth
                open={dialogOpen}
                onClose={(e, reason) => {
                    if (reason && reason === "backdropClick") {
                        return;
                    }
                    handleClose();
                }}>
                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        variant="standard"
                        color="error"
                        sx={{
                            borderRadius: "100%",
                            backgroundColor: "white",
                            maxWidth: "40px",
                            maxHeight: "40px",
                            minWidth: "40px",
                            minHeight: "40px",
                            position: "absolute",
                            top: "10px",
                            right: "10px"
                        }}
                        onClick={handleClose}>
                        <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                    </Button>
                </Box>
                <Box my="20px" mx={"60px"} textAlign="center">
                    <Typography variant='h3'>
                        <FormattedMessage id={"review_deletion_request"} />
                    </Typography>
                </Box>
                <DialogContent>
                    <InfiniteScroll
                        dataLength={commentsToDelete.length}
                        next={() => fetchCommentsToDelete(commentsToDelete.length)}
                        hasMore={hasMoreCommentToDelete}
                        loader={<h4>
                            <FormattedMessage id="loading_elipsis" />
                        </h4>}
                        height={510}
                    >
                        <Box m="10px 0 0 0" maxHeight="500px" display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))">
                            {commentsToDelete.map((comment) =>
                                <CommentBox
                                    key={comment.id}
                                    comment={comment}
                                    onRejectDeletionRequestButtonClick={(commentId) => {
                                        setAlertDialogState({
                                            open: true,
                                            title: <FormattedMessage id={"are_you_sure_to_reject_comment_deletion_request"} />,
                                            description: <FormattedMessage id={"reject_comment_deletion_request_description"} />,
                                            positiveAnswer: <FormattedMessage id={"yes"} />,
                                            negativeAnswer: <FormattedMessage id={"no"} />,
                                            onApprove: () => {
                                                rejectCommentDeletionRequest(commentId);
                                            }
                                        });
                                    }}
                                    onDeleteButtonClick={(commentId) => {
                                        setAlertDialogState({
                                            open: true,
                                            title: <FormattedMessage id={"are_you_sure_to_delete_comment"} />,
                                            description: <FormattedMessage id={"delete_comment_description"} />,
                                            positiveAnswer: <FormattedMessage id={"yes"} />,
                                            negativeAnswer: <FormattedMessage id={"no"} />,
                                            onApprove: () => {
                                                deleteComment(commentId);
                                            }
                                        });
                                    }}
                                />)}
                        </Box>
                    </InfiniteScroll>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ReviewDeletionRequestsDialog